import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import "./SaleInvoice.css";
import * as u from "../utils/Common";
import 'jspdf-autotable';
import { AiOutlinePlus, AiOutlineSave, AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import InputMask from 'react-input-mask';
import ProductMasterList from '../GridLookup/ProductMasterList';
import CustomerList from '../GridLookup/CustomerList';
// import TransportList from '../GridLookup/TransportList';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const Saleinvoice = ({ TrnType = "SI" }) => {
    const trntype = TrnType;
    // let trnname = "Sale Invoice";

    // if (trntype===2){
    //     trnname="Production";
    // } else if (trntype===8) {
    //     trnname="Transfer";
    // }


    const placeRef = useRef();
    const pincodeRef = useRef();

    const [tdate, setDate] = useState(u.curdate());

    const [selectedcustomer, setselectedcustomer] = useState({ actcode: 0, actname: '', add1: '', add2: '', place: '', pincode: '', gstin: '' });
    const add1Ref = useRef('');
    // const [selectedtransport, setselectedtransport] = useState({ transportcode: 0, transportname: "" });
    const [docutype, setdocuType] = useState("cash");
    // const transportRef = useRef('');

    const [curdata, setcurdata] = useState([])
    const [pdtserial, setpdtSerial] = useState('');
    const [pdtdata, setpdtdata] = useState({});

    const [selecteditem, setselecteditem] = useState({ pdtcode: 0, pdtname: '' });
    const pdtnameRef = useRef('');

    const [qty, setqty] = useState('');
    const [amount, setamount] = useState('');
    const [rate, setrate] = useState('');
    const [rateper, setrateper] = useState('');

    const [curmode, setcurmode] = useState(['New', 0]);   // New/Edit  SrlNo 

    const sicurmode = useSelector(function (data) {
        return data.saleinvoicemode;
    })
    useEffect(() => {
        pdtserialRef.current.focus();
    }, [sicurmode]);

    const setselecteditemobj = (code, name) => {
        setselecteditem({ pdtcode: code, pdtname: name })
    }

    const setselectedcustomerobj = (actcode, actname, add1, add2, place, pincode, gstin) => {
        setselectedcustomer({ actcode: actcode, actname: actname, add1: add1, add2: add2, place: place, pincode: pincode, gstin: gstin })
    }
    // const setselectedtransportobj = (code, name) => {
    //     setselectedtransport({ transportcode: code, transportname: name })
    // }

    let preView = false;
    let docuno = sicurmode[1];
    useEffect(() => {
        const fetchdata = async (e) => {
            const data = { docuno: sicurmode[1] };
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/saleinvoice_read`, data);
            if (response.data.inv1.length > 0) {
                setDate(response.data.inv1[0].docudate);
                setdocuType(response.data.inv1[0].docutype);
                setselectedcustomerobj(response.data.inv1[0].actcode, response.data.inv1[0].actname, response.data.inv1[0].add1, response.data.inv1[0].add2, response.data.inv1[0].place, response.data.inv1[0].pincode, response.data.inv1[0].gstin)
                // setselectedtransportobj(response.data.inv1[0].transportcode, response.data.inv1[0].transportname);
                const si2data = response.data.inv2.map((data) => {
                    return {
                        srlno: data.srlno,
                        pdtcode: data.pdtcode,
                        pdtserial: data.pdtserial,
                        pdtname: data.pdtname,
                        uofm: data.uofm,
                        hsn: data.hsn,
                        gstrate: data.gstrate,
                        qty: data.qty,
                        rate: data.rate,
                        rateper: data.rateper,
                        amount: data.amount,
                        igst: data.igst,
                        cgst: data.cgst,
                        sgst: data.sgst
                    }
                })
                setcurdata(si2data)
            }
        }
        if (sicurmode[0] === "Edit") {
            fetchdata();
        }
    }, [sicurmode]);


    const handleDateChange = (e) => {
        const input = e.target.value;
        setDate(input);
    };
    const handleDocuTypeChange = (e) => {
        const input = e.target.value;
        setdocuType(input);
    };

    const handleCustomerTransportData = async (value) => {
        const data = { transportcode: u.myInt(value) };
        const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/transport_readbytransportcode`, data);
        if (response.data.length > 0) {
            // setselectedtransportobj(response.data[0].transportcode, response.data[0].transportname);
        };
    };

    const handlepdtSerialChange = (values) => {
        let { value } = values;
        if (value.startsWith("0")) {
            value = value.replace(/^0+/, '');
        }
        const regex = /^\d{0,5}$/;
        if (regex.test(value)) {
            setpdtSerial(value);
            fetchproductdata();
        }
    };

    const fetchproductdata = async () => {
        if (curmode[0] === 'New') {
            try {
                const data = { pdtserial: u.myInt(pdtserial) };
                const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_readpdtserial`, data)
                if (response.data.length > 0) {
                    setpdtdata({ uofm: response.data[0].uofm, hsn: response.data[0].hsn, gstrate: response.data[0].gstrate });
                    setrate(response.data[0].rate);
                    setrateper(response.data[0].rateper);
                    setselecteditemobj(response.data[0].pdtcode, response.data[0].pdtname);
                } else {
                    setpdtdata({ uofm: '', hsn: '', gstrate: '' });
                    setrate('');
                    setrateper('');
                    setselecteditemobj(0, '');
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    useEffect(() => {
        if (pdtserial) {
            fetchproductdata();
        }
    }, [pdtserial]);

    const handleSaveItem = async () => {
        let value = u.myInt(pdtserial);
        console.log(value);
        if (value === 0) {
            alert('Must Enter Valid Product Serial');
            window.document.getElementById("si_pdtserial").focus();
            window.document.getElementById("si_pdtserial").select();
            return;
        }
        value = u.myTrim(selecteditem.pdtname);
        if (value === "") {
            alert("Product Name should be given");
            window.document.getElementById("si_pdtserial").focus();
            window.document.getElementById("si_pdtserial").select();
            return;
        }
        value = u.myInt(qty);
        if (value === 0) {
            alert('Must Enter Valid Quantity Number');
            qtyRef.current.focus();
            return;
        }

        let nextsrlno = 1;
        if (curmode[0] === "New") {
            if (curdata.length > 0) {
                nextsrlno = curdata[curdata.length - 1].srlno + 1;
            }
        }

        const newdata = {
            srlno: curmode[0] === "New" ? nextsrlno : curmode[1],
            pdtserial: u.myInt(pdtserial),
            pdtcode: selecteditem.pdtcode,
            pdtname: selecteditem.pdtname,
            uofm: pdtdata.uofm,
            hsn: pdtdata.hsn,
            gstrate: pdtdata.gstrate,
            rate: u.myFloat(rate),
            rateper: u.myInt(rateper),
            qty: u.myInt(qty),
            amount: parseFloat((parseFloat(amount)).toFixed(2)),
            igst: 0,
            cgst: u.calculateGST(amount, pdtdata.gstrate / 2),
            sgst: u.calculateGST(amount, pdtdata.gstrate / 2),
        };
        console.log(newdata);
        if (curmode[0] === "Edit") {
            const updatedData = curdata.map((item) => (item.srlno === newdata.srlno ? newdata : item));
            console.log(updatedData);
            setcurdata(updatedData);
        } else {
            setcurdata([...curdata, newdata]);
        }

        if (curmode[0] === "New") {
            handleAddNew()
        } else {
            handleCancelItem();
        }
        resetform();
    };
    const resetform = () => {
        pdtserialRef.current.focus();
        setpdtSerial('');
        setqty('');
        setrate('');
        setrateper('');
        setamount('');
        setselecteditemobj(0, '');
    }
    const handleCancelItem = () => {
        resetform();
    }

    // const handlePrint = () => {
    //     preView = true
    //     handlesaleinvoiceSave()
    // };

    const handlesaleinvoiceSave = async () => {
        if (!curdata.length > 0) {
            alert('Table Contains No Data');
            return;
        }
        let value = tdate;
        if (!u.validateDate(value)) {
            alert('Please Enter Valid Date');
            window.document.getElementById("date").focus();
            window.document.getElementById("date").select();
            return;
        }
        if (docutype === '') {
            alert("Type Should Be Given");
            window.document.getElementById("docutype").focus();
            return;
        }
        if (selectedcustomer.actname === '') {
            alert("Customer Should be Given");
            window.document.getElementById("customer").focus();
            return;
        }
        if (selectedcustomer.add1 === '') {
            alert("Address Should be Given");
            add1Ref.current.focus();
            return;
        }
        if (selectedcustomer.place === '') {
            alert("Place Should be Given");
            placeRef.current.focus();
            return;
        }
        if (selectedcustomer.pincode === '') {
            alert("Pincode Should be Given");
            pincodeRef.current.focus();
            return;
        }
        if(selectedcustomer.gstin === '' || selectedcustomer.gstin.length < 15){
            alert("Invalid GSTIN");
            window.document.getElementById("gstin").focus();
            return;
        }

        // if (selectedtransport.transportname === '') {
        //     alert("Transport Should be Given");
        //     transportRef.current.focus();
        //     return;
        // }

        let newdocuno = 0;

        const itemlist = curdata.map(item => {
            return {
                srlno: item.srlno,
                pdtcode: item.pdtcode,
                gstrate: item.gstrate,
                rate: item.rate,
                rateper: item.rateper,
                qty: item.qty,
                amount: item.amount,
                igst: item.igst,
                cgst: item.cgst,
                sgst: item.sgst
            }
        }
        )
        try {
            const data = {
                trntype: trntype,
                docuno: sicurmode[1],
                docudate: tdate,
                docutype: docutype === "cash" ? 0 : 1,
                actcode: u.myInt(selectedcustomer.actcode),
                actname: u.toProperCase(selectedcustomer.actname),
                add1: u.toProperCase(selectedcustomer.add1),
                add2: u.toProperCase(selectedcustomer.add2),
                place: u.toProperCase(selectedcustomer.place),
                pincode: selectedcustomer.pincode,
                gstin: selectedcustomer.gstin.toUpperCase(),
                // transportcode: u.myInt(selectedtransport.transportcode),
                igst: 0,
                cgst: totalcgst,
                sgst: totalsgst,
                goodsvalue: parseFloat(saleValue.toFixed(2)),
                roundoff: roundoff,
                debit: parseFloat(debitamount.toFixed(2)),
                itemlist: itemlist
            };
            let url = `${process.env.REACT_APP_RAINBOW_URL}/saleinvoice_create`
            const res = await axios.post(url, data);
            if (res) {
                newdocuno = res.data.docuno;
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
        alert("Data Save Successfully....");

        if (preView) {
            const data = { docuno: newdocuno }
            const url = `${process.env.REACT_APP_RAINBOW_URL}/saleinvoice_preview`
            const response = await axios.post(url, data, { responseType: 'blob' });
            const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            window.open(fileURL, '_blank');
        }

        preView = false;

        if (sicurmode[0] === "Edit") {
            navigate(-1);
            return;
        }

        setcurdata([]);
    };
    const calcuAmount = (qty, rate, rateper) => {
        const qtyValue = u.myInt(qty);
        const rateValue = u.myFloat(rate);
        const rateperValue = u.myInt(rateper);
        if (qtyValue > 0 && rateValue > 0 && rateperValue > 0) {
            const totalamount = (qtyValue / rateperValue) * rateValue;
            setamount(totalamount);
        }
    }
    // const [cgst, setcgst] = useState('');
    // const [sgst, setsgst] = useState('');

    const handleChangeQty = (values) => {
        let { value } = values;
        setqty(value);
        calcuAmount(value, rate, rateper);
    }

    const handleChangeAmount = (values) => {
        let { value } = values;
        setamount(value);
    }

    const handleChangeRate = (values) => {
        let { value } = values;
        setrate(value);
        calcuAmount(qty, value, rateper);
    }
    const handleChangeRatePer = (values) => {
        let { value } = values;
        setrateper(value);
        calcuAmount(qty, rate, value);
    }

    const defaultEnterRef = useRef(null)
    const defaultEscapeRef = useRef(null)
    const defaultsaleinvoiceSaveRef = useRef(null)

    // useEffect(() => {
    //     const handleKeyPress = (event) => {
    //         event = event || window.event;
    //         if ((event.key === 'Enter') || (event.ctrlKey && event.key === 'a')) {
    //             defaultEnterRef.current.click();
    //         }
    //         if (event.key === 'Escape') {
    //             defaultEscapeRef.current.click();
    //         }
    //         if (event.altKey && event.key === 's') {
    //             defaultsaleinvoiceSaveRef.current.click();
    //         }
    //         if (event.altKey && event.key === 'p') {
    //             handlePrint();
    //         }
    //     };
    //     document.addEventListener('keydown', handleKeyPress);
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyPress);
    //     };
    // }, []);
    const handleDelete = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(data);
            }
        });
    }

    const confirmDelete = async (selectedrow) => {
        setcurdata(curdata.filter(data => data.srlno !== selectedrow.srlno));
    };

    const handleEdit = (data) => {
        setcurmode(["Edit", data.srlno]);
        setpdtSerial(data.pdtserial);
        setselecteditemobj(data.pdtcode, data.pdtname);
        setpdtdata({ uofm: data.uofm, hsn: data.hsn, gstrate: data.gstrate });
        setqty(data.qty);
        setrate(data.rate);
        setrateper(data.rateper);
        setamount(data.amount);
        if (u.myInt(qty) > 0) {
            window.document.getElementById("si_qty").focus();
            window.document.getElementById("si_qty").select();
        } else {
            window.document.getElementById("si_amount").focus();
            window.document.getElementById("si_amount").select();
        }
    }

    const handleAddNew = () => {
        setcurmode(["New", 0]);
        resetform();
    }

    const navigate = useNavigate();
    const totalQty = curdata.reduce((total, item) => total + (item.qty || 0), 0)
    const saleValue = parseFloat(curdata.reduce((total, item) => total + (item.amount || 0), 0).toFixed(2));
    const totalcgst = parseFloat(curdata.reduce((total, item) => total + (item.cgst || 0), 0).toFixed(2));
    const totalsgst = parseFloat(curdata.reduce((total, item) => total + (item.sgst || 0), 0).toFixed(2));
    const roundoff = u.roundOffValue(saleValue + totalcgst + totalsgst);
    const debitamount = parseFloat((saleValue + totalcgst + totalsgst + roundoff).toFixed(2));
    const tableContainerRef = useRef(null);
    useEffect(() => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = tableContainerRef.current.scrollHeight;
        }
    }, [curdata]);

    const handleClosesaleinvoice = () => {
        navigate(-1)
    }
    const handleCustomerChange = (event) => {
        const value = event.target.value;
        setselectedcustomerobj(0, value, selectedcustomer.add1, selectedcustomer.add2, selectedcustomer.place, selectedcustomer.pincode, selectedcustomer.gstin);
        // setselectedtransportobj(0, '');
    }
    const handlePlaceChange = (event) => {
        const value = event.target.value;
        setselectedcustomerobj(selectedcustomer.actcode, selectedcustomer.actname, selectedcustomer.add1, selectedcustomer.add2, value, selectedcustomer.pincode, selectedcustomer.gstin);
    }
    const handleAdd1Change = (event) => {
        const value = event.target.value;
        setselectedcustomerobj(selectedcustomer.actcode, selectedcustomer.actname, value, selectedcustomer.add2, selectedcustomer.place, selectedcustomer.pincode, selectedcustomer.gstin);
    }
    const handleAdd2Change = (event) => {
        const value = event.target.value;
        setselectedcustomerobj(selectedcustomer.actcode, selectedcustomer.actname, selectedcustomer.add1, value, selectedcustomer.place, selectedcustomer.pincode, selectedcustomer.gstin);
    }
    const handlePincodeChange = (values) => {
        let { value } = values;
        setselectedcustomerobj(selectedcustomer.actcode, selectedcustomer.actname, selectedcustomer.add1, selectedcustomer.add2, selectedcustomer.place, value, selectedcustomer.gstin);
    }
    const handleGSTINChange = (event) => {
        const value = event.target.value;
        setselectedcustomerobj(selectedcustomer.actcode, selectedcustomer.actname, selectedcustomer.add1, selectedcustomer.add2, selectedcustomer.place, selectedcustomer.pincode, value);
    }

    const qtyRef = useRef();
    const amountRef = useRef();
    const rateRef = useRef();
    const rateperRef = useRef();
    const pdtserialRef = useRef();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
    // const [isTransportModalOpen, setIsTransportModalOpen] = useState(false);

    const onDoubleClickpdtserial = () => {
        setIsModalOpen(true);
    }
    const onDoubleClickcustomer = () => {
        setIsCustomerModalOpen(true);
    }
    // const onDoubleClicktransport = () => {
    //     setIsTransportModalOpen(true);
    // }
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const closeCustomerModal = () => {
        setIsCustomerModalOpen(false);
    };
    // const closeTransportModal = () => {
    //     setIsTransportModalOpen(false);
    // };


    const handleRowDoubleClick = (data) => {
        const rowData = data;  // Get data of the double-clicked row
        setIsModalOpen(false);  // Close modal after selection
        setpdtSerial(rowData.pdtserial);
        setselecteditemobj(rowData.pdtcode, rowData.pdtname);
        setpdtdata({ uofm: rowData.uofm, hsn: rowData.hsn, gstrate: rowData.gstrate });
        qtyRef.current.focus();
    };
    const handleCustomerRowDoubleClick = (data) => {
        const rowData = data;
        setIsCustomerModalOpen(false);
        setselectedcustomerobj(rowData.actcode, rowData.actname, rowData.add1, rowData.add2 + rowData.add3 + rowData.add4, rowData.place, rowData.pincode, rowData.gstin);
        console.log("sample")
        if (sicurmode[0] === "New") {
            handleCustomerTransportData(rowData.transportcode);
        }
        handleAddNew();
        // transportRef.current.focus();
    };
    // const handleTransportRowDoubleClick = (data) => {
    //     const rowData = data;
    //     setIsTransportModalOpen(false);
    //     setselectedtransportobj(rowData.transportcode, rowData.transportname)
    //     // pricelistRef.current.focus();
    // };

    const columnDefs = [
        { headerName: "Srl", field: "pdtserial", width: 65, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
        { headerName: "Product Name", field: "pdtname", width: 170, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
        { headerName: "HSN Code", field: "hsn", width: 100, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
        { headerName: "GST Rate", field: "gstrate", width: 75, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
        { headerName: "Qty.", field: "qty", width: 70, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
        { headerName: "UOFM", field: "uofm", width: 80, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
        {
            headerName: "Rate", field: "rate", width: 80, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
            valueFormatter: (params) => {
                return (params.value.toFixed(2))
            }
        },
        { headerName: "RatePer", field: "rateper", width: 75, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
        {
            headerName: "Amount", field: "amount", width: 107, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
            valueFormatter: (params) => {
                return (params.value.toFixed(2))
            }
        },
        {
            headerName: "Actions",
            cellRenderer: (params) => (
                <div className='col-action-div'>
                    <button onClick={() => handleEdit(params.data)}>Edit</button>
                    <button onClick={() => handleDelete(params.data)}>Delete</button>
                </div>
            ),
            headerClass: 'custom-header',
            cellClass: "col-action",
            width: 100,
        }
    ];
    const [selectedIndex, setSelectedIndex] = useState(0);
    const gridOptions = {
        rowSelection: 'multiple',
        onRowDoubleClicked: (event) => handleEdit(event.data),
    };
    const gridRef = useRef();
    const [arrowKeyUsed, setArrowKeyUsed] = useState(false); // Track arrow key usage

    const handleKeyDown = (event) => {
        const activeElement = document.activeElement;
        const isInputField = activeElement.tagName === 'SELECT';
        if (!curdata.length || isCustomerModalOpen || isInputField) return; // Exit if there's no data

        if (event.key === 'ArrowDown') {
            setSelectedIndex((prevIndex) => {
                const newIndex = Math.min(prevIndex + 1, curdata.length - 1);
                gridRef.current.api.ensureIndexVisible(newIndex); // Make sure the selected row is visible
                gridRef.current.api.getDisplayedRowAtIndex(newIndex).setSelected(true); // Set row selected
                return newIndex;
            });
            setArrowKeyUsed(true);
        } else if (event.key === 'ArrowUp') {
            setSelectedIndex((prevIndex) => {
                const newIndex = Math.max(prevIndex - 1, 0);
                gridRef.current.api.ensureIndexVisible(newIndex); // Ensure selected row is visible
                gridRef.current.api.getDisplayedRowAtIndex(newIndex).setSelected(true); // Set row selected
                return newIndex;
            });
            setArrowKeyUsed(true);
        } else if (event.key === 'Enter') {
            if (arrowKeyUsed) {
                handleEdit(curdata[selectedIndex]);
                setArrowKeyUsed(false); // Reset after edit
            } else {
                console.log('Enter pressed without using arrow keys first');
            }
        }
    };

    const handleRowClick = (event) => {
        const index = event.rowIndex;
        setSelectedIndex(index);
        setArrowKeyUsed(false); // Reset arrow key flag since row was selected by mouse
    };

    useEffect(() => {
        const handleKeyDownGlobal = (event) => {
            handleKeyDown(event);
        };

        window.addEventListener('keydown', handleKeyDownGlobal);
        return () => {
            window.removeEventListener('keydown', handleKeyDownGlobal);
        };
    }, [curdata, selectedIndex, arrowKeyUsed, isCustomerModalOpen]);

    const getRowClass = (params) => {
        return params.node.rowIndex === selectedIndex ? 'highlighted-row' : '';
    };
    return (
        <div className="frmsaleinvoice-divmain">
            <div className='frmsaleinvoice-main'>
                <div className="frmsaleinvoice-top">
                    <div className='frmsaleinvoice-top1'>
                        <div className="input-top-item" style={{ width: "9%" }}>
                            <label style={{ marginLeft: "4px", width: "60%" }}>Invoice No</label>
                            <input type="text" className='selectreact'
                                autoComplete='off' value={docuno}
                                style={{ width: "40%", textAlign: "right", paddingRight: "4px" }} readOnly />
                        </div>
                        <div className="input-top-item" style={{ width: "8%" }}>
                            <label style={{ marginLeft: "4px", width: "22%" }}>Date </label>
                            <InputMask
                                className='selectreact'
                                autoFocus
                                autoComplete='off'
                                id="date"
                                mask="99/99/99"
                                value={tdate}
                                onChange={handleDateChange}
                                placeholder="DD/MM/YY"
                                style={{ fontSize: "14px", marginLeft: "2px", width: "78%", paddingLeft: "3px", paddingRight: "3px" }}
                                // className="date-input"
                                maskChar=""
                            />
                        </div>

                        <div className="input-top-item" style={{ width: "8%" }}>
                            <label style={{ marginLeft: "4px", width: "27%" }}>Type</label>
                            <select name="docutype" id="docutype" value={docutype} onChange={handleDocuTypeChange} style={{ width: "73%" }}>             }>
                                <option value="cash">Cash</option>
                                <option value="credit"> Credit</option>
                            </select>
                        </div>


                    </div>

                    <div className='frmsaleinvoice-top1'>
                        {/* <div className="input-top-item" style={{ width: "20%" }}>
                            <label style={{ marginLeft: "2px", width: "26%" }}>Transport</label>
                            <input type='text'
                                className='selectreact'
                                name="transport"
                                id="transport"
                                ref={transportRef}
                                value={selectedtransport.transportname}
                                onDoubleClick={onDoubleClicktransport}
                                onKeyDown={(event) => {
                                    if (event.key === 'F4') {
                                        onDoubleClicktransport()
                                    }
                                }}
                                readOnly
                            />
                            {isTransportModalOpen && (
                                <TransportList
                                    closeModal={closeTransportModal}
                                    onRowDoubleClick={handleTransportRowDoubleClick}
                                    selecteditems={selectedtransport}
                                />
                            )}

                        </div> */}
                        <div className="input-top-item" style={{ width: "30%" }}>
                            <label style={{ marginLeft: "2px", width: "15%" }}>Customer</label>
                            <input type='text'
                                autoComplete='off'
                                className='selectreact'
                                name="customer"
                                id="customer"
                                onChange={handleCustomerChange}
                                value={selectedcustomer.actname}
                                onDoubleClick={onDoubleClickcustomer}
                                style={{ width: "85%" }}
                                onFocus={() => {
                                    if (selectedcustomer.actname === '' && docutype === 'credit') { onDoubleClickcustomer() }
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'F4') {
                                        onDoubleClickcustomer()
                                    }
                                }}
                            />
                            {isCustomerModalOpen && (
                                <CustomerList
                                    closeModal={closeCustomerModal}
                                    onRowDoubleClick={handleCustomerRowDoubleClick}
                                    selecteditems={selectedcustomer}
                                />
                            )}
                        </div>
                        <div className="input-top-item" style={{ width: "32%" }}>
                            <label style={{ marginLeft: "2px", width: "12%" }}>Address</label>
                            <input type="text" className='selectreact' ref={add1Ref}
                                autoComplete='off' onChange={handleAdd1Change} value={selectedcustomer.add1}
                                maxLength={100}
                                style={{ width: "44%" }} readOnly={docutype === "credit" ? true : false} />
                            <input type="text" className='selectreact'
                                autoComplete='off' onChange={handleAdd2Change} value={selectedcustomer.add2}
                                maxLength={100}
                                style={{ width: "44%" }} readOnly={docutype === "credit" ? true : false} />
                        </div>
                        <div className="input-top-item" style={{ width: "16%" }}>
                            <label style={{ marginLeft: "2px", width: "15%" }}>Place</label>
                            <input type="text" className='selectreact' ref={placeRef}
                                autoComplete='off' onChange={handlePlaceChange} value={selectedcustomer.place}
                                maxLength={50}
                                style={{ width: "85%" }} readOnly={docutype === "credit" ? true : false} />
                        </div>
                        <div className="input-top-item" style={{ width: "8%" }}>
                            <label style={{ marginLeft: "2px", width: "60%" }}>PinCode</label>
                            <NumericFormat
                                getInputRef={(ref) => {
                                    pincodeRef.current = ref;
                                }}
                                autoComplete='off'
                                className='selectreact'
                                onValueChange={handlePincodeChange}
                                value={selectedcustomer.pincode} style={{ width: "60%", textAlign: "right" }}
                                maxLength={6}
                                readOnly={docutype === "credit" ? true : false}
                                thousandSeparator={false}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                isNumericString
                            />
                        </div>
                        <div className="input-top-item" style={{ width: "14%" }}>
                            <label style={{ marginLeft: "2px", width: "25%" }}>GSTIN</label>
                            <InputMask
                                mask="D9AAAAA9999A*Z*"
                                maskChar={null}
                                formatChars={{
                                    '*': '[A-Za-z0-9]',
                                    'D': '[0-3]',
                                    'A': '[A-Za-z]',
                                    'Z': '[Zz]',
                                    '9': '[0-9]'
                                }}
                                id='gstin'
                                alwaysShowMask={false}
                                style={{ textTransform: "uppercase", width: "75%", textAlign: "right" }}
                                onChange={handleGSTINChange} value={selectedcustomer.gstin}
                                readOnly={docutype === "credit" ? true : false}
                            ></InputMask>
                        </div>
                    </div>
                </div>

                <div className="frmsaleinvoice-frmmain">
                    <div className="leftpanel">
                        <div className="left-container">
                            <div className="ag-theme-alpine" style={{ height: "80%", width: '100%' }}                       >
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={columnDefs}
                                    rowData={curdata}
                                    rowHeight={25}
                                    gridOptions={gridOptions}
                                    getRowClass={getRowClass}
                                    onRowClicked={handleRowClick} // Handle row click
                                    // onGridReady={(params) => {
                                    //     params.api.getDisplayedRowAtIndex(selectedIndex).setSelected(true); // Set initial row selected
                                    // }}
                                    // suppressHorizontalScroll={true}
                                    onFilterChanged={() => {
                                        const firstRowNode = gridRef.current.api.getDisplayedRowAtIndex(0);
                                        if (firstRowNode) {
                                            gridRef.current.api.setFocusedCell(0, 'docuno');
                                            gridRef.current.api.getRowNode(firstRowNode.id).setSelected(true);
                                        }
                                    }}
                                    domLayout="normal"
                                />
                            </div>

                            <div className='tabledata-footer'>
                                <label style={{ width: "420px", textAlign: "center" }}>Total Qty ==&gt;&gt;</label>
                                <label style={{ width: "65px", textAlign: "center" }} >{totalQty}</label>
                            </div>
                            <div className="mybtncontainer">
                                <button className="button-with-icon" onClick={handleAddNew}><AiOutlinePlus className="btn-icon" /><span className="button-text">Add Item</span></button>
                                <div className='frmsaleinvoice-taxvalue'>
                                    <label > Sale Value</label>
                                    <input type="text" value={saleValue} style={{ color: "green" }} readOnly />
                                    <label > Bill Amount</label>
                                    <input type="text" value={debitamount.toFixed(2)} style={{ color: "red" }} readOnly />
                                </div>
                                <div className="frmsaleinvoice-btngroup-save-cancel">
                                    {/* <button className="button-with-icon" onClick={handlePrint}><AiOutlineFilePdf className="btn-icon" /><span className="button-text">Print</span></button> */}
                                    <button className="button-with-icon" onClick={handleClosesaleinvoice}><AiOutlineClose className="btn-icon" /><span className="button-text">Close</span></button>
                                    {/* <button className="button-with-icon" ><AiOutlineDelete className="btn-icon" /><span className="button-text">Delete</span></button> */}
                                    <button className="button-with-icon" onClick={handlesaleinvoiceSave} ref={defaultsaleinvoiceSaveRef}><AiOutlineSave className="btn-icon" /><span className="button-text">Save</span></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="rightpanel">
                        <div className="right-container" >
                            <div className="input-row">
                                <label> Product Serial :</label>
                                <NumericFormat
                                    getInputRef={(ref) => {
                                        pdtserialRef.current = ref;
                                    }}
                                    autoComplete='off'
                                    id='si_pdtserial'
                                    style={{ textAlign: "right", paddingRight: "5px", width: "15%" }}
                                    value={pdtserial}
                                    thousandSeparator={false}
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    onValueChange={handlepdtSerialChange}
                                    placeholder=""
                                    isNumericString
                                    maxLength={5}
                                    onDoubleClick={onDoubleClickpdtserial}
                                    onKeyDown={(event) => {
                                        if (event.key === 'F4') {
                                            onDoubleClickpdtserial()
                                        }
                                    }}
                                    readOnly={curmode[0] === 'Edit'}
                                />
                                {isModalOpen && (
                                    <ProductMasterList
                                        closeModal={closeModal}
                                        onRowDoubleClick={handleRowDoubleClick}
                                        selecteditems={selecteditem}
                                    />
                                )}
                            </div>

                            <div className="input-row">
                                <label >Product Name :</label>
                                <input type='text'
                                    autoComplete='off'
                                    name="pdtname"
                                    className='selectreact'
                                    id="pdtname"
                                    style={{ width: "73%" }}
                                    value={selecteditem.pdtname}
                                    ref={pdtnameRef}
                                    onDoubleClick={onDoubleClickpdtserial}
                                    onKeyDown={(event) => {
                                        if (event.key === 'F4') {
                                            onDoubleClickpdtserial()
                                        }
                                    }}
                                    readOnly
                                />
                                {isModalOpen && (
                                    <ProductMasterList
                                        closeModal={closeModal}
                                        onRowDoubleClick={handleRowDoubleClick}
                                        selecteditems={selecteditem}
                                    />
                                )}
                            </div>

                            <div className="input-row">
                                <label> Qty:</label>

                                <NumericFormat
                                    getInputRef={(ref) => {
                                        qtyRef.current = ref;
                                    }}
                                    autoComplete='off'
                                    id='si_qty'
                                    style={{ textAlign: "right", paddingRight: "5px" }}
                                    value={qty}
                                    thousandSeparator={false}
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    onValueChange={handleChangeQty}
                                    placeholder=""
                                    isNumericString
                                />
                                <label>&nbsp;&nbsp;{pdtdata.uofm}</label>

                            </div>

                            <div className='input-row'>
                                <label > Rate:</label>
                                <NumericFormat
                                    getInputRef={(ref) => {
                                        rateRef.current = ref;
                                    }}
                                    autoComplete='off'
                                    id='si_rate'
                                    style={{ textAlign: "right", paddingRight: "5px" }}
                                    value={rate}
                                    thousandSeparator={false}
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    onValueChange={handleChangeRate}
                                    placeholder=""
                                    isNumericString
                                    tabIndex={-1}
                                />
                            </div>

                            <div className='input-row'>
                                <label > Rate Per:</label>

                                <NumericFormat
                                    getInputRef={(ref) => {
                                        rateperRef.current = ref;
                                    }}
                                    autoComplete='off'
                                    id='si_rateper'
                                    style={{ textAlign: "right", paddingRight: "5px" }}
                                    value={rateper}
                                    thousandSeparator={false}
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    onValueChange={handleChangeRatePer}
                                    placeholder=""
                                    isNumericString
                                    tabIndex={-1}
                                />
                                <label>&nbsp;&nbsp;{pdtdata.uofm}</label>

                            </div>

                            <div className="input-row">
                                <label> Amount:</label>
                                <NumericFormat
                                    getInputRef={(ref) => {
                                        amountRef.current = ref;
                                    }}
                                    autoComplete='off'
                                    id='si_amount'
                                    style={{ textAlign: "right", paddingRight: "5px" }}
                                    value={amount}
                                    thousandSeparator={false}
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    onValueChange={handleChangeAmount}
                                    placeholder=""
                                    isNumericString
                                    readOnly
                                    tabIndex={-1}
                                />
                            </div>


                        </div>

                        <div className="frmsaleinvoice-btngroup-save-cancel" style={{ width: "100%", justifyContent: "flex-end" }}>
                            <button className="button-with-icon" onClick={handleCancelItem} tabIndex={-1} ref={defaultEscapeRef}><AiOutlineClose className="btn-icon" /><span className="button-text">Cancel</span></button>
                            <button className="button-with-icon" onClick={handleSaveItem} ref={defaultEnterRef}><AiOutlineSave className="btn-icon" /><span className="button-text">Save</span></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

};
export default Saleinvoice;
