
import React, { useState,useRef,useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ProductGroup.css'
import { useSelector } from 'react-redux';

const ProductGroup = () => {
  const myNavigate=useNavigate()
  const pdtgroupserialRef = useRef(null);
  const pdtgroupnameRef = useRef(null);
  let pdtgroupcode = 0;
  let url="";
  const defaultEnterRef=useRef(null)
  const defaultEscapeRef=useRef(null)

  const curmode=useSelector(function (data) {
    return data.pdtgroupmode;
  })

  if (curmode[0]==="Edit") {
    pdtgroupcode=curmode[1]
  }
  
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
          defaultEnterRef.current.click();
      }
      if (event.key === 'Escape') {
          defaultEscapeRef.current.click();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    const fetchdata = async (e) => {
      const data = { pdtgroupcode : curmode[1] };
      const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productgroup_readbygroupcode`,data)
      if (response.data.length>0) {
        pdtgroupserialRef.current.value = response.data[0].pdtgroupserial;
        pdtgroupnameRef.current.value = response.data[0].pdtgroupname;
      }
    }
  
    if (curmode[0]==="New") {
      pdtgroupserialRef.current.value=1;
      axios.get(`${process.env.REACT_APP_RAINBOW_URL}/productgroup_lastserial`)
      .then(response => {
          console.log(response.data.pdtgroupserial);
          pdtgroupserialRef.current.value = response.data.pdtgroupserial+1;
      })
      .catch(error => {
          console.error('Error fetching last record:', error);
      });
    } 

    if (curmode[0]==="Edit") {
      fetchdata();
    } 

  }, [curmode]);

  useEffect(() => {
    pdtgroupnameRef.current.focus();
  }, []);
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const pdtgroupserial = parseInt(pdtgroupserialRef.current.value);
    const pdtgroupname = pdtgroupnameRef.current.value;

    if (!pdtgroupserial>0) {
      alert("Serial No. Should be given..");
      return;
    }

    if (!pdtgroupname==="") {
      alert("Product Group Name Should be Given")
      return;
    }

    pdtgroupcode=0;
    if (curmode[0]==="Edit") {
      pdtgroupcode=curmode[1];
    }

    try {
      const data= {
        pdtgroupserial : pdtgroupserial,
        pdtgroupcode : pdtgroupcode
      };
      const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productgroup_readpdtgroupserial`,  data );
      if (response.data.length>0) {
        alert('Serial already Given ')
        return;
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (show error message, etc.)
    }

    try {
      const data= {
        pdtgroupname : pdtgroupname,
        pdtgroupcode : pdtgroupcode
      };
      const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productgroup_readpdtgroupname`,  data );
      if (response.data.length>0) {
        alert('Group Name already Given ')
        return;
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (show error message, etc.)
    }

    try {
      const data= { 
        pdtgroupserial : pdtgroupserial,
        pdtgroupcode : pdtgroupcode,
        pdtgroupname : pdtgroupname
      };
      
      console.log( data )
      url = `${process.env.REACT_APP_RAINBOW_URL}/productgroup_create`
      if (curmode[0]==="Edit") {
        url = `${process.env.REACT_APP_RAINBOW_URL}/productgroup_update`
      }
      const response = await axios.post(url, data );
      console.log(response.data);

      myNavigate("/listproductgroup")

      // Handle success (redirect, show success message, etc.)
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (show error message, etc.)
    }
  };

  const [pdtgroupnameValue, setpdtgroupnameValue] = useState('');
  const handlepdtgroupnamechange = (event) => {
      const { value } = event.target;
      setpdtgroupnameValue(toProperCase(value));
    };
  
    // Function to convert a string to proper case
    const toProperCase = (str) => {
      return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };


  return (
    <div className="productgroup-divmain">
    <h2>Product Group</h2>
    <div className="pdtgroup-form-container">
      <form onSubmit={handleSubmit} className="pdtgroup-form">
        <div className="pdtgroup-form-group">
          <label htmlFor="pdtgroupserial" title=''>Serial No:</label>
          <input type="number" id="pdtgroupserial" ref={pdtgroupserialRef} required tabIndex="1" />
        </div>
        <div className="pdtgroup-form-group">
          <label htmlFor="pdtgroupname">Group Name:</label>
          <input 
            type="text"
            value={pdtgroupnameValue}
            onChange={handlepdtgroupnamechange}
            id="ProductgroupName" 
            ref={pdtgroupnameRef} 
            tabIndex="2"
            required />
        </div>
      <div className='updateBack'>
          <button tabIndex="4" onClick={() => myNavigate("/listproductgroup") }  ref= { defaultEscapeRef }>back</button>
          <button type="submit" tabIndex="3" ref= { defaultEnterRef } >Update</button>
      </div>
      </form>
    </div>
    </div>
  );
};

export default ProductGroup;