import React, { useState,useEffect,useRef } from 'react';
import './recaptcha.css';
import backgroundImage from '../../assets/recaptcha.jpeg'; // Add your own image here
import { AiOutlineSync,AiOutlineCheck } from "react-icons/ai";

const ReCaptcha = ({ inputallCaps = false, inputallSmall = true, inputreverse = true }) => {
  const [inputValue, setInputValue] = useState('');
  const [captchaText, setCaptchaText] = useState(generateCaptchaText());
  const inputCaptchaRef=useRef(null)

  useEffect(() => {
    inputCaptchaRef.current.focus();
  }, []);


  let reCaptchaLabel = "Captcha";
  if (inputallCaps) {
    reCaptchaLabel += ' All(Caps)'
  }
  if (inputallSmall) {
    reCaptchaLabel += ' All(Small)'
  }
  if (inputreverse) {
    reCaptchaLabel += ' Reverse'
  }

  function generateCaptchaText() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let captchadata = captchaText;
    if (inputallCaps) {
      captchadata = captchadata.toUpperCase();
    }
    if (inputallSmall) {
      captchadata = captchadata.toLowerCase();
    }
    if (inputreverse) {
      captchadata = reverseString(captchadata);
    }
    if (inputValue === captchadata) {
      alert('Captcha verified successfully');
    } else {
      alert('Captcha verification failed');
      inputCaptchaRef.current.focus();
      // regenerateCaptcha();
    }
  };

  const regenerateCaptcha = () => {
    setCaptchaText(generateCaptchaText());
    setInputValue('');
    inputCaptchaRef.current.focus();
  };

  const reverseString = (str) => {
    return str.split('').reverse().join('');
  };


  return (
    <div className="captcha-container">
      <form onSubmit={handleSubmit}>
        <div className='captcha-header'>
          <div className='captcha-inner'>
          <label className='captcha-heading-label' htmlFor="captcha-userinput">{reCaptchaLabel}</label>
            <div className='captcha-row'>
              <div className="captcha-image">
                <img src={backgroundImage} alt="background" className="captcha-background" />
                <div className="captcha-text">{captchaText}</div>
              </div>
              <span><AiOutlineSync className='captcha-refresh-icon' onClick={regenerateCaptcha} /></span>
            </div>
          </div>
          <div className='captcha-input'>
            <input
              id="captcha-userinput"
              maxLength={6}
              type="text"
              value={inputValue}
              onChange={handleChange}
              ref={inputCaptchaRef}
            />
            <span><AiOutlineCheck className='captcha-refresh-icon' onClick={handleSubmit} /></span>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ReCaptcha;
