import React, { useRef, useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { FaWhatsapp } from 'react-icons/fa';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ClientProfile = () => {
    let url;
    let response;
    const coyIdRef = useRef();
    const coynameRef = useRef();
    const address1Ref = useRef();
    const address2Ref = useRef();
    const address3Ref = useRef();
    const address4Ref = useRef();
    const placeRef = useRef();
    const pincodeRef = useRef();
    const statenameRef = useRef();
    const districtRef = useRef();
    const mobile1Ref = useRef();
    const email1Ref = useRef();
    const gstinRef = useRef();
    const constitutionRef = useRef();
    const usergroupRef = useRef();
    const startdateRef = useRef();
    const startyearRef = useRef();
    const auditorcodeRef = useRef();
    const cpnameRef = useRef();
    const gstreturntypeRef = useRef();
    const gstofficeRef = useRef();
    const gstofficernameRef = useRef();
    const datareceivedtypeRef = useRef();
    const servicetypeRef = useRef();
    const gstusernameRef = useRef();
    const gstpasswordRef = useRef();
    const ewbusernameRef = useRef();
    const ewbpasswordRef = useRef();
    const gstfiledbyRef = useRef();


    const servicetypeArray = [
        { code: 1, value: 'Full Service' },
        { code: 2, value: 'Return Only' },
        { code: 3, value: 'Account Maintanence Only' },
        { code: 4, value: 'Consulting Only' },
        { code: 5, value: 'Others' },
    ];

    const datareceivedtypeArray = [
        { code: 1, value: 'File Received (Bill Copy)' },
        { code: 2, value: 'Excel File' },
        { code: 3, value: 'Data File' },
        { code: 4, value: 'Consultant' }
    ];

    useEffect(() => {
        coyIdRef.current.focus();
    }, []);

    const [passwordVisibility, setPasswordVisibility] = useState({
        gstpassword: false,
        ewbpassword: false,
    });

    const togglePasswordVisibility = (field) => {
        setPasswordVisibility((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const [auditordata, setAuditordata] = useState([]);

    useEffect(() => {
        const handleFetchAuditorlist = async () => {
            try {
                const response = await axios.post('http://localhost:5000/auditorlist_getall');
                console.log(response);
                setAuditordata(response.data.data);
            } catch (error) {
                console.error('Error fetching Auditor:', error);
            }
        };
        handleFetchAuditorlist();
        coyIdRef.current.focus();
    }, []);

    const [formdata, setFormdata] = useState({
        coyId: '',
        coyname: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        place: '',
        pincode: '',
        statename: '',
        district: '',
        mobile1: '',
        mobile2: '',
        mobile3: '',
        whatsapp1: false,
        whatsapp2: false,
        whatsapp3: false,
        email1: '',
        email2: '',
        website: '',
        gstin: '',
        aadhaar: '',
        pan: '',
        constitution: '',
        usergroup: '',
        startdate: '',
        startyear: '',
        closedate: '',
        closeyear: '',
        it: false,
        itindividual: false,
        auditorcode: '',
        cpname: '',
        cpmobile1: '',
        cpmobile2: '',
        cpmobile3: '',
        cpwhatsapp1: false,
        cpwhatsapp2: false,
        cpwhatsapp3: false,
        cpemail: '',
        gstreturntype: '',
        gstjurisdiction: '',
        gstoffice: '',
        gstofficername: '',
        datareceivedtype: '',
        servicetype: '',
        gstannualreturn: '',
        einvoice: '',
        gstusername: '',
        gstpassword: '',
        ewbusername: '',
        ewbpassword: '',
        dscexpirydate: '',
        gstfiledby: 'EVC',

    });

    const currentYear = new Date().getFullYear(); // Get the current year, e.g., 2024
    const startyearOptions = [];
    for (let i = 0; i < 10; i++) {
        const startYear = currentYear - i;
        const endYear = (startYear + 1).toString().slice(-2); // Get the last two digits of the next year
        startyearOptions.push(`${startYear}-${endYear}`);
    }

    const closeyearOptions = [];
    for (let i = -2; i <= 10; i++) {
        const startYear = currentYear + i;
        const endYear = (startYear + 1).toString().slice(-2); // Get the last two digits of the next year
        closeyearOptions.push(`${startYear}-${endYear}`);
    }

    const handleClick = (event) => {
        event.preventDefault();
    };
    const toProperCase = (text) => {
        return text.replace(/\w\S*/g, (word) =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
    };
    const handleFormChange = (event) => {
        let { name, value } = event.target;

        if (name === 'coyId') {
            value = value.toUpperCase();
            const regex = /^(?!.*([-/.]).*\1)(?!.*[-][/][.])(?=.*[A-Z0-9])[A-Z0-9]*([-/.][A-Z0-9]*)*$/;
            if (!regex.test(value) || value.length > 8) {
                return;
            }
        }
        else if (name === 'gstin') {
            if (!(/^[A-Z0-9a-z]*$/.test(value) && value.length <= 15)) {
                return;
            }
        } else if (name === 'whatsapp1' || name === 'whatsapp2' || name === 'einvoice' ||
            name === 'whatsapp3' || name === 'cpwhatsapp1' || name === 'cpwhatsapp2' || name === 'cpwhatsapp3' || name === 'gstannualreturn') {
            value = event.target.checked;
        }
        else if (name === 'it' || name === 'itindividual') {
            value = event.target.checked;
            setFormdata((prevFormdata) => ({
                ...prevFormdata,
                auditorcode: ''
            }));
        } else if (name === 'coyname' || name === 'address1' || name === 'address2' || name === 'address3' || name === 'address4' ||
            name === 'place' || name === 'usergroup' || name === 'cpname' || name === 'gstoffice' || name === 'gstofficername'
            || name === 'gstusername' || name === 'ewbusername') {
            value = toProperCase(value);
            const { selectionStart, selectionEnd } = event.target;
            setTimeout(() => {
                if (name === 'coyname') {
                    coynameRef.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'address1') {
                    address1Ref.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'address2') {
                    address2Ref.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'address3') {
                    address3Ref.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'address4') {
                    address4Ref.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'place') {
                    placeRef.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'usergroup') {
                    usergroupRef.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'cpname') {
                    cpnameRef.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'gstoffice') {
                    gstofficeRef.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'gstofficername') {
                    gstofficernameRef.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'gstusername') {
                    gstusernameRef.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'ewbusername') {
                    ewbusernameRef.current.setSelectionRange(selectionStart, selectionEnd);
                }
            }, 0);

        }
        setFormdata((prevFormdata) => ({
            ...prevFormdata,
            [name]: value
        }));
    };
    const [curmode, setCurmode] = useState(["New", 0]);
    const handleProfileSave = async() => {
        let value = formdata.coyId;
        if (value === '') {
            alert('Must Enter Company Id');
            coyIdRef.current.focus();
            return;
        }
        value = formdata.coyname;
        if (value === '') {
            alert('Must Enter Company Name');
            coynameRef.current.focus();
            return;
        }
        value = formdata.address1;
        if (value === '') {
            alert('Must Enter Address');
            address1Ref.current.focus();
            return;
        }
        value = formdata.place;
        if (value === '') {
            alert('Must Enter Place');
            placeRef.current.focus();
            return;
        }
        value = formdata.pincode;
        if (value === '') {
            alert('Must Enter Pincode');
            window.document.getElementById('pincode').focus();
            window.document.getElementById('pincode').select();
            return;
        }
        // value= formdata.statename;
        // if(value===''){
        //     alert('Must Choose State');
        //     statenameRef.current.focus();
        //     return;
        // }
        // value= formdata.district;
        // if(value===''){
        //     alert('Must Choose District');
        //     districtRef.current.focus();
        //     return;
        // }
        value = formdata.mobile1;
        if (value === '') {
            alert('Must Enter Mobile No.');
            mobile1Ref.current.focus();
            return;
        }
        value = formdata.email1;
        if (value === '') {
            alert('Must Enter Email');
            email1Ref.current.focus();
            return;
        }
        value = formdata.gstin;
        if (value === '') {
            alert('Must Enter GSTIN');
            window.document.getElementById('gstin').focus();
            window.document.getElementById('gstin').select();
            return;
        }
        value = formdata.constitution;
        if (value === '') {
            alert('Must Choose Constitution');
            constitutionRef.current.focus();
            return;
        }
        value = formdata.startdate;
        if (value === '') {
            alert('Must Enter Starting Date');
            startdateRef.current.focus();
            return;
        }
        value = formdata.startyear;
        if (value === '') {
            alert('Must Choose Starting Year');
            startyearRef.current.focus();
            return;
        }
        value = formdata.auditorcode;
        if ((formdata.it || formdata.itindividual) && (value === '')) {
            alert('Auditor Name Missing');
            auditorcodeRef.current.focus();
        }
        value = formdata.cpname;
        if (value === '') {
            alert('Must Enter Contact Person');
            cpnameRef.current.focus();
            return;
        }
        try {
            const data = {
                actcode: (curmode[0] === 'New' ? 0 : curmode[1]),
                actid: formdata.coyId,
                actname: formdata.coyname,
                add1: formdata.address1,
                add2: formdata.address2,
                add3: formdata.address3,
                add4: formdata.address4,
                place: formdata.place,
                pincode: formdata.pincode,
                statename: formdata.statename,
                district: formdata.district,
                mobile1: formdata.mobile1,
                mobile2: formdata.mobile2,
                mobile3: formdata.mobile3,
                whatsapp1: formdata.whatsapp1 ? 1 : 0,
                whatsapp2: formdata.whatsapp2 ? 1 : 0,
                whatsapp3: formdata.whatsapp3 ? 1 : 0,
                email1: formdata.email1,
                email2: formdata.email2,
                website: formdata.website,
                gstin: formdata.gstin,
                aadhaar: formdata.aadhaar,
                pan: formdata.pan,
                constitution: formdata.constitution,
                usergroup: formdata.usergroup,
                startdate: formdata.startdate,
                startyear: formdata.startyear,
                closedate: formdata.closedate,
                closeyear: formdata.closeyear,
                it: formdata.it ? 1 : 0,
                itindividual: formdata.itindividual ? 1 : 0,
                auditorcode: formdata.auditorcode,
                cpname: formdata.cpname,
                cpmobile1: formdata.cpmobile1,
                cpmobile2: formdata.cpmobile2,
                cpmobile3: formdata.cpmobile3,
                cpwhatsapp1: formdata.cpwhatsapp1 ? 1 : 0,
                cpwhatsapp2: formdata.cpwhatsapp2 ? 1 : 0,
                cpwhatsapp3: formdata.cpwhatsapp3 ? 1 : 0,
                cpemail: formdata.cpemail,
                gstreturntype: formdata.gstreturntype,
                gstjurisdiction: formdata.gstjurisdiction,
                gstoffice: formdata.gstoffice,
                gstofficer: formdata.gstofficername,
                datareceivedtype: formdata.datareceivedtype,
                servicetype: formdata.servicetype,
                gstannualreturn: formdata.gstannualreturn ? 1 : 0,
                einvoice: formdata.einvoice ? 1 : 0,
                gstusername: formdata.gstusername,
                gstpassword: formdata.gstpassword,
                ewbusername: formdata.ewbusername,
                ewbpassword: formdata.ewbpassword,
                gstfiledby: formdata.gstfiledby,
                dscexpirydate: formdata.dscexpirydate

            }
            console.log(data);
            url = `${process.env.REACT_APP_EMPLOYEE_URL}/companyprofile_create`;

            // if (curmode === 'Edit') {
            //     url = `${process.env.REACT_APP_EMPLOYEE_URL}/profilepartners_update`;
            // }
            response = await axios.post(url, data);
            console.log(response);
            // handleFetchAll();
        }
        catch (error) {
            console.error('Error Submitting Form:', error);
        }

    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission on Enter
        }
    };
    const defaultEnterRef = useRef('');
    const defaultEscapeRef = useRef('');

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Enter') {
                defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                defaultEscapeRef.current.click();
            }

        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (

        <form className='clientprofile-forms' onSubmit={handleClick} onKeyDown={handleKeyDown}>
            <div className='clientprofile-heading'>
                <p>Profile</p>
            </div>
            <div className='clientprofile-container'>
                <div className='clientprofile-leftpanel'>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="coyid" >Company ID:</label>
                        <div className='clientprofile-form-subgroup1'>
                            <input type="text" id='coyid' name='coyId' value={formdata.coyId} ref={coyIdRef} onChange={handleFormChange} style={{ width: "20%" }} />
                            <div className='clientprofile-form-subgroup2'>
                                <label htmlFor="coycode" id='coylabelid' style={{ width: "20%", textAlign: "right" }} >Code:</label>
                                <input type="text" id='coycode' style={{ width: "20%" }} readOnly tabIndex={-1} />
                            </div>
                        </div>
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="coyname"  >Company Name:</label>
                        <input type="text" id='coyname' name='coyname' ref={coynameRef} value={formdata.coyname} onChange={handleFormChange} />
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="address1"> Address:</label>
                        <input type="text" id='address1' name='address1' ref={address1Ref} value={formdata.address1} onChange={handleFormChange} />
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="address2"> </label>
                        <input type="text" id='address2' name='address2' ref={address2Ref} value={formdata.address2} onChange={handleFormChange} />
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="address3"> </label>
                        <input type="text" id='address3' name='address3' ref={address3Ref} value={formdata.address3} onChange={handleFormChange} />
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="address4"> </label>
                        <input type="text" id='address4' name='address4' ref={address4Ref} value={formdata.address4} onChange={handleFormChange} />
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="place" > Place:</label>
                        <div className='clientprofile-form-subgroup1'>
                            <input type="text" id='place' style={{ width: "55%" }} name='place' value={formdata.place} ref={placeRef} onChange={handleFormChange} />
                            <div className='clientprofile-form-subgroup2' style={{ width: "45%" }}>
                                <label htmlFor="pincode" id='pincodelabelid' style={{ width: "50%", textAlign: "right" }} > PinCode:</label>
                                <InputMask
                                    mask="999999"
                                    maskChar={null}
                                    alwaysShowMask={false}
                                    id='pincode' style={{ width: "50%" }} name='pincode' value={formdata.pincode} ref={pincodeRef} onChange={handleFormChange}
                                ></InputMask>

                            </div>
                        </div>
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="statename" > State:</label>
                        <div className='clientprofile-form-subgroup1'>
                            <select id='statename' style={{ width: "40%" }} name='statename' value={formdata.statename} ref={statenameRef} onChange={handleFormChange}>
                                <option value="TamilNadu">TamilNadu</option>
                                <option value="TamilNadu2">TamilNadu2</option>
                            </select>
                            <div className='clientprofile-form-subgroup2' style={{ width: "60%" }}>
                                <label htmlFor="district" id='districtlabelid' style={{ width: "30%", textAlign: "right" }}>  District:</label>
                                <select id='district' style={{ width: "70%" }} name='district' value={formdata.district} ref={districtRef} onChange={handleFormChange} >
                                    <option value="TamilNadu">TamilNadu</option>
                                    <option value="TamilNadu2">TamilNadu2</option>
                                </select>
                            </div>
                        </div>
                    </div>


                    <div className='clientprofile-form-group'>
                        <label htmlFor="mobile1" className='mobileno' > Mobile:</label>
                        <div className='clientprofile-form-subgroup1'>
                            <InputMask
                                mask="999999999999"
                                maskChar={null}
                                alwaysShowMask={false}
                                id='mobile1' name='mobile1' value={formdata.mobile1} ref={mobile1Ref} onChange={handleFormChange} style={{ width: "28%" }}
                            ></InputMask>
                            <FaWhatsapp />
                            <input type="checkbox" id='whatsapp1' name='whatsapp1' checked={formdata.whatsapp1} onChange={handleFormChange} style={{ width: "4%" }} />
                            <InputMask
                                mask="999999999999"
                                maskChar={null}
                                alwaysShowMask={false}
                                id='mobile2' name='mobile2' value={formdata.mobile2} onChange={handleFormChange} style={{ width: "28%" }}
                            ></InputMask>

                            <FaWhatsapp />
                            <input type="checkbox" id='whatsapp2' name='whatsapp2' checked={formdata.whatsapp2} onChange={handleFormChange} style={{ width: "4%" }} />
                            <InputMask
                                mask="999999999999"
                                maskChar={null}
                                alwaysShowMask={false}
                                id='mobile3' name='mobile3' value={formdata.mobile3} onChange={handleFormChange} style={{ width: "28%" }}
                            ></InputMask>
                            <FaWhatsapp />
                            <input type="checkbox" id='whatsapp3' name='whatsapp3' checked={formdata.whatsapp3} onChange={handleFormChange} style={{ width: "4%" }} />
                        </div>
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="email1" > Email:</label>
                        <div className='clientprofile-form-subgroup1'>
                            <input type="text" id='email1' name='email1' style={{ width: "50%" }} value={formdata.email1} ref={email1Ref} onChange={handleFormChange} />
                            <input type="text" id='email2' name='email2' style={{ width: "50%" }} value={formdata.email2} onChange={handleFormChange} />
                        </div>
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="website"> Website:</label>
                        <input type="text" id='website' name='website' value={formdata.website} onChange={handleFormChange} />
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="gstin" > GSTIN:</label>
                        <InputMask
                            mask="D9AAAAA9999A*Z*"
                            maskChar={null}
                            formatChars={{
                                '*': '[A-Z0-9]',
                                'D': '[0-3]'

                            }}
                            alwaysShowMask={false}
                            // placeholder='99AAAAA9999AAZA'
                            id='gstin' name='gstin' value={formdata.gstin} ref={gstinRef} onChange={handleFormChange}
                        ></InputMask>

                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="aadhaar"> Aadhaar:</label>
                        <div className='clientprofile-form-subgroup1'>
                            <InputMask
                                mask="999999999999"
                                maskChar={null}
                                alwaysShowMask={false}
                                id='aadhaar' style={{ width: "45%" }}
                                name='aadhaar' value={formdata.aadhaar} onChange={handleFormChange}
                            ></InputMask>
                            <div className='clientprofile-form-subgroup2' style={{ width: "55%" }}>
                                <label htmlFor="pan" style={{ width: "30%", textAlign: "right" }} > PAN:</label>
                                <InputMask
                                    mask="AAAAA9999A"
                                    maskChar={null}
                                    alwaysShowMask={false}
                                    id='pan' style={{ width: "70%" }}
                                    name='pan' value={formdata.pan} onChange={handleFormChange}
                                ></InputMask>

                            </div>
                        </div>
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="constitution"> Constitution:</label>
                        <select id="constitution" name='constitution' value={formdata.constitution} ref={constitutionRef} onChange={handleFormChange}>
                            <option value="" disabled style={{ width: "28%" }}>Select Constitution</option>
                            <option value="Proprietor">Proprietor </option>
                            <option value="Proprietrix">Proprietrix </option>
                            <option value="Partner">Partner </option>
                            <option value="Company">Company </option>
                            <option value="Trust">Trust </option>
                        </select>
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="usergroup"> User Group:</label>
                        <input type="text" id='usergroup' name='usergroup' ref={usergroupRef} value={formdata.usergroup} onChange={handleFormChange} />
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="joindate"> Starting Date:</label>
                        <div className='clientprofile-form-subgroup1'>
                            <InputMask
                                mask="99/99/99"
                                placeholder="DD/MM/YY"
                                id='joindate' style={{ width: "25%" }} name='startdate' value={formdata.startdate} ref={startdateRef} onChange={handleFormChange}
                            />

                            <div className='clientprofile-form-subgroup2' style={{ width: "75%" }}>
                                <label htmlFor="startyear" id='startyearlabelid' style={{ width: "45%", textAlign: "right" }}>Start Year:</label>
                                <select id='startyear' style={{ width: "55%" }} name='startyear' value={formdata.startyear} ref={startyearRef} onChange={handleFormChange}>
                                    <option value='' disabled selected> Select Year ...</option>
                                    {startyearOptions.map((yearOption, index) => (
                                        <option key={index} value={yearOption}>{yearOption}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='clientprofile-form-group'>
                        <label htmlFor="closedate"> Close Date:</label>
                        <div className='clientprofile-form-subgroup1'>
                            <input type="text" id='closedate' style={{ width: "25%" }} readOnly />
                            <div className='clientprofile-form-subgroup2' style={{ width: "75%" }}>
                                <label htmlFor="closeyear" style={{ width: "45%", textAlign: "right" }}>Close Year:</label>
                                <select id='closeyear' style={{ width: "55%" }} disabled>
                                    <option value='' disabled selected> Select Year ...</option>
                                    {closeyearOptions.map((yearOption, index) => (
                                        <option key={index} value={yearOption}>{yearOption}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='clientprofile-formcheckbox-group'>
                        <div className='clientprofile-formcheckbox-subgroup'>


                            <label htmlFor="it">IT</label>
                            <input
                                type="checkbox"
                                id='it'
                                name='it'
                                checked={formdata.it}
                                onChange={handleFormChange}
                                style={{ height: "16px" }}
                            />
                            <label htmlFor="itindividual">Individual:</label>
                            <input
                                type="checkbox"
                                id='itindividual'
                                name='itindividual'
                                checked={formdata.itindividual}
                                onChange={handleFormChange}
                                style={{ height: "16px" }}
                                disabled={(formdata.constitution !== 'Proprietor' && formdata.constitution !== 'Proprietrix')}
                            />


                            < div className='clientprofile-formcheckbox-subgroup'>
                                <label htmlFor="auditorcode"> Auditor Name: </label>

                                <select id='auditorcode'
                                    style={{ width: "65%", height: "28px" }}
                                    name='auditorcode'
                                    ref={auditorcodeRef}
                                    value={formdata.auditorcode}
                                    onChange={handleFormChange}
                                    disabled={(!formdata.it && !formdata.itindividual)}>
                                    <option value="" disabled selected>Select Auditor...</option>

                                    {auditordata.map(data => (
                                        <option key={data.auditorcode}
                                            value={data.auditorcode}>{data.auditorname}</option>

                                    ))}

                                </select>

                            </div>

                        </div>
                    </div>
                </div>
                <div className='clientprofile-rightpanel'>
                    <div className='clientprofile-cpgroup'>
                        <div className='clientprofile-form-group'>
                            <label htmlFor=""> </label>

                        </div>
                        <div className='clientprofile-form-group'>
                            <label htmlFor="cpname"> Contact Person:</label>
                            <input type="text" id='cpname' ref={cpnameRef} name='cpname' value={formdata.cpname} onChange={handleFormChange} />
                        </div>
                        <div className='clientprofile-form-group'>
                            <label htmlFor="cpmobile1" className='cpmobileno'> Mobile:</label>
                            <div className='clientprofile-form-subgroup1'>
                                <InputMask
                                    mask="999999999999"
                                    maskChar={null}
                                    alwaysShowMask={false}
                                    id='cpmobile1' style={{ width: "28%" }} name='cpmobile1' value={formdata.cpmobile1} onChange={handleFormChange}
                                ></InputMask>
                                <FaWhatsapp />
                                <input type="checkbox" id='cpwhatsapp1' name='cpwhatsapp1' checked={formdata.cpwhatsapp1} onChange={handleFormChange} style={{ width: "4%" }} />
                                <InputMask
                                    mask="999999999999"
                                    maskChar={null}
                                    alwaysShowMask={false}
                                    id='cpmobile2' style={{ width: "28%" }} name='cpmobile2' value={formdata.cpmobile2} onChange={handleFormChange}
                                ></InputMask>
                                <FaWhatsapp />
                                <input type="checkbox" id='cpwhatsapp2' name='cpwhatsapp2' checked={formdata.cpwhatsapp2} onChange={handleFormChange} style={{ width: "4%" }} />
                                <InputMask
                                    mask="999999999999"
                                    maskChar={null}
                                    alwaysShowMask={false}
                                    id='cpmobile3' style={{ width: "28%" }} name='cpmobile3' value={formdata.cpmobile3} onChange={handleFormChange}
                                ></InputMask>
                                <FaWhatsapp />
                                <input type="checkbox" id='cpwhatsapp3' name='cpwhatsapp3' checked={formdata.cpwhatsapp3} onChange={handleFormChange} style={{ width: "4%" }} />
                            </div>
                        </div>
                        <div className='clientprofile-form-group'>
                            <label htmlFor="cpemail"> Email:</label>
                            <input type="text" id='cpemail' name='cpemail' value={formdata.cpemail} onChange={handleFormChange} />
                        </div>
                    </div>
                    <div className='clientprofile-GSTgroup'>


                        <div className='clientprofile-GSTpanel'>
                            <div className='clientprofile-GSTheading-group' >
                                <p className='gradient-text'>GST</p>
                            </div>
                            <div className='clientprofile-GSTform-group'>
                                <label htmlFor="gstreturntype" style={{ width: "30%" }}> Return Type:</label>
                                <div className='clientprofile-form-subgroup1'>
                                    <select id='gstreturntype' style={{ width: "36%" }} name='gstreturntype' value={formdata.gstreturntype} ref={gstreturntypeRef} onChange={handleFormChange}>
                                        <option value="" disabled selected>Select Return Type</option>
                                        <option value="R">Regular</option>
                                        <option value="C">Compounding</option>
                                    </select>
                                    <div className='clientprofile-form-subgroup2' style={{ width: "64%" }}>
                                        <label htmlFor="gstjurisdiction" style={{ width: "40%", textAlign: "right" }}> Jurisdiction:</label>
                                        <select id='gstjurisdiction' style={{ width: "60%" }}
                                            name='gstjurisdiction'
                                            value={formdata.gstjurisdiction}
                                            onChange={handleFormChange}
                                        >
                                            <option value="" disabled>Select Jurisdiction</option>
                                            <option value="S">State</option>
                                            <option value="C">Central</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='clientprofile-GSTform-group'>
                                <label htmlFor="gstoffice" >{formdata.gstjurisdiction === 'C' ? 'Range:' : 'Office:'}</label>
                                <input type="text" id='gstoffice' ref={gstofficeRef} name='gstoffice' value={formdata.gstoffice} onChange={handleFormChange} />
                            </div>
                            <div className='clientprofile-GSTform-group'>
                                <label htmlFor="gstofficername">Officer Name:</label>
                                <input type="text" id='gstofficername' ref={gstofficernameRef} name='gstofficername' value={formdata.gstofficername} onChange={handleFormChange} />
                            </div>
                            <div className='clientprofile-GSTform-group'>
                                <label htmlFor="datareceivedtype" > Data Received Type:</label>
                                <select id='datareceivedtype' name='datareceivedtype' value={formdata.datareceivedtype} ref={datareceivedtypeRef} onChange={handleFormChange}>
                                    <option value="" disabled selected>Select Data Received Type</option>
                                    {datareceivedtypeArray.map((option, index) => (
                                        <option key={index} value={option.code}>
                                            {option.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='clientprofile-GSTform-group'>
                                <label htmlFor="servicetype" > Service Type:</label>
                                <select id='servicetype' name='servicetype' value={formdata.servicetype} ref={servicetypeRef} onChange={handleFormChange}>
                                    <option value="" disabled selected>Select Service Type</option>
                                    {servicetypeArray.map((option, index) => (
                                        <option key={index} value={option.code}>
                                            {option.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='clientprofile-GSTform-group'>
                                <label htmlFor="" ></label>
                                <div className='clientprofile-GSTform-subgroup1'>
                                    <label htmlFor="gstannualreturn" style={{ width: "35%" }}> Annual Return:</label>
                                    <input type="checkbox" id='gstannualreturn' name='gstannualreturn' value={formdata.gstannualreturn} onChange={handleFormChange} style={{ width: "5%", height: "16px" }} />
                                    <div className='clientprofile-GSTform-subgroup2' style={{ width: "60%" }}>
                                        <label htmlFor="" style={{ width: "50%" }}></label>
                                        <label htmlFor="einvoice" style={{ width: "35%", textAlign: "right" }}> E-Invoice:</label>
                                        <input type="checkbox" id='einvoice' name='einvoice' value={formdata.einvoice} onChange={handleFormChange} style={{ width: "15%", height: "17px" }} />

                                    </div>
                                </div>
                            </div>
                            <div className='clientprofile-GSTform-group'>
                                <label htmlFor="gstusername" >GST Username:</label>
                                <input type="text" id='gstusername' ref={gstusernameRef} name='gstusername' value={formdata.gstusername} onChange={handleFormChange} />
                            </div>
                            <div className='clientprofile-GSTform-group'>
                                <label htmlFor="gstpassword" >Password:</label>
                                <div className='clientprofile-form-subgroup1' style={{ position: 'relative', width: '72%' }} >
                                    <input
                                        type={passwordVisibility.gstpassword ? 'text' : 'password'}
                                        placeholder="Enter your GST password"
                                        id='gstpassword' ref={gstpasswordRef} name='gstpassword'
                                        style={{ width: '90%' }}
                                        value={formdata.gstpassword} onChange={handleFormChange} />
                                    <span
                                        onClick={() => togglePasswordVisibility('gstpassword')}
                                        style={{ width: '10%' }}
                                    >
                                        {passwordVisibility.gstpassword ? <FaEye /> : <FaEyeSlash />}
                                    </span>
                                </div>
                            </div>
                            <div className='clientprofile-GSTform-group'>
                                <label htmlFor="ewbusername" > E-WayBill Username:</label>
                                <input type="text" id='ewbusername' ref={ewbusernameRef} name='ewbusername' value={formdata.ewbusername} onChange={handleFormChange} />
                            </div>
                            <div className='clientprofile-GSTform-group'>
                                <label htmlFor="ewbpassword" >Password:</label>
                                <div className='clientprofile-form-subgroup1' style={{ position: 'relative', width: '72%' }} >
                                    <input
                                        type={passwordVisibility.ewbpassword ? 'text' : 'password'}
                                        placeholder="Enter your E-way password"
                                        id='ewbpassword' ref={ewbpasswordRef} name='ewbpassword'
                                        style={{ width: '90%' }}
                                        value={formdata.ewbpassword} onChange={handleFormChange} />
                                    <span
                                        onClick={() => togglePasswordVisibility('ewbpassword')}
                                        style={{ width: '10%' }}
                                    >
                                        {passwordVisibility.ewbpassword ? <FaEye /> : <FaEyeSlash />}
                                    </span>
                                </div>
                            </div>
                            <div className='clientprofile-GSTform-group'>
                                <label htmlFor="gstfiledby" > Filed by:</label>
                                <div className='clientprofile-GSTform-subgroup'>
                                    <select id='gstfiledby' name='gstfiledby' value={formdata.gstfiledby} ref={gstfiledbyRef} onChange={handleFormChange} style={{ width: "35%" }}>
                                        <option value="E" selected>EVC</option>
                                        <option value="D"> Digital Token</option>
                                    </select>

                                    <div className='clientprofile-GSTform-subgroup2' style={{ width: "65%" }}>
                                        <label htmlFor="dscexpirydate" style={{ width: "50%", textAlign: "right" }}>Exp. Date: </label>
                                        <InputMask
                                            mask="99/99/99"
                                            placeholder="DD/MM/YY"
                                            id='dscexpirydate' style={{ width: "50%" }}
                                            name='dscexpirydate' value={formdata.dscexpirydate} onChange={handleFormChange}
                                            readOnly={formdata.gstfiledby !== 'D'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='clientprofile-save-cancelbtngrp' style={{ width: '100%', height: "10%" }}>
                            <button>Cancel</button>
                            <button type='submit' ref={defaultEnterRef} onClick={handleProfileSave}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    )



}
export default ClientProfile;