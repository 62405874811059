import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "./uploadPDF.css";

const Uploadpdf = () => {
  const myNavigate=useNavigate()
  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('pdfFiles', files[i]);
    }

    try {
      const response = await axios.post(process.env.PUBLIC_RAINBOW_URL + '/uploadPDF', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert(response.data);
    } catch (error) {
      console.error('Error uploading files: ', error);
    }
    myNavigate( "/emptypage")
    return;
  };

  return (
    <>
    <div className="mypage">
    <div className='mypagewindow'>

    <div className="inputfilepanel">
      <input 
        type="file" 
        multiple 
        accept=".pdf"
        onChange={handleFileChange} 
      />
      <button onClick={handleUpload}>Upload</button>
      <button onClick={() => myNavigate("/emptypage")}>Go back</button>
    </div>

    </div>
    </div>
    </>
  );
};

export default Uploadpdf;
