import React, { useRef, useState, useEffect } from 'react';
import axios from "axios";
import InputMask from 'react-input-mask';
import "./Commodity.css";

const Commodity = () => {
    let url;
    let response;

    const commoditynameRef = useRef();
    const hsncodeRef = useRef();
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const handleClick = (event) => {
        event.preventDefault();
    };

    const [formdata, setFormdata] = useState({
        commodityname: '',
        hsncode: '',
    })

    const resetForm = () => {
        setFormdata({
            commodityname: '',
            hsncode: '',
        })
    }

    const toProperCase = (text) => {
        return text.replace(/\w\S*/g, (word) =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
    };

    const handleFormChange = async (event) => {
        let { name, value } = event.target;
        if (name === 'commodityname') {
            value = toProperCase(value);
            const { selectionStart, selectionEnd } = event.target;
            setTimeout(() => {
                commoditynameRef.current.setSelectionRange(selectionStart, selectionEnd);
            }, 0);
        }

        setFormdata((prevFormdata) => ({
            ...prevFormdata,
            [name]: value
        }));

    }
    const [curmode, setcurmode] = useState(['New',0]);

    const handleAddNew = () => {
        setcurmode(['New',0]);
        resetForm();
        commoditynameRef.current.focus();
        setSelectedRowIndex(null);
    }
    const [commoditycode, setCommoditycode] = useState(0);

    const handleEdit = (data, index) => {
        setFormdata({
            commodityname: data.commodityname,
            hsncode: data.hsncode,
        })
        setcurmode(['Edit',data.commoditycode]);
        setCommoditycode(data.commoditycode);
        setSelectedRowIndex(index);
    }

    const handleDelete = async (data, index) => {
        const confirmed = window.confirm("Are you sure you want to delete this entry?");
        if (confirmed) {
            const commoditydata = {
                commoditycode: data.commoditycode
            }
            url = `${process.env.REACT_APP_RAINBOW_URL}/commodity_delete`;

            response = await axios.post(url, commoditydata)
            handleFetchAll();
            setcurmode(['New',0]);
            resetForm();
            setSelectedRowIndex(null);
        }
    };

    const handleCancel = () => {
        if (curmode[0]==="Edit") {
            return;
        }
        resetForm();
        commoditynameRef.current.focus();
        setSelectedRowIndex(null);
    }

    const [commoditydata, setCommoditydata] = useState([]);
    const handleFetchAll = async () => {
        try {
            url = `${process.env.REACT_APP_RAINBOW_URL}/commodity_getall`;
            const response = await axios.post(url);
            setCommoditydata(response.data);
        } catch (error) {
            console.error('Error fetching Commodity details:', error);
        }
    };

    useEffect(() => {
        handleFetchAll();
    }, []);


    const handleSave = async () => {
        let value = formdata.commodityname;
        if (value === '') {
            alert('Commodity Name Should be Given');
            commoditynameRef.current.focus();
            return;
        }
        value = formdata.hsncode;
        if (value === '') {
            alert('HSN Code Should be Given');
            window.document.getElementById('hsncode').focus();
            return;
        }
        value = formdata.hsncode;
        if ((value.length < 4 || value.length === 5 || value.length === 7) || parseInt(value) === 0) {
            alert('In-valid HSN Code');
            window.document.getElementById('hsncode').focus();
            window.document.getElementById('hsncode').select();
            return;
        }


        try {
            const inputdata = {
                commoditycode: curmode[1],
                commodityname: formdata.commodityname,
                hsncode: formdata.hsncode,
            }
            url = `${process.env.REACT_APP_RAINBOW_URL}/commodity_create`;
            if (curmode[0] === 'Edit') {
                url = `${process.env.REACT_APP_RAINBOW_URL}/commodity_update`;
            }
            response = await axios.post(url, inputdata)
            handleFetchAll();
            if (curmode[0]==="Edit") {
                return;
            }
        }
        catch (error) {
            console.error('Error submitting form:', error);
        }
        setcurmode(['New',0]);

        resetForm();
        commoditynameRef.current.focus();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission on Enter
        }
    };

    const defaultEnterRef = useRef('');
    const defaultEscapeRef = useRef('');

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Enter') {
                defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                defaultEscapeRef.current.click();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);


    const handleRowClick =(data)=> {
        setFormdata({
            commodityname: data.commodityname,
            hsncode: data.hsncode
        })
        setcurmode(['Edit',data.commoditycode]);
    }

    return (
        <div className='commodity-divmain'>
            <div className='commodity-body'>
                <div className="commodity-form-content">
                    <form className='commodity-forms' onSubmit={handleClick} onKeyDown={handleKeyDown} >
                        <div className='commodity-container'>
                            <div className='commodity-tablepanel'>
                                <div className='commodity-addnewdetailsgrp'>
                                    <h3>Commodity Details</h3>
                                    <button onClick={handleAddNew}>Add New</button>
                                </div>
                                <div className='commodity-table' >
                                    <table style={{margin:"2px"}}>
                                        <thead>
                                            <tr >
                                                <th style={{ width: '20%' }}>HSN/SAC</th>
                                                <th style={{ width: '60%' }}>Commodity Name</th>
                                                <th style={{ width: '20%' }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {commoditydata.map((data, index) => (
                                                <tr key={index}
                                                    onClick={() => handleRowClick(data)}
                                                    style={{
                                                        height: '15px',
                                                        backgroundColor: selectedRowIndex === index ? '#f7cac9 ' : '', // Change the color as needed
                                                    }}>
                                                    
                                                    <td className="table-cell" style={{ textAlign: "left" }}>{data.hsncode}</td>
                                                    <td className="table-cell" style={{ textAlign: "left" }}>{data.commodityname}</td>
                                                    <td className="table-cell">
                                                        <div className='edit-deletebtngroup'>
                                                            <button onClick={() => handleEdit(data, index)}>Edit</button>
                                                            <button onClick={() => handleDelete(data, index)}>Delete</button>
                                                        </div>
                                                    </td>

                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className='commodity-singlepanel'>
                                <div className='commodity-form'>
                                    <div className='commodity-form-group' style = {{height:"20%"}}>
                                        <label htmlFor="commodityname" > Commodity Name</label>
                                        <textarea id='commodityname' name='commodityname' ref={commoditynameRef} value={formdata.commodityname} onChange={handleFormChange} maxLength={100}></textarea>
                                    </div>
                                    <div className='commodity-form-group'>
                                        <label htmlFor="hsncode" >  HSN/SAC Code</label>
                                        <InputMask
                                            mask="99999999"
                                            maskChar={null}
                                            alwaysShowMask={false}
                                            id='hsncode' name='hsncode' ref={hsncodeRef} value={formdata.hsncode} onChange={handleFormChange}
                                        ></InputMask>
                                    </div>
                                </div>

                                <div className='commodity-save-cancelbtngrp' style={{marginBottom:"-5%"}}>
                                    <button ref={defaultEscapeRef} onClick={handleCancel}>Cancel</button>
                                    <button type='submit' ref={defaultEnterRef} onClick={handleSave}>Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Commodity;