import React, {useState} from 'react';
import Profile from './Profile';
import Bank from './Bank';
import Partners from './Partners'
import "./MainPage.css";

const MainPage = () => {
    const [activeTab, setActiveTab] = useState('profile');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'profile':
                return <Profile/>;
            case 'bank':
                return <Bank/>;
            case 'partners':
                return <Partners/>;
            default:
                return null;
        }
    };

    return (
        <div className='clientprofile-divmain'>
            <div className='clientprofile-body'>

                <div className="clientprofile-tab-buttons">
                    <button
                        className={activeTab === 'profile' ? 'active' : ''}
                        onClick={() => setActiveTab('profile')}
                    >
                        Profile
                    </button>
                    <button
                        className={activeTab === 'bank' ? 'active' : ''}
                        onClick={() => setActiveTab('bank')}
                    >
                        Bank
                    </button>
                    <button
                        className={activeTab === 'partners' ? 'active' : ''}
                        onClick={() => setActiveTab('partners')}
                    >
                        Partners / Managing Directors
                    </button>
                </div>
                <div className="clientprofile-form-content">
                    {renderTabContent()}
                </div>


                {/* <div className="clientprofile-tab-buttons">
                    <button className={activeTab === 'profile' ? 'active' : ''} onClick={() => setActiveTab('profile')}>Profile</button>
                    <button className={activeTab === 'bank' ? 'active' : ''} onClick={() => setActiveTab('bank')}>Bank</button>
                    <button className={activeTab === 'partners' ? 'active' : ''} onClick={() => setActiveTab('partners')}>Partners</button>
                </div> */}
            </div>
        </div >
    );
};

export default MainPage;