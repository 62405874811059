import React from "react";
import "./Navbar.css";
import { FaHome, FaSearch, FaDownload, FaUpload, FaToolbox, FaNewspaper } from "react-icons/fa";
import { Link, } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { useState } from "react";
import { myaction } from "../../redux/Mystore";

const Navbar = () => {
  const [install, setinstall] = useState(false)
  const [entry, setentry] = useState(false)
  const [emport, setemport] = useState(false)
  const [gsttab, setgsttab] = useState(false)

  let hideMenu = useSelector((xdata) => {
    return xdata.hidemenu;
  })

  const isadmin = useSelector((xdata) => {
    return xdata.isAdmin;
  })

  function openmodel(params) {
    setinstall(!install)
  }
  function openmodel2(params) {
    setentry(!entry)
  }
  function openmodel3(params) {
    setemport(!emport)
  }

  function opengsttab(params) {
    setgsttab(!gsttab)
  }


  function hidedata(params) {
    setinstall(false)
    setentry(false)
    setemport(false)
    setgsttab(false)
  }
  const mydispatch = useDispatch()

  function sethidemenu(params) {
    mydispatch(myaction.hideMenu(true))
  }

  return (
    <>
      <div class={hideMenu ? "navbar-hide" : "navbar"}>
        <ul class="navbar-item hide">

          <li class="active"><FaHome />&nbsp; Home</li>

          <Link to="/searchtaxpayer" className="linkcom">
            <li><FaSearch />&nbsp; Search Tax Payer</li>
          </Link>

          <li className="dropdown" onClick={openmodel2} onMouseLeave={hidedata}><FaToolbox />&nbsp; Entry
            {entry && (<ul className="droplist1">
              <Link to="/stockentry-production" className="linkcom1" onClick={sethidemenu}><li>Production</li></Link>
              <Link to="/stockentry-transfer" className="linkcom1" onClick={sethidemenu}><li>Transfer</li></Link>
              <Link to="/listsaleinvoice" className="linkcom1" onClick={sethidemenu}><li>Sale Invoice</li></Link>
              <Link to="/annualreturn" className="linkcom1" onClick={sethidemenu}><li>Annual Return</li></Link>
            </ul>)}
          </li>


          <li className="dropdown" onClick={openmodel} onMouseLeave={hidedata}><FaToolbox />&nbsp; Install
            {install && (<ul className="droplist1">
              <Link to="/listaccledger_agent" className="linkcom1" onClick={sethidemenu}><li>Area Ledger Name</li></Link>
              <Link to="/listaccledger_customer" className="linkcom1" onClick={sethidemenu}><li>Customer Ledger Name</li></Link>
              <Link to="/listproductmaster" className="linkcom1" onClick={sethidemenu}><li>Product Master</li></Link>
              <Link to="/listproductgroup" className="linkcom1" onClick={sethidemenu}><li>Product Group</li></Link>
              <Link to="/listtransport" className="linkcom1" onClick={sethidemenu}><li>Transport</li></Link>
              <Link to="/liststockplace" className="linkcom1" onClick={sethidemenu}><li>Stock Place</li></Link>
              <Link to="/listcompany" className="linkcom1" onClick={sethidemenu}><li>Company</li></Link>
              <Link to="/listpricelist" className="linkcom1" onClick={sethidemenu}><li>Price List</li></Link>
              <Link to="/ratelist" className="linkcom1" onClick={sethidemenu}><li>Rate List</li></Link>
              <Link to="/stockentry" className="linkcom1" onClick={sethidemenu}><li>Opening Stock</li></Link>
            </ul>)}
          </li>
          {isadmin &&
            <Link to="/flashnews" className="linkcom">
              <li><FaNewspaper />&nbsp; Flash News</li>
            </Link>
          }
          <Link to="/chequeprinting" className="linkcom" onClick={sethidemenu}>
            <li>Cheque Printing</li>
          </Link>

          {isadmin &&
            <Link to="/license" className="linkcom" onClick={sethidemenu}>
              <li> License</li>
            </Link>
          }

          <li className="dropdown" onClick={openmodel3} onMouseLeave={hidedata}><FaDownload />&nbsp; Import
            {isadmin && emport && (<ul className="droplist1">
              <Link to="/importproductgroup" className="linkcom1" onClick={sethidemenu}><li>Product Group</li></Link>
            </ul>)}
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
