import { Routes,Route } from "react-router-dom"

import React from "react";
import MyLoginPage from "../pages/MyLoginPage";
import Signin from "../components/Users/Signin";
import Login from "../components/Users/Login";
import Emptypage from "../pages/Emptypage";
import Uploadpdf from "../components/uploadPDF/Uploadpdf";
import Flashnews from "../components/flashNews/Flashnews";

import ProductMaster from "../components/productmaster/ProductMaster";
import ProductGroup from "../components/productgroup/ProductGroup";
import Transport from "../components/transport/Transport";
import Stockplace from "../components/stockplace/Stockplace";
import PriceList from "../components/pricelist/PriceList";
// Code Checks
import PdtGroupLookup from "../components/productgroup/PdtGroupLookup";
import PdtMasterLookup from "../components/productmaster/PdtMasterLookup";
import TransportLookup from "../components/transport/TransportLookup";
import Stockplacelookup from "../components/stockplace/Stockplacelookup";
import PriceListLookup from "../components/pricelist/PriceListLookup";
import Commodity from "../components/GST/Commodity";
import SalesTaxParticularsLookup from "../components/GST/SalesTaxParticularsLookup";
import SalesTaxParticulars from "../components/GST/SalesTaxParticulars";
import MainPage from "../components/MainPage/MainPage";

import SearchTaxPayer from "../components/GST/SearchTaxPayer";
import ReCaptcha from "../components/reCaptcha/ReCaptcha";
import AccLedger from "../components/accledger/AccLedger";
import AccHead from "../components/accledger/AccHead";
import ChequePrinting from "../components/ChequePrinting/ChequePrinting";
import AccLedgerLookup from "../components/accledger/AccLedgerLookup";

import Company from "../components/company/Company";
import CompanyLookup from "../components/company/CompanyLookup";
import StockEntry from "../components/StockEntry/StockEntry";
import SaleInvoice from "../components/SaleInvoice/SaleInvoice";
import SaleInvoiceLookup from "../components/SaleInvoice/SaleInvoiceLookup";
import AnnualReturn from "../components/AnnualReturn/AnnualReturn";
import ImportProductGroup from "../components/import/ImportProductGroup";
import RateList from "../components/RateList/RateList";
import License from "../components/License/License";
import Contactus from "../components/ContactUs/ContactUs";
import ChangePassword from "../components/Users/ChangePassword";

const Routers = () => {
  return (
    <>
    
    <Routes>
        <Route path="/" element={<MyLoginPage/>}/>
        <Route path="/login" element={ <Login/>}/>
        <Route path="/signin" element={ <Signin/>}/>
        <Route path="/forgotpassword" element={ <Signin formmode={'FP'}/>}/>
        <Route path="/changepassword" element={ <ChangePassword/>}/>

        <Route path="/emptypage" element={ <Emptypage/>}/>
        <Route path="/uploadpdf" element={  <Uploadpdf/>}/>
        <Route path="/flashnews" element={  <Flashnews/>}/>
        <Route path="/installproductmaster" element={ <ProductMaster/>}/>
        <Route path="/listproductmaster" element={ <PdtMasterLookup/>}/>
        <Route path="/installproductgroup" element={ <ProductGroup/>}/>
        <Route path="/listproductgroup" element={ <PdtGroupLookup/>}/>    
        <Route path="/searchtaxpayer" element={ <SearchTaxPayer/>}/>            

        <Route path="/recaptcha" element={ <ReCaptcha/>}/>                    
        <Route path="/listaccledger_agent" element={ <AccLedgerLookup accgroupid={5} />}/>
        <Route path="/listaccledger_customer" element={ <AccLedgerLookup accgroupid={3} />}/>
        <Route path="/listaccledger_sales" element={ <AccLedgerLookup accgroupid={8} />}/>
        <Route path="/listaccledger_tax" element={ <AccLedgerLookup accgroupid={16} />}/>
        <Route path="/accledger_agent" element={ <AccLedger accgroupid={5} />}/>
        <Route path="/accledger_customer" element={ <AccLedger accgroupid={3} />}/>
        <Route path="/accledger_sales" element={ <AccHead accgroupid={8} />}/>        
        <Route path="/accledger_tax" element={ <AccHead accgroupid={16} />}/>        
        <Route path="/chequeprinting" element={ <ChequePrinting/>}/>   
        <Route path="/installtransport" element={ <Transport/>}/>
        <Route path="/listtransport" element={ <TransportLookup/>}/>
        <Route path="/installstockplace" element={ <Stockplace/>}/>
        <Route path="/liststockplace" element={ <Stockplacelookup/>}/>
        <Route path="/installcompany" element={ <Company/>}/>
        <Route path="/listcompany" element={ <CompanyLookup/>}/>
        <Route path="/listpricelist" element={ <PriceListLookup/>}/>
        <Route path="/installpricelist" element={ <PriceList/>}/>
        <Route path="/installcommodity" element={ <Commodity/>}/>
        <Route path="/installsalestaxparticulars" element={ <SalesTaxParticulars/>}/>
        <Route path="/listsalestaxparticularsLookup" element={ <SalesTaxParticularsLookup/>}/>
        <Route path="/profile" element={ <MainPage/>}/>

        <Route path="/stockentry" element={ <StockEntry/> }/>
        <Route path="/stockentry-production" element={ <StockEntry TrnType={2}/> }/>
        <Route path="/stockentry-transfer" element={ <StockEntry TrnType={8}/> }/>        
        {/* <Route path="/packingslip" element={ <Packingslip/> }/>    */}
        <Route path="/installsaleinvoice" element={ <SaleInvoice/>}/>
        <Route path="/listsaleinvoice" element={ <SaleInvoiceLookup/>}/> 
        <Route path="/annualreturn" element={ <AnnualReturn/>}/>    
        <Route path="/importproductgroup" element={ <ImportProductGroup/>}/>  
        <Route path="/ratelist" element={ <RateList/> }/>   
        <Route path="/license" element={ <License/>}/>
        <Route path="/contactus" element={ <Contactus/>}/>     
    </Routes>
        
    </>
  );
};

export default Routers;
