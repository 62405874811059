// CheckboxCircle.js

import React from 'react';
import './CheckboxCircle.css';

const CheckboxCircle = ({ checked, onChange, tabindex }) => {
  return (
    <label className="checkbox-circle">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        tabIndex={tabindex}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default CheckboxCircle;
