import "./accledger.css"
import React, { useState, useEffect, useRef } from 'react';
import { FaWhatsapp } from "react-icons/fa";
import axios from 'axios';
import CheckboxCircle from "../CheckboxCircle/CheckboxCircle";
import blinking_gif from "../../assets/blinking_new.gif";
import * as u from "../utils/Common";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { FaSearch } from "react-icons/fa";
import InputMask from 'react-input-mask';

const AccLedger = ({ accgroupid = 3 }) => {
    let groupid = accgroupid;

    const agentid = 5;
    const custid = 3;
    const salesid = 8;

    let accgroupname = '';

    let agentYN = false;
    let transportYN = false;
    let salestypeYN = false;

    let url = '';

    const curmode = useSelector(function (data) {
        return data.accledgermode;
    })

    groupid = curmode[2];

    if (groupid === custid) {
        accgroupname = 'Customer';
    }

    if (groupid === agentid) {
        accgroupname = 'Area';
    }


    const navigate = useNavigate();

    if (groupid === custid) {
        agentYN = true;
        transportYN = true;
        salestypeYN = false;
    }

    const [mobNumbers, setMobNumbers] = useState([{ mobNo: '', whatsapp: false }, { mobNo: '', whatsapp: false }, { mobNo: '', whatsapp: false }, { mobNo: '', whatsapp: false }, { mobNo: '', whatsapp: false }, { mobNo: '', whatsapp: false }])

    const actnameRef = useRef('');
    const address1Ref = useRef('');
    const placeRef = useRef('');
    const pincodeRef = useRef('');


    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const [isChecked4, setIsChecked4] = useState(false);
    const [isChecked5, setIsChecked5] = useState(false);
    const [isChecked6, setIsChecked6] = useState(false);
    const handleCheckboxChange1 = () => {
        setIsChecked1(!isChecked1);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[0].whatsapp = !isChecked1;
        setMobNumbers(newMobileNumbers);
    };
    const handleCheckboxChange2 = () => {
        setIsChecked2(!isChecked2);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[1].whatsapp = !isChecked2;
        setMobNumbers(newMobileNumbers);
    };
    const handleCheckboxChange3 = () => {
        setIsChecked3(!isChecked3);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[2].whatsapp = !isChecked3;
        setMobNumbers(newMobileNumbers);
    };
    const handleCheckboxChange4 = () => {
        setIsChecked4(!isChecked4);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[3].whatsapp = !isChecked4;
        setMobNumbers(newMobileNumbers);
    };
    const handleCheckboxChange5 = () => {
        setIsChecked5(!isChecked5);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[4].whatsapp = !isChecked5;
        setMobNumbers(newMobileNumbers);
    };
    const handleCheckboxChange6 = () => {
        setIsChecked6(!isChecked6);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[5].whatsapp = !isChecked6;
        setMobNumbers(newMobileNumbers);
    };

    const stateRef = useRef('')
    const [statedata, setstatedata] = useState([]);
    const [selectedState, setselectedState] = useState(0);

    const transportRef = useRef('')
    const [transportdata, settransportdata] = useState([]);
    const [selectedtransport, setselectedtransport] = useState(0);

    const salesRef = useRef('')
    const [salesdata, setsalesdata] = useState([]);
    const [selectedsales, setselectedsales] = useState(0);

    const agentRef = useRef('')
    const [agentdata, setagentdata] = useState([]);
    const [selectedagent, setselectedagent] = useState(0);


    useEffect(() => {
        actnameRef.current.focus();
    }, []);


    useEffect(() => {
        const fetchdata = async (e) => {
            if (curmode[0] === "Edit") {
                const data = { actcode: curmode[1] };
                const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/acchead_readbyactcode`, data)
                if (response.data.length > 0) {
                    formdata.actname = response.data[0].actname;
                    formdata.address1 = response.data[0].add1
                    formdata.address2 = response.data[0].add2;
                    formdata.address3 = response.data[0].add3;
                    formdata.address4 = response.data[0].add4;
                    formdata.place = response.data[0].place;
                    formdata.pincode = response.data[0].pincode;
                    formdata.adhaar = response.data[0].adhaar;
                    formdata.pan = response.data[0].pan;
                    formdata.gstin = response.data[0].gstin;
                    formdata.gstincanceldate = response.data[0].gstincanceldate;
                    formdata.statename = response.data[0].statename;
                    formdata.district = response.data[0].district;
                    setselectedState(response.data[0].statecode);
                    setselectedagent(response.data[0].agentcode);
                    setselectedtransport(response.data[0].transportcode);
                    formdata.email = response.data[0].email;
                    formdata.website = response.data[0].website;
                    formdata.iecode = response.data[0].iecode;
                    formdata.explic1 = response.data[0].explic1;
                    formdata.explic2 = response.data[0].explic2;
                    formdata.contactperson = response.data[0].contactperson;
                    setformdata(formdata);
                    const newMobileNumbers = [...mobNumbers];
                    newMobileNumbers[0].whatsapp = response.data[0].mobile1whatsapp === 1 ? true : false;
                    newMobileNumbers[1].whatsapp = response.data[0].mobile2whatsapp === 1 ? true : false;
                    newMobileNumbers[2].whatsapp = response.data[0].mobile3whatsapp === 1 ? true : false;
                    newMobileNumbers[0].mobNo = response.data[0].mobile1;
                    newMobileNumbers[1].mobNo = response.data[0].mobile2;
                    newMobileNumbers[2].mobNo = response.data[0].mobile3;
                    newMobileNumbers[3].whatsapp = response.data[0].cpmobile1whatsapp === 1 ? true : false;
                    newMobileNumbers[4].whatsapp = response.data[0].cpmobile2whatsapp === 1 ? true : false;
                    newMobileNumbers[5].whatsapp = response.data[0].copmobile3whatsapp === 1 ? true : false;
                    newMobileNumbers[3].mobNo = response.data[0].cpmobile1;
                    newMobileNumbers[4].mobNo = response.data[0].cpmobile2;
                    newMobileNumbers[5].mobNo = response.data[0].cpmobile3;
                    setIsChecked1(response.data[0].mobile1whatsapp);
                    setIsChecked2(response.data[0].mobile2whatsapp);
                    setIsChecked3(response.data[0].mobile3whatsapp);
                    setIsChecked4(response.data[0].cpmobile1whatsapp);
                    setIsChecked5(response.data[0].cpmobile2whatsapp);
                    setIsChecked6(response.data[0].cpmobile3whatsapp);
                    setMobNumbers(newMobileNumbers);
                    formdata.usergroup = response.data[0].usergroup;
                }
            }
        };

        const fetchstatedata = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/readstatemaster`)
                setstatedata(response.data);
            } catch (error) {
                console.error("Error:", error);
            }
        };

        const fetchtransportdata = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/transport_readall`)
                console.log(response.data)
                settransportdata(response.data);
            } catch (error) {
                console.error("Error:", error);
            }
        };

        if (agentYN) {
            const fetchagentdata = async () => {
                try {
                    const data = { accgroupcode: agentid };
                    const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/acchead_readgroupall`, data)
                    setagentdata(response.data);
                } catch (error) {
                    console.error("Error:", error);
                }
            };
            fetchagentdata()
        }

        if (salestypeYN) {
            const fetchsalesdata = async () => {
                try {
                    const data = { accgroupcode: salesid };
                    const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/acchead_readgroupall`, data)
                    setsalesdata(response.data);
                } catch (error) {
                    console.error("Error:", error);
                }
            };
            fetchsalesdata()
        }


        fetchstatedata()
        fetchtransportdata()
        fetchdata()
    }, []);

    const handleMobChange = (index, event) => {
        const { value } = event.target;
        if (!(/^\d*$/.test(value) && value.length <= 10)) {
            return;
        }
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[index].mobNo = event.target.value;
        setMobNumbers(newMobileNumbers);
    };

    const handleStateChange = (event) => {
        const { value } = event.target;
        setselectedState(u.myInt(value));
    }

    const handeleTransportChanged = (event) => {
        const { value } = event.target;
        setselectedtransport(u.myInt(value));

    }

    const handeleSalesChanged = (event) => {
        const { value } = event.target;
        setselectedsales(u.myInt(value));
    }

    const handeleagentChanged = (event) => {
        const { value } = event.target;
        setselectedagent(u.myInt(value));
    }

    const handleSave = async (e) => {
        let newValue = u.myTrim(formdata.actname);
        if (newValue === "") {
            alert("Name Should be Given");
            actnameRef.current.focus()
            return;
        }

        if (groupid === custid) {
            newValue = u.myTrim(formdata.address1);
            if (newValue === "") {
                alert("Address Should be Given");
                address1Ref.current.focus()
                return;
            }
        }
        newValue = u.myTrim(formdata.place);
        if (newValue === "") {
            alert("Place Should be Given");
            placeRef.current.focus()
            return;
        }
        try {
            const data = {
                actname: u.myTrim(formdata.actname),
                place: u.myTrim(formdata.place),

                actcode: curmode[0] === "New" ? 0 : curmode[1],
            };
            console.log(data)
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/acchead_checkactnameplaceexist`, data);
            console.log(response.data)
            if (response.data.length > 0) {
                alert('Name and Place already Given ')
                return;
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error (show error message, etc.)
        }


        if (groupid === custid) {
            newValue = u.myTrim(formdata.pincode);
            if (newValue === "") {
                alert("Pincode should be Given");
                pincodeRef.current.focus()
                return;
            }

            if (!u.validatePinCode(newValue)) {
                alert("Invalid Pincode..");
                pincodeRef.current.focus()
                return;
            }
        }
        if (groupid !== agentid) {
            newValue = u.myInt(selectedState);
            if (newValue === 0) {
                alert("State Should be Given");
                stateRef.current.focus()
                return;
            }
        }
        if (groupid !== agentid) {
            newValue = u.myInt(selectedagent);
            if (newValue === 0) {
                alert("Agent Should be Given");
                agentRef.current.focus()
                return;
            }
        }

        let agentcode = 0;
        if (agentYN) {
            agentcode = selectedagent.agentCode;
            if (agentcode === 0) {
                alert("Agent Name Should be Given");
                agentRef.current.focus()
                return;
            }
        }
        if (formdata.gstin !== "") {
            try {
                const data = {
                    gstin: formdata.gstin,

                    actcode: curmode[0] === "New" ? 0 : curmode[1],
                };
                console.log(data)
                const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/acchead_checkgstinexist`, data);
                console.log(response.data)
                if (response.data.length > 0) {
                    alert('GSTIN already Given ')
                    return;
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                // Handle error (show error message, etc.)
            }

        }
        let value = formdata.gstincanceldate;
        if(!u.validateDate(value) && value.length > 0){
            alert("Invalid Date");
            window.document.getElementById("gstincanceldate").focus();
            return;
        }
        try {
            const data = {
                actcode: (curmode[0] === "New" ? 0 : curmode[1]),
                actname: u.myTrim(formdata.actname),
                add1: u.myTrim(formdata.address1),
                add2: u.myTrim(formdata.address2),
                add3: u.myTrim(formdata.address3),
                add4: u.myTrim(formdata.address4),
                place: u.myTrim(formdata.place),
                pincode: u.myTrim(formdata.pincode),
                gstin: u.myTrim(formdata.gstin),
                gstincanceldate: u.myTrim(formdata.gstincanceldate),
                statecode: selectedState,
                district: u.myTrim(formdata.district),
                adhaar: u.myTrim(formdata.adhaar),
                pan: u.myTrim(formdata.pan),
                mobile1: u.myTrim(mobNumbers[0].mobNo),
                mobile2: u.myTrim(mobNumbers[1].mobNo),
                mobile3: u.myTrim(mobNumbers[2].mobNo),
                mobile1whatsapp: mobNumbers[0].whatsapp ? 1 : 0,
                mobile2whatsapp: mobNumbers[1].whatsapp ? 1 : 0,
                mobile3whatsapp: mobNumbers[2].whatsapp ? 1 : 0,
                agentcode: u.myInt(selectedagent),
                transportcode: u.myInt(selectedtransport),
                salecode: u.myInt(selectedsales),
                iecode: u.myTrim(formdata.iecode),
                explic1: u.myTrim(formdata.explic1),
                explic2: u.myTrim(formdata.explic2),
                email: u.myTrim(formdata.email),
                website: u.myTrim(formdata.website),
                contactperson: u.myTrim(formdata.contactperson),
                cpmobile1: u.myTrim(mobNumbers[3].mobNo),
                cpmobile2: u.myTrim(mobNumbers[4].mobNo),
                cpmobile3: u.myTrim(mobNumbers[5].mobNo),
                cpmobile1whatsapp: mobNumbers[3].whatsapp ? 1 : 0,
                cpmobile2whatsapp: mobNumbers[4].whatsapp ? 1 : 0,
                cpmobile3whatsapp: mobNumbers[5].whatsapp ? 1 : 0,
                usergroup: u.myTrim(formdata.usergroup),
                accgroupcode: groupid
            };
            console.log(data);
            url = `${process.env.REACT_APP_RAINBOW_URL}/acchead_create`
            if (curmode[0] === "Edit") {
                url = `${process.env.REACT_APP_RAINBOW_URL}/acchead_update`
            }
            await axios.post(url, data);

        } catch (error) {
            console.error('Error submitting form:', error);
        }
        if (curmode[0] === "Edit") {
            navigate(-1)
            return;
        }
        resetform();
        actnameRef.current.focus();
    }

    function resetform() {
        formdata.actname = '';
        formdata.address1 = '';
        formdata.address2 = '';
        formdata.address3 = '';
        formdata.address4 = '';
        formdata.place = '';
        formdata.pincode = '';
        formdata.district = '';
        formdata.adhaar = '';
        formdata.gstin = '';
        formdata.gstincanceldate = '';
        formdata.pan = '';
        formdata.email = '';
        formdata.website = '';
        formdata.iecode = '';
        formdata.explic1 = '';
        formdata.explic2 = '';
        formdata.contactperson = '';
        formdata.usergroup = '';
        setformdata(formdata);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[0].whatsapp = false;
        newMobileNumbers[1].whatsapp = false;
        newMobileNumbers[2].whatsapp = false;
        newMobileNumbers[0].mobNo = '';
        newMobileNumbers[1].mobNo = '';
        newMobileNumbers[2].mobNo = '';
        newMobileNumbers[3].whatsapp = false;
        newMobileNumbers[4].whatsapp = false;
        newMobileNumbers[5].whatsapp = false;
        newMobileNumbers[3].mobNo = '';
        newMobileNumbers[4].mobNo = '';
        newMobileNumbers[5].mobNo = '';
        setMobNumbers(newMobileNumbers);
    }

    const [formdata, setformdata] = useState({
        actname: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        place: '',
        pincode: '',
        adhaar: '',
        pan: '',
        gstin: '',
        statecode: 0,
        statename: '',
        district: '',
        gstincanceldate: '',
        email: '',
        website: '',
        iecode: '',
        explic1: '',
        explic2: '',
        contactperson: '',
        usergroup: ''
    });



    const handleformchange = (event) => {
        let { name, value } = event.target;
        if (name === "pincode") {
            if (!(/^\d*$/.test(value) && value.length <= 6)) {
                return;
            }
        } else if (name === "gstin") {
            if (!(/^[A-Z0-9a-z]*$/.test(value) && value.length <= 15)) {
                return;
            }
            value = value.toUpperCase();
        }else if (name === "pan") {
            if (!(/^[A-Z0-9a-z]*$/.test(value) && value.length <= 10)) {
                return;
            }
            value = value.toUpperCase();
        } else if (name === "iecode") {
            if (!(/^[A-Z0-9a-z]*$/.test(value) && value.length <= 10)) {
                return;
            }
            value = value.toUpperCase();
        } else if (name === "explic1" || name === "explic2") {
            if (!(value.length <= 25)) {
                return;
            }
            value = value.toUpperCase();
        } else if (name === "email" || name === "website") {
            value = value.toLowerCase();
        } else {
            value = u.toProperCase(value);
        }
        setformdata({ ...formdata, [name]: value, })
        if (name === "gstin") {
            const xdata = u.myTrim(value).substring(0, 2);
            const xdata2 = statedata.filter((state) => state.gststatecode === xdata)
            if (xdata2.length > 0) {
                setselectedState(xdata2[0].statecode)
            } else {
                setselectedState(0)
            }
        }
    };





    const defaultEnterRef = useRef(null)
    const defaultEscapeRef = useRef(null)

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if ((event.key === 'Enter') || (event.ctrlKey && event.key === 'a')) {
                defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                defaultEscapeRef.current.click();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    function frmClose() {
        navigate(-1)
    }

    const [GSTINError, setGSTINError] = useState('');
    const [details, setDetails] = useState(null);
    const handleSearchTaxPayer = () => {
        if (!u.validateGSTIN(formdata.gstin)) {
            alert("Not a Valid GSTIN");
            return;
        }
        fetchGstinDetails();
    }
    const fetchGstinDetails = async () => {
        const data = { gstin: formdata.gstin }
        console.log(data);
        const url = `${process.env.REACT_APP_RAINBOW_URL}/searchtaxpayer`
        try {
            const response = await axios.post(url, data);
            console.log(response.data.result);
            if (response.data.success === false) {
                setGSTINError(response.data.message);
                alert(response.data.message);
                return;
            }
            const jsondata = JSON.parse(response.data.result).data;
            console.log(jsondata.tradeNam); // Don't Remove Just For Rendering
            setDetails(jsondata);
            formdata.actname = jsondata.tradeNam;
            //   console.log(jsondata.pradr);
            let address = "";
            let curvalue = u.myTrim(jsondata.pradr.addr.bnm);
            if (curvalue !== "") {
                address += (address === "" ? "" : ", ") + curvalue;
            }
            curvalue = u.myTrim(jsondata.pradr.addr.bno);
            if (curvalue !== "") {
                address += (address === "" ? "" : ", ") + curvalue;
            }
            curvalue = u.myTrim(jsondata.pradr.addr.flno);
            if (curvalue !== "") {
                address += (address === "" ? "" : ", ") + curvalue;
            }
            curvalue = u.myTrim(jsondata.pradr.addr.st);
            if (curvalue !== "") {
                address += (address === "" ? "" : ", ") + curvalue;
            }
            formdata.address1 = address;
            formdata.place = jsondata.pradr.addr.loc;
            formdata.pincode = jsondata.pradr.addr.pncd;
            formdata.district = jsondata.pradr.addr.dst;
            setformdata(formdata);
            console.log(formdata);
            setGSTINError(null);
        } catch (error) {
            console.log(error.message)
            setGSTINError('Failed to fetch GSTIN details');

        }
    }

    return (
        <>
            <div className="divmain" >
                <div className="frmaccledger-main">
                    <div className="frmaccledger-container-page">
                        <div className="frmaccledger-header">
                            <h2>{accgroupname}</h2>
                            <h2>Account Ledger Information</h2>
                        </div>
                        <div className="frmaccledger-container">
                            <div className="frmaccledger-leftpanel">
                                <div className="frmaccledger-group">
                                    <label htmlFor="actname" className="frmaccledger-label required">Account Name</label>
                                    <input type="text" id="actname" name="actname" value={formdata.actname} onChange={handleformchange} className="frmaccledger-input" required autofocus maxlength="75" ref={actnameRef} />
                                </div>
                                <div className="frmaccledger-group">
                                    <label for="address1" className="frmaccledger-label required">Address</label>
                                    <input type="text" id="address1" name="address1" value={formdata.address1} onChange={handleformchange} className="frmaccledger-input" required ref={address1Ref} />
                                </div>
                                <div className="frmaccledger-group">
                                    <label for="address2" className="frmaccledger-label "></label>
                                    <input type="text" id="address2" name="address2" value={formdata.address2} onChange={handleformchange} className="frmaccledger-input" />
                                </div>
                                <div className="frmaccledger-group">
                                    <label for="address3" className="frmaccledger-label "></label>
                                    <input type="text" id="address3" name="address3" value={formdata.address3} onChange={handleformchange} className="frmaccledger-input" />
                                </div>
                                <div className="frmaccledger-group">
                                    <label for="address4" className="frmaccledger-label "></label>
                                    <input
                                        type="text"
                                        id="address4"
                                        name="address4"
                                        value={formdata.address4}
                                        onChange={handleformchange}
                                        className="frmaccledger-input"
                                        tabIndex={-1}
                                    />
                                </div>

                                <div className="frmaccledger-group">
                                    <label for="place" class="frmaccledger-label required">Place</label>
                                    <div className="frmaccledger-group-sub">
                                        <input type="text" id="frmaccledger-place" name="place" value={formdata.place} onChange={handleformchange} ref={placeRef} />
                                        <label for="pincode" id="frmaccledger-label-pincode" className="required">Pin Code</label>
                                        <input type="text" id="frmaccledger-pincode" name="pincode" ref={pincodeRef} onChange={handleformchange} maxLength="6" value={formdata.pincode} />
                                    </div>
                                </div>

                                {(groupid !== agentid) &&
                                    <div className="frmaccledger-group">

                                        <label for="gstin" class="frmaccledger-label">GSTIN </label>


                                        <div className="frmaccledger-group-sub">
                                            <input type="text" id="gstin" name="gstin" onChange={handleformchange} value={formdata.gstin} style={{ width: '35%' }} />
                                            <FaSearch onClick={handleSearchTaxPayer} style={{ width: "5%", marginTop: "-2px", marginLeft: "4px", fontSize: "30px" }} />
                                            <label for="gstincanceldate" style={{ textAlign: "right", width: "30%" }}>GSTIN Cancel dt.</label>
                                            <InputMask
                                                mask="99/99/99"
                                                id="gstincanceldate" name="gstincanceldate" onChange={handleformchange} style={{ width: '30%' }} maxLength="10" value={formdata.gstincanceldate}
                                                placeholder="DD/MM/YY"
                                                maskChar=""
                                            />
                                        </div>
                                    </div>
                                }
                                {(groupid !== agentid) && details && (formdata.gstin !== '') &&
                                    <div className={details.sts === "Active" ? 'gststatus' : "gststatus gststatus-alert"}>
                                        <light>Status :&nbsp;&nbsp;&nbsp;</light>
                                        <p className='status'>{details.sts}</p>
                                        <img src={blinking_gif} alt="" />
                                        <light>&nbsp;&nbsp;&nbsp;  Regn.Type :&nbsp;&nbsp;</light><p className='status'>{details.dty}</p>
                                        <light>&nbsp;&nbsp;&nbsp;  eInvoice :&nbsp;&nbsp;</light><p className='status'>{details.einvoiceStatus}</p>
                                    </div>
                                }

                                {(groupid !== agentid) &&
                                    <div className="frmaccledger-group">
                                        <label for="state" className="frmaccledger-label">State</label>
                                        <div className="frmaccledger-group-sub">
                                            <select id="state-select" name="state-select" required onChange={handleStateChange} value={selectedState} ref={stateRef}>
                                                <option value={0} disabled selected>Select State...</option>
                                                {statedata.map(data => (
                                                    <option key={data.statecode} value={data.statecode}>{data.statename}</option>
                                                ))}
                                            </select>

                                            < label for="district" style={{ textAlign: "right" }}>District</label>
                                            <input type="text" style={{ width: "100px" }} value={formdata.district} readOnly tabIndex={-1}></input>
                                        </div>
                                    </div>
                                }
                                <div className="frmaccledger-group">
                                    <label for="adhaar" className="frmaccledger-label">Adhaar</label>
                                    <div className="frmaccledger-group-sub">
                                        <input type="text" id="frmaccledger-place" name="adhaar" maxLength="12" onChange={handleformchange} value={formdata.adhaar} />
                                        <label for="pan" id="frmaccledger-label-pan">PAN</label>
                                        <input type="text" name="pan" id="frmaccledger-pan" maxLength="12" onChange={handleformchange} value={formdata.pan} />
                                    </div>
                                </div>

                                <div className="frmaccledger-group">
                                    <label for="mobile number-1" className="frmaccledger-label">Mobile</label>
                                    <div className="frmaccledger-group-sub">
                                        <div className="frmaccledger-group-sub2">
                                            <input type="text" id="frmaccledger-mobile" name="mobile number-1"
                                                value={mobNumbers[0].mobNo}
                                                maxLength="10"
                                                onChange={(event) => handleMobChange(0, event)}
                                            />
                                            <FaWhatsapp className="fa-whatsapp-icon" />
                                            <CheckboxCircle
                                                checked={isChecked1}
                                                onChange={handleCheckboxChange1}
                                                tabindex={-1}
                                                className="cricleicon"
                                            />
                                        </div>

                                        <div className="frmaccledger-group-sub2">
                                            <input type="text" id="frmaccledger-mobile" name="mobile number-1"
                                                value={mobNumbers[1].mobNo}
                                                maxLength="10"
                                                onChange={(event) => handleMobChange(1, event)}
                                            />
                                            <FaWhatsapp className="fa-whatsapp-icon" />
                                            <CheckboxCircle
                                                checked={isChecked2}
                                                onChange={handleCheckboxChange2}
                                                tabindex={-1}
                                                className="cricleicon"
                                            />
                                        </div>

                                        <div className="frmaccledger-group-sub2">
                                            <input type="text" id="frmaccledger-mobile" name="mobile number-1"
                                                tabIndex={-1}
                                                value={mobNumbers[2].mobNo}
                                                maxLength="10"
                                                onChange={(event) => handleMobChange(2, event)}
                                            />
                                            <FaWhatsapp className="fa-whatsapp-icon" />
                                            <CheckboxCircle
                                                checked={isChecked3}
                                                onChange={handleCheckboxChange3}
                                                tabindex={-1}
                                                className="cricleicon"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {agentYN &&
                                    <div className="frmaccledger-group">
                                        <label for="agent" className="frmaccledger-label">Area</label>
                                        <div className="frmaccledger-group-sub">
                                            <select id="agent-select" name="agent-select" required onChange={handeleagentChanged} value={selectedagent} ref={agentRef}>
                                                <option value={0} disabled selected>Select Agent...</option>
                                                {agentdata.map(data => (
                                                    <option key={data.actcode} value={data.actcode}>{data.actname}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                }

                                {transportYN &&
                                    <div className="frmaccledger-group">
                                        <label for="transport" className="frmaccledger-label">Transport</label>
                                        <div className="frmaccledger-group-sub" ref={transportRef}>
                                            <select id="transport-select" name="transport" onChange={handeleTransportChanged} value={selectedtransport} ref={transportRef}>
                                                <option value={0} disabled selected>Select Transport...</option>
                                                {transportdata.map(data => (
                                                    <option key={data.transportcode} value={data.transportcode}>{data.transportname}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                }
                                {salestypeYN &&
                                    <div className="frmaccledger-group">
                                        <label for="salestype" className="frmaccledger-label">Sales Type</label>
                                        <div className="frmaccledger-group-sub">
                                            <select name="salestype" ref={salesRef} onChange={handeleSalesChanged} value={selectedsales}>
                                                <option value="" disabled selected>Select Sales Type...</option>
                                                {salesdata.map(data => (
                                                    <option key={data.actcode} value={data.actcode}>{data.actname}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="frmacledger-rightpanel">
                                {(groupid !== agentid) &&
                                    <div className="frmaccledger-group">
                                        <label for="iecode" className="frmaccledger-label">IE Code</label>
                                        <div className="frmaccledger-group-sub">
                                            <input type="text" id="iecode" name="iecode" className="frmaccledger-input" onChange={handleformchange} value={formdata.iecode} />
                                        </div>
                                    </div>
                                }
                                {(groupid !== agentid) &&
                                    <div className="frmaccledger-group">
                                        <label for="explicence" className="frmaccledger-label">Exp.Licence</label>
                                        <div className="frmaccledger-group-sub">
                                            <div className="accledger-group-grid">
                                                <input type="text" id="frmaccledger-sub3" name="explic1" onChange={handleformchange} value={formdata.explic1} />
                                                <input type="text" id="frmaccledger-sub3" name="explic2" onChange={handleformchange} value={formdata.explic2} />
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="frmaccledger-group">
                                    <label for="Email id" className="frmaccledger-label">E-mail</label>
                                    <div className="frmaccledger-group-sub">
                                        <input type="text" name="email" className="frmaccledger-input" onChange={handleformchange} value={formdata.email} />
                                    </div>
                                </div>

                                <div className="frmaccledger-group">
                                    <label for="website" className="frmaccledger-label">webSite</label>
                                    <div className="frmaccledger-group-sub">
                                        <input type="text" name="website" className="frmaccledger-input" onChange={handleformchange} value={formdata.website} />
                                    </div>
                                </div>

                                {(groupid !== agentid) &&

                                    <div className="frmaccledger-group">
                                        <label for="contactperson" className="frmaccledger-label">Contact Person</label>
                                        <div className="frmaccledger-group-sub">
                                            <input type="text" name="contactperson" className="frmaccledger-input" onChange={handleformchange} value={formdata.contactperson} />
                                        </div>
                                    </div>
                                }
                                {(groupid !== agentid) &&
                                    <div className="frmaccledger-group">
                                        <label for="mobile number-1" className="frmaccledger-label">Mobile</label>
                                        <div className="frmaccledger-group-sub">
                                            <div className="frmaccledger-group-sub2">
                                                <input type="text" id="frmaccledger-mobile" name="mobile number-1"
                                                    value={mobNumbers[3].mobNo}
                                                    maxLength="10"
                                                    onChange={(event) => handleMobChange(3, event)}
                                                />
                                                <FaWhatsapp className="fa-whatsapp-icon" />
                                                <CheckboxCircle
                                                    checked={isChecked4}
                                                    onChange={handleCheckboxChange4}
                                                    tabindex={-1}
                                                    className="cricleicon"
                                                />
                                            </div>

                                            <div className="frmaccledger-group-sub2">
                                                <input type="text" id="frmaccledger-mobile" name="mobile number-1"
                                                    value={mobNumbers[4].mobNo}
                                                    maxLength="10"
                                                    onChange={(event) => handleMobChange(4, event)}
                                                />
                                                <FaWhatsapp className="fa-whatsapp-icon" />
                                                <CheckboxCircle
                                                    checked={isChecked5}
                                                    onChange={handleCheckboxChange5}
                                                    tabindex={-1}
                                                    className="cricleicon"
                                                />
                                            </div>

                                            <div className="frmaccledger-group-sub2">
                                                <input type="text" id="frmaccledger-mobile" name="mobile number-1"
                                                    value={mobNumbers[5].mobNo}
                                                    maxLength="10"
                                                    onChange={(event) => handleMobChange(5, event)}
                                                />
                                                <FaWhatsapp className="fa-whatsapp-icon" />
                                                <CheckboxCircle
                                                    checked={isChecked6}
                                                    onChange={handleCheckboxChange6}
                                                    tabindex={-1}
                                                    className="cricleicon"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(groupid !== agentid) &&
                                    <div className="frmaccledger-group">
                                        <label for="usergroup" class="frmaccledger-label">User Group</label>
                                        <div className="frmaccledger-group-sub">
                                            <input type="text" id="frmaccledger-place" name="usergroup" onChange={handleformchange} value={formdata.usergroup} />
                                        </div>
                                    </div>

                                }

                                <div className="frmaccledger-btngroup">
                                    <button className='frmaccledger-btn' ref={defaultEscapeRef} onClick={frmClose}> Cancel</button>
                                    <button className='frmaccledger-btn' ref={defaultEnterRef} onClick={() => handleSave()} >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccLedger;



