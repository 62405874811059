import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import * as u from "../utils/Common";

import './ProductMaster.css'

const ProductMaster = () => {
  const myNavigate = useNavigate()
  const [datalist, setdatalist] = useState([])
  const [selectedpdtgroup, setselectedpdtgroup] = useState("");

  const [cess, setCess] = useState('');
  const [cessrate, setCessRate] = useState('');
  const [wostock, setwostock] = useState(false);
  const [uofmdata, setuofmdata] = useState([]);
  const [selecteduofm, setselecteduofm] = useState('');
  const [pdtnameValue, setpdtnameValue] = useState('');
  const [ratevalue, setratevalue] = useState('');
  const [ratepervalue, setratepervalue] = useState('');
  const [serialnovalue, setserialnovalue] = useState('');
  const [selectedgstrate, setselectedgstrate] = useState();
  const [hsncode, sethsnCode] = useState('');
  const [shortname, setShortName] = useState('');

  const pdtnameRef = useRef(null);
  const pdtuofmRef = useRef(null);
  const pdtrateRef = useRef(null);
  const gstrateRef = useRef(null);
  const pdtrateperRef = useRef(null);
  const pdtserialRef = useRef(null);

  let url = "";
  const inputgroupnameRef = useRef()
  const gstratedata = [0, 1, 5, 6, 12, 18, 28];

  const curmode = useSelector(function (data) {
    return data.pdtmstrmode;
  })

  useEffect(() => {

    const fetchuofmdata = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/readuofm`)
        setuofmdata(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchpdtgrpdata = async () => {
      axios.get(`${process.env.REACT_APP_RAINBOW_URL}/productgroup_readall`)
        .then(response => {
          const options = response.data.map(data => ({ value: data.pdtgroupcode, label: data.pdtgroupname }));
          setdatalist(options)
          console.log(options);
        })
        .catch(error => {
          console.error('Error fetching last record:', error);
        });
    };

    const fetchdata = async (e) => {
      if (curmode[0] === "Edit") {
        const data = { pdtcode: curmode[1] };
        const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_readbypdtcode`, data)
        if (response.data.length > 0) {
          console.log(response.data[0].pdtgroupcode);
          setserialnovalue(response.data[0].pdtserial);
          pdtnameRef.current.value = response.data[0].pdtname;
          setpdtnameValue(response.data[0].pdtname);
          pdtuofmRef.current.value = response.data[0].uofm;
          setselecteduofm(response.data[0].uofm);
          setratepervalue(response.data[0].rateper);
          setratevalue(response.data[0].rate);
          sethsnCode(response.data[0].hsn);
          setwostock(response.data[0].wostock ? true : false);
          setShortName(response.data[0].shortname);
          setselectedgstrate(response.data[0].gstrate);
          setCess(response.data[0].cess);
          setCessRate(response.data[0].cessrate);
          setselectedpdtgroup({
            value: response.data[0].pdtgroupcode,
            label: response.data[0].pdtgroupname
          });

        }
      }
    };
    const fetchlastserial = async () => {
      pdtserialRef.current.value = 1;
      axios.get(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_lastserial`)
        .then(response => {
          if (response.data) {
            pdtserialRef.current.value = response.data.pdtserial + 1;
            setserialnovalue(response.data.pdtserial + 1);
          }
        })
        .catch(error => {
          console.error('Error fetching last record:', error);
        });
    }


    fetchuofmdata();
    fetchpdtgrpdata();
    fetchdata()
    if (curmode[0] === "New") {
      fetchlastserial();
    }

  }, [curmode]);


  const handeleuofmChanged = (event) => {
    setselecteduofm(event.target.value);
  }
  const handelegstrateChanged = (event) => {
    setselectedgstrate(event.target.value);
  }

  const handlepdtnamechange = (event) => {
    const { value } = event.target;
    setpdtnameValue(value);
  };
  
  const handleShortNameChange = (event) => {
    const { value } = event.target;
    setShortName(value);
  };

  const handlerateChange = (values) => {
    let { value } = values;
    setratevalue(value);
  }
  const handleCessChange = (values) => {
    let { value } = values;
    setCess(value);
  }
  const handleHsnChange = (values) => {
    let { value } = values;
    sethsnCode(value);
  }
  const handleCessRateChange = (values) => {
    let { value } = values;
    setCessRate(value);
  }
  const handlerateperChange = (values) => {
    let { value } = values;
    setratepervalue(value);
  }
  const handlewostockChange = (event) => {
    setwostock(event.target.checked);
  }

  const handleserialChange = (event) => {
    let { value } = event.target;
    if (!(/^\d{0,4}$/.test(value))) {
      return;
    }
    value = parseInt(value);
    setserialnovalue(value);
  }



  const handleAvoidDotKeyPress = (event) => {
    // Get the key code of the pressed key
    const keyCode = event.keyCode || event.which;
    // Check if the key pressed is a decimal point
    if (keyCode === 46 || keyCode === 110 || keyCode === 190) {
      // Prevent default action (i.e., typing the decimal point)
      event.preventDefault();
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '2px',
      fontSize: "18px",
      fontWeight: 600,
      borderColor: state.isFocused ? '#0366d6' : '#ccc',
      boxShadow: state.isFocused ? '0 0 0 2px rgba(3, 102, 214, 0.3)' : 'none',
      '&:hover': {
        borderColor: state.isFocused ? '#0366d6' : '#aaa',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#0366d6' : state.isFocused ? '#f1f8ff' : 'white',
      color: state.isSelected ? 'white' : '#333',
      '&:hover': {
        backgroundColor: '#0366d6',
        color: 'white',
      },
    }),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const pdtname = pdtnameRef.current.value;
    const pdtuofm = pdtuofmRef.current.value;

    const pdtserial = parseInt(pdtserialRef.current.value);
    const pdtgroupcode = parseInt(selectedpdtgroup.value);

    if (pdtname.trim() === "") {
      alert("Product Name Should be Given");
      pdtnameRef.current.focus();
      return;
    }
    if (pdtuofm.trim() === "") {
      alert("Product UOFM Should be Given");
      pdtuofmRef.current.focus();
      return;
    }
    if (isNaN(ratevalue)) {
      setratevalue(0)
    }
    if (isNaN(cess)) {
      setCess(0)
    }
    if (isNaN(selectedgstrate) || selectedgstrate === undefined) {
      setselectedgstrate(0)
    }
    if (isNaN(cessrate)) {
      setCessRate(0)
    }
    if ((isNaN(ratepervalue)) || (ratepervalue === 0)) {
      setratepervalue(0)
    }
    if (pdtserial > 9999) {
      alert("Serial No. Should be given..");
      pdtserialRef.current.focus();
      return;
    }
    if (!pdtgroupcode > 0) {
      alert("Product Group Should be Given");
      inputgroupnameRef.current.focus();
      return;
    }
    if(hsncode.length!== 0 && (hsncode.length !== 4 && hsncode.length !==6 && hsncode.length !==8)){
      alert("Invalid HSN Code");
      window.document.getElementById("hsncode").focus();
      return;
    }

    try {
      const data = {
        pdtserial: pdtserial,
        pdtcode: (curmode[0] === "New" ? 0 : curmode[1])
      };
      const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_readpdtserial4pdtcode`, data);
      if (response.data.length > 0) {
        alert('Serial already Given ');
        pdtserialRef.current.focus();
        return;
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (show error message, etc.)
    }

    try {
      const data = {
        pdtname: pdtname,
        pdtcode: (curmode[0] === "New" ? 0 : curmode[1])
      };
      const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_readpdtname`, data);
      if (response.data.length > 0) {
        alert('Product Name already Given ');
        pdtnameRef.current.focus();
        return;
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
    console.log(shortname);
    try {
      const data = {

        pdtserial: pdtserial,
        pdtcode: (curmode[0] === "New" ? pdtserial : curmode[1]),
        pdtname: u.toProperCase(pdtname.trim()),
        uofm: pdtuofm.trim(),
        rateper: parseInt(ratepervalue),
        wostock: wostock ? 1 : 0,
        netrate: 0,
        casecontent: 0,
        bdlecontent: 0,
        hsn: hsncode,
        pdtgroupcode: pdtgroupcode,
        shortname:shortname.toUpperCase(),
        rate: parseFloat(ratevalue),
        gstrate: selectedgstrate,
        cess: parseFloat(cess),
        cessrate: parseFloat(cessrate),
      };
      console.log(data);

      url = `${process.env.REACT_APP_RAINBOW_URL}/productmaster_create`
      if (curmode[0] === "Edit") {
        url = `${process.env.REACT_APP_RAINBOW_URL}/productmaster_update`
      }
      await axios.post(url, data);


      if (curmode[0] === "Edit") {
        myNavigate("/listproductmaster")
        return;
      }
      //  fetchlastserial();
      pdtserialRef.current.value = parseInt(pdtserialRef.current.value) + 1;
      pdtnameRef.current.value = "";
      setpdtnameValue('');
      setserialnovalue(pdtserialRef.current.value);
      pdtnameRef.current.focus();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const fetchlastserial = async () => {
    pdtserialRef.current.value = 1;
    axios.get(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_lastserial`)
      .then(response => {
        if (response.data) {
          pdtserialRef.current.value = response.data.pdtserial + 1;
        }
      })
      .catch(error => {
        console.error('Error fetching last record:', error);
      });
  }


  useEffect(() => {
    if (curmode[0] === "New") {
      fetchlastserial();
    }
  }, [curmode]);

  useEffect(() => {
    pdtnameRef.current.focus();
  }, []);

  const defaultEnterRef = useRef(null)
  const defaultEscapeRef = useRef(null)

  useEffect(() => {
    const handleKeyPress = (event) => {
      event = event || window.event;
      if ((event.key === 'Enter') || (event.ctrlKey && event.key === 'a')) {
        defaultEnterRef.current.click();
      }
      if (event.key === 'Escape') {
        defaultEscapeRef.current.click();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };

  }, []);

  return (
    <div>
      <div className="divmain">
        <h1>Product Master</h1>
        <div className="prodet">
          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">Item Name</label>
            <input
              ref={pdtnameRef}
              value={pdtnameValue}
              onChange={handlepdtnamechange}
              className='prodetailinp1'
              type="text"
              maxLength="75" />
          </div>

          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">UOFM</label>
            <div className='prodetnumb' style={{ width: "100px" }}>
              <select onChange={handeleuofmChanged} value={selecteduofm} ref={pdtuofmRef}>
                {uofmdata.map(data => (
                  <option key={data.id} value={data.uofm}>{data.uofm}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">Rate</label>
            <div className='divproductdetails-subgroup'>
              <NumericFormat
                ref={pdtrateRef}
                className='prodetnumb'
                onValueChange={handlerateChange}
                thousandSeparator={false}
                decimalScale={2}
                fixedDecimalScale={false}
                allowNegative={false}
                maxLength="10"
                id='rate'
                name="rate"
                value={ratevalue}
                min="0" />

              <div className="divproductdetails-subgroup2">
                <label className='prodetlabel2' htmlFor="" style={{ textAlign: "center", marginLeft: "-12px" }}> Per</label>
                <NumericFormat
                  ref={pdtrateperRef}
                  className='prodetnumb'
                  onValueChange={handlerateperChange}
                  thousandSeparator={false}
                  decimalScale={2}
                  fixedDecimalScale={false}
                  allowNegative={false}
                  maxLength="4" min="0"
                  id='rate'
                  name="rate"
                  value={ratepervalue}
                />
                <input className='prodetnumb' type="text" value={selecteduofm} tabIndex="-1" readOnly style={{ textAlign: "left" }} />
                <div className="divproductdetails-subgroup3">
                  <label className='prodetlabel3' htmlFor="">With-out Stock</label>
                  <input type="checkbox" tabIndex="-1" checked={wostock} onChange={handlewostockChange} />
                </div>
              </div>
            </div>
          </div>



          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">GST Rate</label>
            <div className='divproductdetails-subgroup'>
              <select onChange={handelegstrateChanged} value={selectedgstrate} ref={gstrateRef} style={{ width: "60px" }}>
                {gstratedata.map(data => (
                  <option key={data} value={data}>{data}</option>
                ))}
              </select>

              <div className="divproductdetails-subgroup2">
                <label htmlFor="" className='prodetlabel2'>CESS(%)</label>
                <NumericFormat
                  className='prodetnumb'
                  onValueChange={handleCessRateChange}
                  thousandSeparator={false}
                  decimalScale={2}
                  fixedDecimalScale={false}
                  allowNegative={false}
                  maxLength="5"
                  id='cessrate'
                  name="cessrate"
                  value={cessrate}
                  min="0"
                  tabIndex={-1} />
                <label htmlFor="" className='prodetlabel3'>CESS(Amount)</label>
                <NumericFormat
                  className='prodetnumb'
                  onValueChange={handleCessChange}
                  thousandSeparator={false}
                  decimalScale={2}
                  fixedDecimalScale={false}
                  allowNegative={false}
                  maxLength="10"
                  id='cess'
                  name="cess"
                  value={cess}
                  min="0"
                  tabIndex={-1} />
              </div>
            </div>
          </div>


          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">Short Name</label>
            <input type="text" className='prodetailinp1' style={{textTransform:"uppercase"}} value={shortname} onChange={handleShortNameChange} maxLength="20" tabIndex={-1} />
          </div>


          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">Serial</label>
            <input type="number" className='prodetnumb' maxLength="4" value={serialnovalue} onChange={handleserialChange} ref={pdtserialRef} />
          </div>

          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">HSN/SAC</label>
            <NumericFormat
                  id='hsncode'
                  className='prodetailinp2'
                  thousandSeparator={false}
                  decimalScale={0}
                  fixedDecimalScale={false}
                  allowNegative={false}
                  maxLength="8"
                  value={hsncode}
                  onValueChange={handleHsnChange}
                  tabIndex={-1} />
          </div>
          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">Group Name</label>
            <div className='selectoption'>
              <Select
                className='prodetailinp1 pdtgroupSelect'
                ref={inputgroupnameRef}
                options={datalist}
                value={selectedpdtgroup}
                onChange={setselectedpdtgroup}
                styles={customStyles}
              />
            </div>
          </div>
          <div className="divproductdetailsbtns">
            <button className='prodetbtn' onClick={() => myNavigate("/listproductmaster")} ref={defaultEscapeRef} >Cancel</button>
            <button className='prodetbtn' onClick={handleSubmit} ref={defaultEnterRef} >Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProductMaster
