import "./License.css";
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const LicenseForm = () => {
    const usernameRef = useRef();
    const [username, setusername] = useState("");
    const [license, setlicense] = useState(false);
    const myNavigate = useNavigate();

    const handleUsernameChange = async(e) =>{
        const value = e.target.value;
        setusername(value);
    }
    const handleLicenseChange = (e) =>{
        const value = e.target.checked;
        setlicense(value);
    }
    const updateLicense = async () => {
        if (username==="") {
            alert("User Name Should be Given");
            return;
        } 
        const userdata = {
            username: username
        }
        const res = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readbyusername`, userdata);
        if(!res.data.length>0){
           alert('User Not found ...');
           usernameRef.current.focus();
           usernameRef.current.select();
           return;
        }
        if (res.data[0].licensed!=="D") {
            alert("User Name already Activated...");
            usernameRef.current.focus();
            usernameRef.current.select();
            return;
        }
        if (license===false) {
            alert("Activation Not Given...");
            usernameRef.current.focus();
            usernameRef.current.select();
            return;
        }

        const data = {
            username: username,
            licensed: license ? 'Y' : 'D'
        }
        console.log(data);
        const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/LicenseUpdated`, data);
        setusername('');
        setlicense(false);
        alert("User : " +  username + " activated successfully ....")
        myNavigate(-1)
    }
    const defaultEnterRef = useRef(null);
    const defaultEscapeRef = useRef(null);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                defaultEscapeRef.current.click();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);
    return (
        <div className="licenseform-divmain">
            <div className="licenseform-container">
                <div className="licenseform-inputgroup">
                    <label htmlFor="username" >Username</label>
                    <input type="text" 
                        autoFocus={true}
                        id="username" 
                        ref={usernameRef} 
                        value={username} 
                        onChange={handleUsernameChange} 
                        style={{ width: "75%",padding:"5px" }} />
                </div>
                <div className="licenseform-inputgroup">
                    <label htmlFor="license">License</label>
                    <input type="checkbox"
                        id="license" 
                        checked={license} 
                        onChange={handleLicenseChange} 
                        style={{marginLeft:"23px"}}/>
                    <div className="licenseform-btngroup">
                        <button onClick={() => myNavigate(-1)} ref={defaultEscapeRef}>Cancel</button>
                        <button onClick={updateLicense} ref={defaultEnterRef}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LicenseForm;