import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import "./RateList.css";
import * as u from "../utils/Common";

import 'jspdf-autotable';
import { AiOutlinePrinter, AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { myaction } from '../../redux/Mystore';
import ProductMasterList from '../GridLookup/ProductMasterList';
import { NumericFormat } from 'react-number-format';


const RatelistEntry = () => {

    const mydispatch = useDispatch()
    const pricelistnameRef = useRef('');
    const pdtserialRef = useRef('');

    const rateRef = useRef('');
    const rateperRef = useRef('');
    const [pdtserial, setpdtSerial] = useState('');
    const [rate, setRate] = useState('');
    const [rateper, setRatePer] = useState('');

    const [pdtdata, setpdtdata] = useState({ pdtcode: 0, pdtserial: 0, pdtname: '', uofm: '' });
    const [qty, setqty] = useState(0);
    const [curmode, setcurmode] = useState(['New', 0]);
    const [isdisabled, setIsDisabled] = useState(true);
    const [pricelistdata, setPriceListdata] = useState([]);
    const [selectedpricelist, setselectedPriceList] = useState({ pricelistno: 0, pricelistname: '' });

    const [pdtmstrdata, setpdtmstrdata] = useState([]);
    const [selectedproduct, setselectedproduct] = useState(0);
    const [selectedrow, setselectedrow] = useState(null)

    let url = '';


    const handlePricelistChange = (e) => {
        const selectedPriceListno = parseInt(e.target.value);
        const selectedPriceListName = e.target.options[e.target.selectedIndex].text;
        setselectedPriceList({ pricelistno: selectedPriceListno, pricelistname: selectedPriceListName });
        fetchdata(selectedPriceListno);
    };

    const handlepdtSerialChange = (event) => {
        const value = u.myInt(event.target.value);
        if (validateSerial(value)) {
            setpdtSerial(value);
            fetchproductdata();
        }
    };
    const validateSerial = (value) => {
        const regex = /^\d{0,5}$/;
        return regex.test(value);
    };

    const handleChangeRate = (values) => {
        const {value}= values;
        setRate(value);
    }

    const handleChangeRatePer = (values) => {
        const {value} = values;
        setRatePer(value);
    }

    const resetform = () => {
        setpdtSerial('');
        pdtdata.pdtname = '';
        pdtdata.uofm = '';
        setpdtdata(pdtdata);
        setRate('');
        setRatePer('');
    }

    const handleSave = async () => {
        let value = u.myInt(selectedpricelist.pricelistno)
        if (value === 0) {
            alert('Price List Should be given');
            pricelistnameRef.current.focus();
            return;
        }

        value = u.myInt(pdtserialRef.current.value);
        if (value === 0) {
            alert('Must Enter Valid Product Serial');
            pdtserialRef.current.focus();
            return;
        }

        try {
            const data = {

                pricelistno: u.myInt(selectedpricelist.pricelistno),
                pdtcode: pdtdata.pdtcode,
                rate: u.myFloat(rate),
                rateper: u.myInt(rateper),
                recno: curmode[1],

            };
            console.log(data);
            console.log(curmode);
            url = `${process.env.REACT_APP_RAINBOW_URL}/ratelist_create`
            if (curmode[0] === "Edit") {
                url = `${process.env.REACT_APP_RAINBOW_URL}/ratelist_update`
            }
            await axios.post(url, data);
            console.log(data);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
        setIsDisabled(true);
        fetchdata(selectedpricelist.pricelistno);
        if (curmode[0] === "New") {
            handleAddNew()
        } else {
            tableRef.current.focus();
            
        }
    };


    const fetchproductdata = async () => {
        try {
            const data = { pdtserial: u.myInt(pdtserialRef.current.value) };
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_readpdtserial`, data)
            if (response.data.length > 0) {
                setpdtdata(response.data[0]);

            } else {
                setpdtdata({ pdtcode: 0, pdtserial: 0, pdtname: '', uofm: '' });
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };




    const defaultEnterRef = useRef(null)
    const defaultEscapeRef = useRef(null)


    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if ((event.key === 'Enter') || (event.ctrlKey && event.key === 'a')) {
                // defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                // defaultEscapeRef.current.click();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        const fetchPriceListdata = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/pricelist_readall`)

                setPriceListdata(response.data);

            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchPriceListdata();
    }, []);

    useEffect(() => {
        const fetchpdtmstrdata = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_readall`)
                setpdtmstrdata(response.data);
            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchpdtmstrdata();
    }, []);


    const tableRef = useRef();
    const [initialData, setinitialData] = useState([]);
    const [curdata, setcurdata] = useState([]);

    const [search, setSearch] = useState('');
    const [sortField, setSortField] = useState('pdtname');
    const [sortOrder, setSortOrder] = useState('asc');

    const fetchdata = async (value) => {
        try {
            const data = {

                pricelistno: value,
            }
            console.log(value);

            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/ratelist_readpricelistwise`, data);
            setinitialData(response.data);
            console.log(response.data)
            setcurdata(response.data);

        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        if (value === "") {
            setcurdata(initialData);
            return;
        }
        const filtered = initialData.filter(item => item.pdtname.toLowerCase().includes(value.toLowerCase()));
        setcurdata(filtered);

    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const handleDelete = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(data);
            }
            resetform();
        });
    }

    const confirmDelete = async (data) => {
        const userdata = {
            recno: data.recno
        }
        console.log(userdata)
        const url = `${process.env.REACT_APP_RAINBOW_URL}/ratelist_delete`
        try {
            await axios.post(url, userdata);
            fetchdata(selectedpricelist.pricelistno);

        } catch (error) {
            console.error('There was an error deleting the data!', error);
        }

    };

    const thStyle = {
        position: 'sticky',
        top: '0',
        backgroundColor: '#222222',
    };

    const handlePrint = () => {
        window.print();
    };

    const handleEdit = (data) => {
        setIsDisabled(false);
        setcurmode(["Edit", data.recno])
        pdtserialRef.current.focus();
    }

    const handleAddNew = () => {
        setIsDisabled(false);
        setcurmode(["New", 0])
        resetform();

    }

    const navigate = useNavigate()

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Escape') {
                navigate("/emptypage")
                mydispatch(myaction.hideMenu(false))
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);


    const handleRowClick = (rowdata) => {
        setIsDisabled(true);
        setpdtSerial(rowdata.pdtserial);
        setpdtdata({ pdtcode: rowdata.pdtcode, pdtserial: rowdata.pdtserial, pdtname: rowdata.pdtname, uofm: rowdata.uofm, });
        setRate(rowdata.rate);
        setRatePer(rowdata.rateper);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onDoubleClickpdtserial = () => {
        setIsModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const [selectedRow, setSelectedRow] = useState(null);
 

    const handleonselect = (data) => {
        const rowData = data;  // Get data of the double-clicked row
        console.log(rowData);
        setSelectedRow(rowData);
        setIsModalOpen(false);  // Close modal after selection
        setpdtSerial(rowData.pdtserial)
        pdtserialRef.current.value = rowData.pdtserial;
        fetchproductdata();
        pdtserialRef.current.focus();
    };


    const handleRowDoubleClick = (data) => {
        const rowData = data;  // Get data of the double-clicked row
        console.log(rowData);
        setSelectedRow(rowData);
        setIsModalOpen(false);  // Close modal after selection
        setpdtSerial(rowData.pdtserial)
        pdtserialRef.current.value = rowData.pdtserial;
        fetchproductdata();
        pdtserialRef.current.focus();
    };
    const handleCancel = ()=>{
        resetform();
        setIsDisabled(true);
    }

    return (
        <div className="frmRatelistEntry-divmain">
            <div className="frmRatelistEntry-frmmain">
                <div className='toppanel'>
                    <div className='toppanel-header'>
                        <p>Rate List</p>
                    </div>
                    <div className='searchtext' style={{ marginTop: '5px' }}>
                        <input
                            type="text"
                            placeholder="Search"
                            value={search}
                            onChange={handleSearchChange}
                            className="search-input"
                        />
                        < div className="select-pricelist">
                            <label>&nbsp;PriceList :</label>
                            <select name="pricelistname" id="pricelistname" style={{ width: '300px', marginLeft: "3px" }} value={selectedpricelist.pricelistno} onChange={handlePricelistChange} ref={pricelistnameRef} required  >
                                <option value="0" disabled selected>Select Price List...</option>
                                {pricelistdata.map(data => (
                                    <option key={data.pricelistno} value={data.pricelistno}>{data.pricelistname} </option>
                                ))}
                            </select>
                            &nbsp;
                        </div>
                        <div className="mybtncontainer">

                            <button className="mybtn" onClick={handlePrint}><AiOutlinePrinter className="btn-icon" />Print</button>
                            <button className="mybtn" onClick={handleAddNew}><AiOutlinePlus className='btn-icon' />Addnew</button>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className="leftpanel">

                        <div className="left-container">


                            <div className="lookupTable" >
                                <table className="tabledata" id="ratelist-table" ref={tableRef}>
                                    <thead className='tablehead'  >
                                        <tr style={{ position: 'sticky', top: '-1px' }}>
                                            <th style={{ backgroundColor: "black" }}
                                                className="col-pdtname" onClick={() => handleSort('pdtname')}>
                                                Product Name
                                            </th>


                                            <th style={{ backgroundColor: "black" }} className="col-rate">
                                                Rate
                                            </th>
                                            <th style={{ backgroundColor: "black" }} className="col-rateper">
                                                Rate Per
                                            </th>

                                            <th style={{ backgroundColor: "black" }} className="col-uofm">
                                                UOFM
                                            </th>

                                            <th style={{ backgroundColor: "black" }} className="col-action" >Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {curdata.map(data => (
                                            <tr className="row-data" key={data.recno} onClick={() => handleRowClick(data)} onDoubleClick={() => handleEdit(data)}>
                                                <td className="col-pdtname" >{data.pdtname}</td>
                                                <td className="col-rate" >{data.rate}</td>
                                                <td className="col-rateper" >{data.rateper}</td>
                                                <td className="col-uofm" >{data.uofm}</td>
                                                <td className="col-action">
                                                    <div className='col-action-div'>
                                                        <button onClick={() => handleEdit(data)}>Edit</button>
                                                        <button onClick={() => handleDelete(data)}>Delete</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="rightpanel">
                    <fieldset style={{ width: "100%", border: "2px solid whitesmoke", marginTop: "8px", marginBottom: "8px" }} disabled={isdisabled}>

                        <div className="right-container">
                      
                            <div className="input-row">
                                <label> Product Serial :</label>
                                <input type="number" onChange={handlepdtSerialChange} value={pdtserial} ref={pdtserialRef}
                                    onDoubleClick={onDoubleClickpdtserial}
                                    onKeyDown={(e) => {
                                        if (e.key === 'e' || e.key === 'E') {
                                            e.preventDefault();
                                        }
                                    }} />
                                {isModalOpen && (
                                    <ProductMasterList
                                        closeModal={closeModal}
                                        onRowDoubleClick={handleRowDoubleClick}
                                    />
                                )}
                            </div>
                            <div className="input-row">
                                <label>Product Name : </label>
                                <input type="text" readOnly
                                    style={{ width: '250px' }} tabIndex={-1} value={pdtdata.pdtname} />
                            </div>
                            <div className="input-row">
                                <label>Rate : </label>
                                <NumericFormat
                                    thousandSeparator={false}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    ref={rateRef}
                                    onValueChange={ handleChangeRate}
                                    value={rate}
                                    id='rate' name='rate' 
                                    maxLength={10}
                                    style={{ width: '100px' }}
                                />

                            </div>
                            <div className="input-row">
                                <label>Rate Per: </label>
                                <NumericFormat
                                    thousandSeparator={false}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    ref={rateperRef}
                                    onValueChange={handleChangeRatePer}
                                    value={rateper}
                                    id='rateper' name='rateper' 
                                    maxLength={4}
                                />
                                <label>&nbsp;&nbsp;{pdtdata.uofm}</label>
                            </div>

                        </div>
                        <div className="btngroup">
                            <button className="btn" onClick={handleCancel}>Cancel</button>
                            <button className="btn" onClick={handleSave}>Save</button>
                        </div>
                        </fieldset>

                    </div>
                </div>
            </div>
        </div>

    );

};
export default RatelistEntry;
