import "./acchead.css"
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as u from "../utils/Common";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const AccHead = ({ accgroupid=8 }) => {
    let groupid=accgroupid;
    let salesid = 8;
    let taxid = 16;
    let accgroupname = '';
    
    
    if (groupid===salesid){
        accgroupname = 'Sales Ledger';
    }
    if (groupid===taxid){
        accgroupname = 'Tax Ledger';

    }
    const myNavigate = useNavigate();


    const actnameRef = useRef('');
    const [actname, setactname] = useState('');   
   
   
    const curmode = useSelector(function (data) {
        return data.accledgermode;
    })


     

    let url = '';

    useEffect(() => {
        actnameRef.current.focus();
    }, []);

    const handleformchange = (event) => {
        const  {value } = event.target;
        setactname(u.toProperCase(value));
    };

    useEffect(() => {
        const fetchdata = async (e) => {
            const data = { actcode: curmode[1] };
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/acchead_readbyactcode`, data)
            if (response.data.length > 0) {
                setactname(response.data[0].actname)
                
            }
        };
        if (curmode[0] === "Edit") {
            fetchdata();
        }
    }, [curmode])

    const handleSave = async (e) => {

        let actname = u.myTrim(actnameRef.current.value);
        if (actname === "") {
            alert(" Name Should be Given");
            actnameRef.current.focus()
            return;
        }
        
      
        try {
            const data = {
                actname: u.myTrim(actname),
                
                

                actcode: curmode[0] === "New" ? 0 : curmode[1],
            };
            console.log(data)
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/acchead_checkactnameexist`, data);
            console.log(response.data)
            if (response.data.length > 0) {
                alert('Name  already Given ')
                return;
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error (show error message, etc.)
        }

            
      

        try {
            const data = {
                actcode: (curmode[0] === "New" ? 0 : curmode[1]),
                actname: actname,
                accgroupcode: groupid,

            };
            console.log(data);
            url = `${process.env.REACT_APP_RAINBOW_URL}/acchead_create`
            if (curmode[0] === "Edit") {
                url = `${process.env.REACT_APP_RAINBOW_URL}/acchead_update`
            }
            await axios.post(url, data);

            if (curmode[0] === "Edit") {
                myNavigate("/listaccledger_sales")
                return;
            }
            actnameRef.current.value = "";
            actnameRef.current.focus();

        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
  
    const defaultEnterRef = useRef(null)
    const defaultEscapeRef = useRef(null)

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if ((event.key === 'Enter') || (event.ctrlKey && event.key === 'a')) {
                defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                defaultEscapeRef.current.click();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <>
            <div className="frmacchead-divmain" >
                <div className="frmacchead-main">
                    <div className="frmacchead-container-page">
                        <div className="frmacchead-header">
                            <h3>{accgroupname}</h3>
                        </div>
                        <div className="frmacchead-container">
                            <div className="frmacchead-panel">
                                <div className="frmacchead-group">
                                    <label for="actname" className="frmsacchead-label required"> Name</label>
                                    <input type="text" id="actname" value={actname} onChange={handleformchange} className="frmacchead-input" required autofocus maxlength="75" ref={actnameRef} />
                                
                                </div>
                                
                               
                                <div className="frmacchead-btngroup">
                                    <button className='frmacchead-btn' ref={defaultEscapeRef} onClick={() => myNavigate(-1)}> Cancel</button>
                                    <button className='frmacchead-btn' ref={defaultEnterRef} onClick={() => handleSave()} >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccHead;
