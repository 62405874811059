import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import "./StockEntry.css";
import * as u from "../utils/Common";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
// import React, { useState, useRef, useEffect } from 'react';
// import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AiOutlineFilePdf, AiOutlineFileExcel, AiOutlineFileText, AiOutlinePrinter, AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { myaction } from '../../redux/Mystore';
import ProductMasterList from '../GridLookup/ProductMasterList';
import InputMask from 'react-input-mask';
import { NumericFormat } from 'react-number-format';


const StockEntry = ({TrnType=1}) => {

    const mydispatch = useDispatch()
    const dateRef = useRef('');
    const factorynameRef = useRef('');
    const factory2nameRef = useRef('');
    const pdtserialRef = useRef('');

    const qtyRef = useRef('');
    const nofcaseRef = useRef('');
    const casecontentRef = useRef('');
    const trntype = TrnType;
    let trnname ="Openning Stock";

    if (trntype===2){
        trnname="Production";
    } else if (trntype===8) {
        trnname="Transfer";
    }


    const [tdate, setDate] = useState('');
    const [pdtserial, setpdtSerial] = useState('');
    const [nofcase, setnofcase] = useState('');

    const [pdtcase, setpdtcase] = useState({ casecontentYN: true, qtyYN: false });
    const [pdtdata, setpdtdata] = useState({ pdtcode: 0, pdtserial: 0, pdtname: '', uofm: '', casecontent: 0 });
    const [qty, setqty] = useState(0);
    const [curmode, setcurmode] = useState(['New',0]);

    const [factorydata, setfactorydata] = useState([]);
    const [selectedfactory, setselectedfactory] = useState({ factorycode: 0, factoryname: '' });
    const [selectedfactory2, setselectedfactory2] = useState({ factorycode: 0, factoryname: '' });

    const [pdtmstrdata, setpdtmstrdata] = useState([]);
    const [selectedproduct, setselectedproduct] = useState(0);
    const [selectedrow, setselectedrow]= useState(null)

    let url = '';
   

    const handleDateChange = (e) => {
        const input = e.target.value;
        const formattedDate = formatDate(input);
        setDate(formattedDate);
    };
    const handleFactoryChange = (e) => {
        const selectedFactoryCode = parseInt(e.target.value);
        const selectedFactoryName = e.target.options[e.target.selectedIndex].text;
        setselectedfactory({ factorycode: selectedFactoryCode, factoryname: selectedFactoryName });
        fetchdata(selectedFactoryCode);
    };


    
    const handleFactory2Change = (e) => {
        const selectedFactoryCode = parseInt(e.target.value);
        const selectedFactoryName = e.target.options[e.target.selectedIndex].text;
        setselectedfactory2({ factorycode: selectedFactoryCode, factoryname: selectedFactoryName });
    };

    const formatDate = (input) => {
        // Remove any non-numeric characters
        let dateValue = input.replace(/[^\d]/g, '');

        // Format the date as DD/MM/YYYY
        if (dateValue.length >= 2) {
            dateValue = dateValue.slice(0, 2) + '/' + dateValue.slice(2);
        }
        if (dateValue.length >= 5) {
            dateValue = dateValue.slice(0, 5) + '/' + dateValue.slice(5, 7);
        }

        return dateValue;
    };

    const handlepdtSerialChange = (event) => {
        const value = u.myInt(event.target.value);
        if (validateSerial(value)) {
            setpdtSerial(value);
            fetchproductdata();
        }
    };
    const validateSerial = (value) => {
        const regex = /^\d{0,5}$/;
        return regex.test(value);
    };

    const resetform = () => {
        setpdtSerial('');
        setnofcase('');
        setqty('');
        pdtdata.pdtname = '';
        pdtdata.uofm = '';
        pdtdata.casecontent = '';
        nofcaseRef.current.value=''
        qtyRef.current.value=''
        setpdtdata(pdtdata);
    }

    const handleSave = async () => {
        if (!u.validateDate(dateRef.current.value)) {
            dateRef.current.focus()
            return;
        }
        let value = u.myInt(selectedfactory.factorycode)
        if (value === 0) {
            alert('Factory Should be given');
            factorynameRef.current.focus();
            return;
        }
        value = u.myInt(pdtserialRef.current.value);
        if (value === 0) {
            alert('Must Enter Valid Product Serial');
            pdtserialRef.current.focus();
            return;
        }
        if (pdtdata.pdtname==="") {
            alert('Please Select a Product');
            pdtserialRef.current.focus();
            return;
        }
        value = u.myInt(qtyRef.current.value);
        if (value === 0) {
            alert('Must Enter Valid Quantity Number');
            nofcaseRef.current.focus()
            return;
        }
       
        if (trntype===8) {
           value = u.myInt(selectedfactory2.factorycode)
            if (value === 0) {
                alert('To Factory Should be given');
                factory2nameRef.current.focus();
                return;
            }
        }

        
      
        try {
            const data = {
                trntype: trntype,
                tdate: tdate,
                factorycode: u.myInt(selectedfactory.factorycode),
                pdtcode: pdtdata.pdtcode,
                nofcase: u.myInt(nofcase),
                casecontent: u.myInt(pdtdata.casecontent),
                qty: u.myInt(qty),
                recno:curmode[1],
                transfer2factorycode : trntype===8 ? u.myInt(selectedfactory2.factorycode) : 0
            };
            console.log(curmode);
            url = `${process.env.REACT_APP_RAINBOW_URL}/stock_create`
            if (curmode[0] === "Edit") {
                url = `${process.env.REACT_APP_RAINBOW_URL}/stock_update`
            }
            await axios.post(url, data);
            console.log(data);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
        fetchdata(selectedfactory.factorycode);
        if (curmode[0]==="New") {
            handleAddNew()
        } else {
            tableRef.current.focus();
        }
    };


    const fetchproductdata = async () => {
        try {
            const data = { pdtserial: u.myInt(pdtserialRef.current.value) };
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_readpdtserial`, data)
            if (response.data.length > 0) {
                setpdtdata(response.data[0]);
            } else {
                setpdtdata({ pdtcode: 0, pdtserial: 0, pdtname: '', uofm: '', casecontent: 0 });
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleQtyStatus = (value) => {
        setpdtcase((prevState) => ({
            ...prevState,
            casecontentYN: false, qtyYN: true // Update the specific property based on the input name
        }))
        if (value > 0) {
            setpdtcase((prevState) => ({
                ...prevState,
                casecontentYN: true, qtyYN: false // Update the specific property based on the input name
            }))
        }
        reValue()
    }

    const handlenofcaseChange = (e) => {
        const value = u.myInt(e.target.value);
        setnofcase(value);
        handleQtyStatus(value)
    }
    const handleChangeCaseContent = (e) => {
        const value = u.myInt(e.target.value);
        setpdtdata((prevState) => ({
            ...prevState,
            casecontent: value // Update the specific property based on the input name
        }))
        reValue();
    }
    const handlenofcaseonBlur = (e) => {
        const value = u.myInt(e.target.value);
        handleQtyStatus(value)
    }

    const reValue = () => {
        const xnofcase = u.myInt(nofcaseRef.current.value);
        const xcasecontent = u.myInt(casecontentRef.current.value);
        if (xnofcase > 0 || xcasecontent > 0) {
            setqty(xnofcase * xcasecontent);
        }
    }

    const handleChangeQty = (e) => {
        const value = u.myInt(e.target.value)
        setqty(value);
    }
    const defaultEnterRef = useRef(null)
    const defaultEscapeRef = useRef(null)


    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if ((event.key === 'Enter') || (event.ctrlKey && event.key === 'a')) {
                // defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                // defaultEscapeRef.current.click();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        const fetchfactorydata = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/store_readall`)
                setfactorydata(response.data);

            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchfactorydata();
    }, []);

    useEffect(() => {
        const fetchpdtmstrdata = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_readall`)
                setpdtmstrdata(response.data);
            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchpdtmstrdata();
    }, []);


    const tableRef = useRef();
    const [initialData, setinitialData] = useState([]);
    const [curdata, setcurdata] = useState([]);

    const [search, setSearch] = useState('');
    const [sortField, setSortField] = useState('pdtname');
    const [sortOrder, setSortOrder] = useState('asc');

    const fetchdata = async (value) => {
        try {
            const data = {
                trntype: trntype,
                factorycode:value,
            }
            console.log(value);

            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/stock_readfactorywise`, data);
            setinitialData(response.data);
            console.log(response.data)
            setcurdata(response.data);

        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        if (value === "") {
            setcurdata(initialData);
            return;
        }
        const filtered = initialData.filter(item => item.trndate.toLowerCase().includes(value.toLowerCase()));
        setcurdata(filtered);

    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const handleDelete = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(data);
            }
            resetform();
        });
    }

    const confirmDelete = async (data) => {
        const userdata = {
            recno: data.recno
        }
        console.log(userdata)
        const url = `${process.env.REACT_APP_RAINBOW_URL}/stock_delete`
        try {
            await axios.post(url, userdata);
            fetchdata(selectedfactory.factorycode);
           
        } catch (error) {
            console.error('There was an error deleting the data!', error);
        }
      
    };

    const thStyle = {
        position: 'sticky',
        top: '0',
        backgroundColor: '#222222',
    };

    const handlePrint = () => {
        window.print();
    };

    const handlePDFDownload = () => {
        const doc = new jsPDF();
        doc.autoTable({ html: '#stock-table' });
        doc.save('stockentry-list.pdf');
    };

    const handleExportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(curdata);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Entry");
        XLSX.writeFile(workbook, "data.xlsx");
    };

    const handleExportToJSON = () => {
        const blob = new Blob([JSON.stringify(curdata, null, 2)], { type: 'application/json' });
        saveAs(blob, 'data.json');
    };

    const handleEdit = (data) => {
        setcurmode(["Edit",data.recno])
        window.document.getElementById("date").focus();
    }

    const handleAddNew = () => {
        setcurmode(["New",0])
        resetform();
        setDate(u.curdate(true));

        window.document.getElementById("date").select();
        window.document.getElementById("date").focus();

        if (trntype===1 && initialData.length>0) {
            setDate(initialData[0].trndate)
            pdtserialRef.current.focus();
        }
    }

    const navigate = useNavigate()

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Escape') {
                navigate("/emptypage")
                mydispatch(myaction.hideMenu(false))
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const totalNofCase = curdata.reduce((total, item) => total + (item.nofcase || 0), 0)

    const handleRowClick = (rowdata) => {
        setDate(rowdata.trndate);
        setpdtSerial(rowdata.pdtserial);
        setpdtdata({ pdtcode: rowdata.pdtcode, pdtserial: rowdata.pdtserial, pdtname: rowdata.pdtname, uofm: rowdata.uofm, casecontent: rowdata.casecontent });
        setnofcase(rowdata.nofcase);
        setqty(rowdata.qty);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onDoubleClickpdtserial =() =>{
        setIsModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const [selectedRow, setSelectedRow] = useState(null);
    
    
    const handleonselect = (data) => {
        const rowData =  data;  // Get data of the double-clicked row
        console.log(rowData);
        setSelectedRow(rowData);
        setIsModalOpen(false);  // Close modal after selection
        setpdtSerial(rowData.pdtserial)
        pdtserialRef.current.value=rowData.pdtserial;
        fetchproductdata();
        pdtserialRef.current.focus();
    };


    const handleRowDoubleClick = (data) => {
        const rowData = data;  // Get data of the double-clicked row
        console.log(rowData);
        setSelectedRow(rowData);
        setIsModalOpen(false);  // Close modal after selection
        setpdtSerial(rowData.pdtserial)
        pdtserialRef.current.value=rowData.pdtserial;
        fetchproductdata();
        pdtserialRef.current.focus();
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
          event = event || window.event;
          if ((event.key === 'Enter') || (event.ctrlKey && event.key === 'a')) {
            defaultEnterRef.current.click();
          }
          if (event.key === 'Escape') {
            defaultEscapeRef.current.click();
          }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
     }, []);
    

    return (
        <div className="frmStockEntry-divmain">
            <div className="frmStockEntry-frmmain">
                <div className="leftpanel">
                    <div className="leftpanel-header">
                        <p>{trnname}</p>
                    </div>
                    <div className="left-container">
                        <div className='searchtext' style={{marginTop:'5px'}}>
                            <input
                                type="text"
                                placeholder="Search"
                                value={search}
                                onChange={handleSearchChange}
                                className="search-input"
                            />
                            < div className="select-factory">
                                <label>&nbsp;Factory :</label>
                                <select name="factoryname" id="factoryname" style={{ width: '300px' , marginLeft:"3px"}} value={selectedfactory.factorycode} onChange={handleFactoryChange} ref={factorynameRef} required  >
                                    <option value="0" disabled selected>Select Factory...</option>
                                    {factorydata.map(data => (
                                        <option key={data.factorycode} value={data.factorycode}>{data.factoryname} </option>
                                    ))}
                                </select>
                                &nbsp;
                            </div>
                            <div className="mybtncontainer">
                                <button className="mybtn" onClick={handlePDFDownload}><AiOutlineFilePdf className="btn-icon" />PDF</button>
                                <button className="mybtn" onClick={handleExportToExcel}><AiOutlineFileExcel className="btn-icon" />Excel</button>
                                <button className="mybtn" onClick={handleExportToJSON}><AiOutlineFileText className="btn-icon" />JSON</button>
                                <button className="mybtn" onClick={handlePrint}><AiOutlinePrinter className="btn-icon" />Print</button>
                                <button className="mybtn" onClick={handleAddNew}><AiOutlinePlus className='btn-icon' />Addnew</button>
                            </div>
                        </div>

                        <div className="lookupTable" >
                            <table className="tabledata" id="stock-table" ref={tableRef}>
                                <thead className='tablehead'  >
                                    <tr style={{position: 'sticky', top: '-1px'}}>
                                        <th style={{backgroundColor:"black"}}
                                        className="col-tdate" onClick={() => handleSort('factoryname')}>
                                            Date
                                        </th>

                                        <th style={{backgroundColor:"black"}}
                                        className="col-pdtserial"  onClick={() => handleSort('pdtserial')}>
                                            Serial
                                        </th>
                                        <th style={{backgroundColor:"black"}} className="col-pdtname">
                                            Product Name
                                        </th>
                                        <th style={{backgroundColor:"black"}} className="col-nofcase">
                                            Case(s)
                                        </th>

                                        <th style={{backgroundColor:"black"}} className="col-casecontent">
                                            Content
                                        </th>
                                        <th style={{backgroundColor:"black"}} className="col-qty">
                                            Qty.
                                        </th>
                                        <th style={{backgroundColor:"black"}} className="col-uofm">
                                            UOFM
                                        </th>

                                        <th style={{backgroundColor:"black"}} className="col-action" >Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {curdata.map(data => (
                                        <tr className="row-data" key={data.recno} onClick={() => handleRowClick(data)} onDoubleClick={() => handleEdit(data)}>
                                            <td className="col-tdate" >{data.trndate}</td>
                                            <td className="col-pdtserial" >{data.pdtserial}</td>
                                            <td className="col-pdtname" >{data.pdtname}</td>
                                            <td className="col-nofcase" >{data.nofcase}</td>
                                            <td className="col-casecontent" >{data.casecontent}</td>
                                            <td className="col-qty" >{data.qty}</td>
                                            <td className="col-uofm" >{data.uofm}</td>
                                            <td className="col-action">
                                                <div className='col-action-div'>
                                                <button onClick={() => handleEdit(data)}>Edit</button>
                                                <button onClick={() => handleDelete(data)}>Delete</button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabledata-footer' >
                            <label className="col-tdate"></label>
                            <label className="col-pdtserial" ></label>
                            <label className="col-pdtname">Total Case(s) ==>></label>
                            <lebel className="col-nofcase">{totalNofCase}</lebel>
                        </div>
                    </div>
                </div>

                <div className="rightpanel">
                    
                    <div className="right-container">
                        <div className="input-row">
                            <label> Date :</label>
                            <InputMask
                                readOnly = { trntype===1 && initialData.length>0 ? true : false }
                                tabIndex={ trntype===1 && initialData.length>0 ? -1 : 0 }
                                ref={dateRef}
                                id={ "date" }
                                mask="99/99/99"
                                value={tdate}
                                onChange={handleDateChange}
                                placeholder="DD/MM/YY"
                                style={{ width: "65px", paddingLeft: "4px", paddingRight: "4px" }}
                                className="date-input"
                                maskChar=""
                            />
                        </div>
                        <div className="input-row">
                            <label> Product Serial :</label>
                            <input type="number" onChange={handlepdtSerialChange}  value={pdtserial} ref={pdtserialRef}
                               onDoubleClick={ onDoubleClickpdtserial}
                               onKeyDown={(e) => {
                                    if (e.key === 'e' || e.key === 'E') {
                                        e.preventDefault();
                                    }
                                }} />
                                {isModalOpen && (
                                    <ProductMasterList 
                                        closeModal={closeModal} 
                                        onRowDoubleClick={handleRowDoubleClick}
                                    />
                                )}
                        </div>
                        <div className="input-row">
                            <label>Product Name : </label>
                            <input type="text" readOnly
                                style={{ width: '200px' }} tabIndex={-1} value={pdtdata.pdtname} />
                        </div>
                        <div className="input-row">
                            <label>No. of Cases : </label>
                            <input type="number"
                                ref={nofcaseRef}
                                onChange={handlenofcaseChange}
                                value={nofcase}
                                onBlur={handlenofcaseonBlur}
                            />
                        </div>
                        <div className="input-row">
                            <label>Case Content : </label>
                            <input type="number"
                                onKeyDown={(e) => {
                                    if (e.key === 'e' || e.key === 'E') {
                                        e.preventDefault();
                                    }
                                }}
                                ref={casecontentRef}
                                onChange={handleChangeCaseContent}
                                value={pdtdata.casecontent}
                                readOnly={!pdtcase.casecontentYN}
                                tabIndex={pdtcase.casecontentYN ? 0 : -1} />
                                <label>&nbsp;&nbsp;{pdtdata.uofm}</label>
                        </div>
                        <div className="input-row">
                            <label> Qty:</label>
                            <input type="number" ref={qtyRef}
                                onKeyDown={(e) => {
                                    if (e.key === 'e' || e.key === 'E') {
                                        e.preventDefault();
                                    }
                                }}
                                value={qty}
                                onChange={handleChangeQty}
                                readOnly={!pdtcase.qtyYN}
                                tabIndex={pdtcase.qtyYN ? 0 : -1} />
                                <label>&nbsp;&nbsp;{pdtdata.uofm}</label>
                        </div>
                        { trntype===8 && 
                            < div className="input-row">
                                <label>Factory To:</label>
                                <select name="factoryname" id="factoryname" style={{ width: '210px' }} value={selectedfactory2.factorycode} onChange={handleFactory2Change} ref={factory2nameRef} required  >
                                    <option value="" disabled selected>Select Factory...</option>
                                    {factorydata.map(data => (
                                        <option key={data.factorycode} value={data.factorycode}>{data.factoryname} </option>
                                    ))}
                                </select>
                            </div>
                        }
                    </div>
                    <div className="btngroup">
                        <button className="btn" >Cancel</button>
                        <button className="btn" ref={defaultEnterRef} onClick={()=>handleSave()}>Save</button>
                    </div>
                </div>
            </div>
        </div>

    );

};
export default StockEntry;
