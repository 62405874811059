import "./stockplace.css"
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as u from "../utils/Common";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const Stockplace = () => {
    const myNavigate = useNavigate();


    const factorynameRef = useRef('');
    const [factorynameValue, setfactorynameValue] = useState('');

    
    const [companydata, setcompanydata] = useState([]);
    const [selectedcompany, setselectedcompany] = useState();
    const companynameRef = useRef('');

    const handlecompanyChange = (event) => {
        const { value } = event.target;
        setselectedcompany(u.myInt(value));
    };

    useEffect(() => {
        const fetchcompanydata = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/company_readall`)
                setcompanydata(response.data);
            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchcompanydata();
    }, []);

    const curmode = useSelector(function (data) {
        return data.factorymode;
    })    

    let url = '';

    useEffect(() => {
        factorynameRef.current.focus();
    }, []);

    const handlefactorynamechange = (event) => {
        const { value } = event.target;
        setfactorynameValue(u.toProperCase(value));
    };

    useEffect(() => {
        const fetchdata = async (e) => {
            const data = { factorycode: curmode[1] };
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/store_readbyfactorycode`, data)
            if (response.data.length > 0) {
                setfactorynameValue(response.data[0].factoryname)
                companynameRef.current.value=u.myInt(response.data[0].companycode);
            }
        };
        if (curmode[0] === "Edit") {
            fetchdata();
        }
    }, [curmode])

    const handleSave = async (e) => {

        let factoryname = u.myTrim(factorynameRef.current.value);
        if (factoryname === "") {
            alert("Factory Name Should be Given");
            factorynameRef.current.focus()
            return;
        }
        let companycode = u.myInt(companynameRef.current.value);
        if (!companycode>0) {
            alert("Company Name Should be Given");
            companynameRef.current.focus()
            return;
        }
      
        try {
            const data= {
                factorycode: (curmode[0] === "New" ? 0 : curmode[1]),
                factoryname: factoryname,
                companycode:companynameRef.current.value
              
              
            };
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/store_readfactoryname`,  data );
            if (response.data.length>0) {
              alert('Factory Name already Given ')
              return;
            }
          } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error (show error message, etc.)
          }
      

        try {
            const data = {
                factorycode: (curmode[0] === "New" ? 0 : curmode[1]),
                factoryname: factoryname,
                companycode:companynameRef.current.value,

            };
            console.log(data);
            url = `${process.env.REACT_APP_RAINBOW_URL}/store_create`
            if (curmode[0] === "Edit") {
                url = `${process.env.REACT_APP_RAINBOW_URL}/store_update`
            }
            await axios.post(url, data);

            if (curmode[0] === "Edit") {
                myNavigate("/liststockplace")
                return;
            }
            factorynameRef.current.value = "";
            setselectedcompany(0);
            factorynameRef.current.focus();

        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
  
    const defaultEnterRef = useRef(null)
    const defaultEscapeRef = useRef(null)

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if ((event.key === 'Enter') || (event.ctrlKey && event.key === 'a')) {
                defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                defaultEscapeRef.current.click();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <>
            <div className="frmstockplace-divmain" >
                <div className="frmstockplace-main">
                    <div className="frmstockplace-container-page">
                        <div className="frmstockplace-header">
                            <h3>Stock Place</h3>
                        </div>
                        <div className="frmstockplace-container">
                            <div className="frmstockplace-panel">
                                <div className="frmstockplace-group">
                                    <label for="factoryname" className="frmstockplace-label required"> Name</label>
                                    <input type="text" id="factoryname" value={factorynameValue} onChange={handlefactorynamechange} className="frmstockplace-input" required autofocus maxlength="75" ref={factorynameRef} />
                                </div>

                                <div className="frmstockplace-group">
                                    <label className="required"> Company</label>
                                    <select name="company" id="company" value={selectedcompany} onChange={handlecompanyChange} ref={companynameRef} required  >
                                        <option value={0} disabled selected>Select company...</option>
                                        {companydata.map(data => (
                                            <option key={data.actcode} value={data.actcode}>{data.actname} </option>
                                        ))}
                                    </select>
                            </div>
                               
                                <div className="frmstockplace-btngroup">
                                    <button className='frmstockplace-btn' ref={defaultEscapeRef} onClick={() => myNavigate("/liststockplace")}> Cancel</button>
                                    <button className='frmstockplace-btn' ref={defaultEnterRef} onClick={() => handleSave()} >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Stockplace;
