import "./ChequePrinting.css";
import frmChequeBackGroundImage from '../../assets/cheque print.jpeg';
import { useRef, useState, useEffect } from "react";
import { NumericFormat } from 'react-number-format';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from "axios";
import { FaHistory, FaFilePdf } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import CreatableSelect from "react-select/creatable";
import * as u from "../utils/Common";
import { useSelector } from 'react-redux';

const toWords = require('num-words');

const ChequePrinting = () => {
    const navigate = useNavigate();

    const userid=useSelector(function (data) {
        return data.userid;
    })

    const licensed=useSelector(function (data) {
        return data.licensed;
    })

    const datelocationxRef = useRef('');
    const datelocationyRef = useRef('');

    const nameparticularslocationxRef = useRef('');
    const nameparticularslocationyRef = useRef('');
    const nameparticularswidthRef = useRef('');

    const rupees1stlinelocationxRef = useRef('');
    const rupees1stlinelocationyRef = useRef('');
    const rupees1stlinewidthRef = useRef('');

    const rupees2ndlinelocationxRef = useRef('');
    const rupees2ndlinelocationyRef = useRef('');
    const rupees2ndlinewidthRef = useRef('');

    const rupeesinnumberslocationxRef = useRef('');
    const rupeesinnumberslocationyRef = useRef('');
    const rupeesinnumberswidthRef = useRef('');

    const acpaylocationxRef = useRef('');
    const acpaylocationyRef = useRef('');

    const chequeparticularsRef = useRef('');
    const rupeesRef = useRef('');
    const inputDateRef = useRef();

    const [rupees, setRupees] = useState('');
    const [inputdate, setInputDate] = useState(u.curdate(false));
    const [particulars, setParticulars] = useState({});

    const [chequedata, setChequeData] = useState([]);
    const [curdata, setCurData] = useState({});
    const [showmodal, setshowmodal] = useState(false);
    const banklist = ["UJJIVAN Small Finance Bank",
        "HDFC Bank", "ICICI Bank",
        "Karur Vysya Bank",
        "Union Bank of India",
        "Canara Bank",
        "Punjab National Bank",
        "Tamilnad Mercantile Bank Ltd.",
        "Axis Bank", "Central Bank of India",
        "IndusInd Bank",
        "Bank of India",
        "State Bank of India",
        "IDBI Bank"];

    const defdata = {
        datex: 157,
        datey: 11,
        datefs: 12,
        datebold: true,
        datecs: 5,
        datenofdigitsinyear: 4,

        namex: 17,
        namey: 22,
        namefs: 12,
        namebold: false,
        namewidth: 125,
        namewithstar: true,

        rs1x: 31,
        rs1y: 31,
        rs1fs: 12,
        rs1bold: false,
        rs1width: 160,
        rs1withstar: true,

        rs2x: 10,
        rs2y: 39,
        rs2width: 115,

        rsnumberx: 157,
        rsnumbery: 39,
        rsnumberfs: 14,
        rsnumberbold: false,
        rsnumberwidth: 24,
        rsnumberalignment: 'Left',
        rsnumberwithstar: true,

        acpay: true,
        acpayx: 100,
        acpayy: 8,
        acpayfs: 12,
        acpaybold: false
    }

    const fetchCreditorsdata = async () => {
        const xuserdata = { userid : userid }
        try {
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/chequecreditors_read`,xuserdata);
            const data = response.data.map((data) => ({
                value: data, label: data
            }))
            setOptions(data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    useEffect(() => {
        fetchCreditorsdata();
    }, []);

    const [chequehistorydata, setChequeHistorydata] = useState([]);
    const fetchChequeHistorydata = async () => {
        const xuserdata = { userid : userid }
        try {
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/chequehistory_read`,xuserdata);
            setChequeHistorydata(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    useEffect(() => {
        fetchChequeHistorydata();
    }, [showmodal]);

    const fetchchequedata = async () => {
        try {
            if (chequedata.length > 0) {
                return;
            }
            const xuserdata = { userid : userid }
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/getChequeData`,xuserdata);
            const initialdata = [];
            if (response.data.length === 0) {
                banklist.forEach((bank) => {
                    const data = Object.assign({}, { bankname: bank }, defdata);
                    initialdata.push(data);
                })
                setChequeData(initialdata);
                setCurData(initialdata[0])
                const xuserdata={ userid:userid, chequeData:initialdata }
                await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/saveChequeData`, xuserdata);
            }
            else {
                setChequeData(response.data);
                setCurData(response.data[0])
            }
        } catch (error) {
            console.error('There was an error fetching the  data!', error);
        }
    };

    useEffect(() => {
        fetchchequedata();
    });

    const handleDateChange = (e) => {
        const input = e.target.value;
        const formattedDate = formatDate(input);
        setInputDate(formattedDate);
    };

    const formatDate = (value) => {
        let dateValue = value.replace(/[^\d]/g, '');
        // Format the date as DD/MM/YYYY
        if (dateValue.length >= 2) {
            dateValue = dateValue.slice(0, 2) + '/' + dateValue.slice(2);
        }
        if (dateValue.length >= 5) {
            dateValue = dateValue.slice(0, 5) + '/' + dateValue.slice(5, 9);
        }
        return dateValue;
    };

    const isValidDate = (dateStr) => {
        const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
        return regex.test(dateStr);
    };

    const handleRsInAmountChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length > 14) {
            return;
        }
        if (validateDecimal(inputValue)) {
            setRupees(inputValue);
        }
    };

    const validateDecimal = (inputValue) => {
        // Regex to match a number with up to 2 decimal places
        const regex = /^\d*\.?\d{0,2}$/;
        return regex.test(inputValue);
    };

    const toProperCase = (str) => {
        const wordsAndDelimiters = str.split(/([,./\s-&;:])/);
        return wordsAndDelimiters
            .map((wordOrDelimiter) => {
                if (/[a-z]/.test(wordOrDelimiter)) {
                    return wordOrDelimiter.charAt(0).toUpperCase() + wordOrDelimiter.slice(1);
                }
                return wordOrDelimiter;
            })
            .join("");

    };

    const handleValidation = async () => {
        if(licensed === 'D'){
            fetchChequeHistorydata();
            console.log(chequehistorydata);
            if( chequehistorydata.length > 3 ){
                alert("You are in Trial Version");
                return;
            }
        }
        const validated = formvalidation;
        if (validated === false) {
            return;
        }

        if (!isValidDate(inputdate)) {
            alert('Invalid date format. Please use DD/MM/YYYY');
            inputDateRef.current.focus()
            return;
        }

        let newValue;
        newValue = particulars;
        if (!newValue || !newValue.value) {
            alert("Name Particulars Should be Given");
            chequeparticularsRef.current.focus()
            return;
        }

        newValue = rupees;
        if (!parseFloat(newValue) > 0) {
            alert("Enter Amount");
            rupeesRef.current.focus()
            return;
        }

        if ((parseFloat(newValue) > 999999999)) {
            alert("Amount is Too Large. Only Accept upto 99Cr");
            rupeesRef.current.focus();
            return;
        }

        const xuserdata= { userid:userid,chequeData : { 
                trndate: getCurdate(),
                bankname: curdata.bankname,
                chequedate: inputdate,
                particulars: toProperCase(particulars.label),
                amount: rupees  }};
                
        await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/chequehistory_update`,xuserdata );
        
        handlePrint()
    };

    const getCurdate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0'); // Day (dd)
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month (MM), getMonth() is zero-based
        const year = date.getFullYear(); // Year (YYYY)

        return `${day}/${month}/${year}`;
    };

    const formvalidation = () => {
        let newValue = datelocationxRef.current.value;

        if (newValue === '' || newValue === 0 || newValue > 220) {
            alert('Value must be between 1 and 136 ');
            datelocationxRef.current.focus()
            return false;
        }

        newValue = datelocationyRef.current.value;
        if (newValue === '' || newValue === 0 || newValue > 100) {
            alert('Value must be between 1 and 64');
            datelocationyRef.current.focus()
            return false;
        }


        newValue = nameparticularslocationxRef.current.value;
        if (newValue === '' || newValue === 0 || newValue > 136) {
            alert('Value must be between 1 and 136 ');
            nameparticularslocationxRef.current.focus()
            return false;
        }

        newValue = nameparticularslocationyRef.current.value;
        if (newValue === '' || newValue === 0 || newValue > 64) {
            alert('Value must be between 1 and 64');
            nameparticularslocationyRef.current.focus()
            return false;
        }

        newValue = rupees1stlinelocationxRef.current.value;
        if (newValue === '' || newValue === 0 || newValue > 136) {
            alert('Value must be between 1 and 136 ');
            rupees1stlinelocationxRef.current.focus()
            return false;
        }

        newValue = rupees1stlinelocationyRef.current.value;
        if (newValue === '' || newValue === 0 || newValue > 64) {
            alert('Value must be between 1 and 64');
            rupees1stlinelocationyRef.current.focus()
            return false;
        }

        newValue = rupeesinnumberslocationxRef.current.value;
        if (newValue === '' || newValue === 0 || newValue > 220) {
            alert('Value must be between 1 and 220 ');
            rupeesinnumberslocationxRef.current.focus()
            return false;
        }

        newValue = rupeesinnumberslocationyRef.current.value;
        if (newValue === '' || newValue === 0 || newValue > 100) {
            alert('Value must be between 1 and 100');
            rupeesinnumberslocationyRef.current.focus()
            return false;
        }

        newValue = acpaylocationxRef.current.value;
        if (newValue === '' || newValue === 0 || newValue > 136) {
            alert('Value must be between 1 and 136 ');
            acpaylocationxRef.current.focus()
            return false;
        }

        newValue = acpaylocationyRef.current.value;
        if (newValue === '' || newValue === 0 || newValue > 64) {
            alert('Value must be between 1 and 64');
            acpaylocationyRef.current.focus()
            return false;
        }
        return true;

    }


    function numberToRupees() {
        const num = parseFloat(rupeesRef.current.value);
        const rsPart = Math.floor(num);
        const psPart = Math.round((num - rsPart) * 100);

        // eslint-disable-next-line no-template-curly-in-string
        let words = `${toWords(rsPart)} Rupees`;
        if (psPart > 0) {
            // eslint-disable-next-line no-template-curly-in-string
            words += ` and ${toWords(psPart)} Paise`;
        }
        words = toProperCase(words);
        return words;
    };

    const generateOptions = (starti = 0, endi = 50, stepi = 1) => {
        const options = [];
        for (let i = starti; i <= endi; i += stepi) {
            options.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }
        return options;
    };

    const handlePrint = () => {
        const pdf = new jsPDF('landscape', 'mm', 'a4');
        // const pagewidth = 210;
        // const pageheight = 297;

        //1 pt=  1.5875mm 
        const topmargin = 59; // 35pt
        const leftmargin = 93; //60pt

        const wordWrapText = (text, fontSize, maxWidth, doc) => {
            const lines = [];
            const words = text.split(' ');
            let currentLine = '';
            let currentLineWidth = 0;
            let maxWidthinpx = maxWidth * 2.385;

            words.forEach((word) => {
                const wordWidth = doc.getStringUnitWidth(word) * fontSize;
                const spaceWidth = doc.getStringUnitWidth(' ') * fontSize;

                if (currentLineWidth + wordWidth <= maxWidthinpx) {
                    currentLine += (currentLine ? ' ' : '') + word;
                    currentLineWidth += wordWidth + spaceWidth;
                } else {
                    lines.push(currentLine);
                    currentLine = word;
                    currentLineWidth = wordWidth + spaceWidth;
                }
            });

            if (currentLine) {
                lines.push(currentLine);
            }

            return lines;
        };



        const curx = (x) => {
            return ((x) + leftmargin);
        }
        const cury = (y) => {
            return ((y) + topmargin);
        }
        let xfs = parseInt(curdata.datefs);
        if (xfs < 8) {
            xfs = 8;
        }
        let xstr = inputdate.split('/').join('');
        if (parseInt(curdata.datenofdigitsinyear) === 2) {
            xstr = xstr.slice(0, 4) + xstr.slice(6);
        }

        let xint = parseInt(curdata.datecs);
        let xcurx = curx(parseInt(curdata.datex));
        let xcury = cury(parseInt(curdata.datey));

        pdf.setFontSize(xfs);
        for (let i = 0; i < xstr.length; i++) {
            pdf.text(xstr[i], xcurx, xcury);
            xcurx += xint;
        }

        xfs = parseInt(curdata.acpayfs);
        if (xfs < 8) {
            xfs = 8;
        }
        xstr = "A/c Pay";
        xcurx = curx(parseInt(curdata.acpayx));
        xcury = cury(parseInt(curdata.acpayy));
        pdf.setFontSize(xfs);
        pdf.text(xstr, xcurx, xcury);

        let xstrlist = [];
        xfs = parseInt(curdata.namefs);
        if (xfs < 8) {
            xfs = 8;
        }
        xstr = toProperCase(particulars.label);
        if (curdata.namewithstar) {
            xstr = "**" + xstr + "**";
        }
        xstrlist = wordWrapText(xstr, xfs, curdata.namewidth, pdf)
        if (xstrlist.length > 1) {
            xstr = xstrlist[0];
        }
        xcurx = curx(parseInt(curdata.namex));
        xcury = cury(parseInt(curdata.namey));
        pdf.setFontSize(xfs);
        pdf.text(xstr, xcurx, xcury);

        xfs = parseInt(curdata.rs1fs);
        if (xfs < 8) {
            xfs = 8;
        }

        xstr = numberToRupees();
        if (curdata.rs1withstar) {
            xstr = "**" + xstr + "**";
        }
        xstrlist = wordWrapText(xstr, xfs, curdata.rs1width, pdf)
        if (xstrlist.length > 1) {
            xstr = xstrlist[0];
        }
        xcurx = curx(parseInt(curdata.rs1x));
        xcury = cury(parseInt(curdata.rs1y));
        pdf.setFontSize(xfs);
        pdf.text(xstr, xcurx, xcury);
        if (xstrlist.length > 1) {
            xstr = xstrlist[1];
            xstrlist = wordWrapText(xstr, xfs, curdata.rs2width, pdf)
            if (xstrlist.length > 1) {
                xstr = xstrlist[0];
            }
            xcurx = curx(parseInt(curdata.rs2x));
            xcury = cury(parseInt(curdata.rs2y));
            pdf.setFontSize(xfs);
            pdf.text(xstr, xcurx, xcury);
        }

        xfs = parseInt(curdata.rsnumberfs);
        if (xfs < 8) {
            xfs = 8;
        }
        xstr = rupees;
        if (curdata.rsnumberwithstar) {
            xstr = "**" + xstr + "**";
        }
        xcurx = curx(parseInt(curdata.rsnumberx));
        xcury = cury(parseInt(curdata.rsnumbery));
        pdf.setFontSize(xfs);
        pdf.text(xstr, xcurx, xcury);

        // pdf.save('cheque.pdf');

        const pdfBlob = pdf.output('blob');
        const blobUrl = URL.createObjectURL(pdfBlob);

        window.open(blobUrl, 'ChequePrintPreview');
        // setParticulars('');
    };

    const handlePDFDownload = () => {
        const doc = new jsPDF();
        doc.autoTable({ html: '#chequehistory-table' });
        doc.save('chequehistory.pdf');
    };


    const handleSaveSettings = async (event) => {
        if (licensed==="D") {
            alert("You Are in Trial Version....")
            return;
        }
        setdisabled(!isdisabled)
        if (isdisabled) {
            return;
        }
        const validated = formvalidation;
        if (validated === false) {
            return;
        }
        const updateddata = [];
        chequedata.map(data => (
            (curdata.bankname === data.bankname) ? updateddata.push(curdata) : updateddata.push(data)
        ))
        setChequeData(updateddata);
        const xuserdata = { userid:userid, chequeData:updateddata }
        const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/saveChequeData`, xuserdata);
        console.log(response);
    }

    const handleSelectChange = (event) => {
        let value = event.target.value;
        const data = chequedata.find(item => item.bankname === value);
        setCurData(data);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCurData((prevFormdata) => ({
            ...prevFormdata,
            [name]: value
        }));
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCurData((prevFormdata) => ({
            ...prevFormdata,
            [name]: checked
        }));
    }

    const [isdisabled, setdisabled] = useState(true);
    const handleCancelSaveSettings = () => {
        setdisabled(true)
        if (curdata.bankname) {
            const data = chequedata.find(item => item.bankname === curdata.bankname);
            setCurData(data);
        }
    }

    const handleAddBank = async() => {
        if (licensed==="D") {
            alert("You Are in Trial Version....")
            return;
        }
        Swal.fire({
            title: 'Enter a New Bank Name',
            input: 'text',
            inputPlaceholder: 'Bank Name...',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to write something!';
                }
                return null;
            },
        }).then((result) => {
            if (result.isConfirmed) {
                if (!chequedata.find(item => item.bankname === result.value)) {
                    const data = Object.assign({}, { bankname: result.value }, defdata);
                    let copieddata = chequedata.map(item => ({ ...item }));
                    copieddata.push(data);
                    const xuserdata={ userid:userid,chequeData:copieddata }
                    axios.post(`${process.env.REACT_APP_RAINBOW_URL}/saveChequeData`, xuserdata);
                    setChequeData(prevItems => [...prevItems, data]);
                }
                Swal.fire('Bank : ', result.value, 'success');
            }
        });
    };

    const handleRemoveBank = async () => {
        alert(curdata.bankname)
        const updateddata = chequedata.filter(item => item.bankname !== curdata.bankname);
        const xuserdata = { userid:userid, chequeData : updateddata }
        await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/saveChequeData`, xuserdata);
        setChequeData(updateddata)
    };

    const customComponent = {
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
    }

    const customStyles = {
        control: (base) => ({
            ...base,
            height: '25px',
            minHeight: '25px', // Adjust the minimum height of the control
            padding: '0px', // Remove default padding
            width: '450px',
            marginLeft: "5px",
            marginRight: '10px',
        }),
        input: (base) => ({
            ...base,
            height: '25px',
            minHeight: '25px',
            paddingTop: '0px', // Remove padding inside the input box
            padddingBottom: '0px',
            margin: '0px',
            color: "black", // Remove margin inside the input box
            "input": { textTransform: "capitalize" }
        }),
        singleValue: (base) => ({
            ...base,
            height: '25px',
            minHeight: '25px',
            paddingTop: '0px', // Adjust the top padding of the single value (selected item)
            paddingBottom: '10px', // Adjust the bottom padding of the single value (selected item)
            textTransform: "capitalize"
        }),
        placeholder: (base) => ({
            ...base,
            height: '25px',
            minHeight: '25px',
            paddingTop: '0px', // Adjust the top padding for the placeholder text
            paddingBottom: '0px', // Adjust the bottom padding for the placeholder text
        }),
        menu: (base) => ({
            ...base,
            marginTop: '0px', // Adjust the margin top for the dropdown menu
            color: "black",
            maxHeight: '150px',  // Set the maximum height for the dropdown menu
            overflowY: 'auto',   // Enable vertical scrolling when content exceeds maxHeight
            zIndex: 9999,
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: '150px',  // Set the maximum height for the menu list as well
            padding: 0,          // Remove default padding that might affect scroll
            overflowY: 'auto',    // Set the maximum height for the menu list as well
        }),
        dropdownIndicator: (base) => ({
            ...base,
            height: '25px',
            minHeight: '25px',

        }),
    };
    const [options, setOptions] = useState([]);
    const handleCreate = (inputValue) => {
        const newOption = { value: u.toProperCase(inputValue), label: u.toProperCase(inputValue) };
        const optionExists = options.some(
            (option) => option.value === newOption.value
        );
        if (!optionExists) {
            setOptions((prevOptions) => [...prevOptions, newOption]);
        }
        setParticulars(newOption);
    };
    const handleParticularsChange = (input) => {
        setParticulars(input);
    };

    return (
        <div className="divmain">
            <div className="frmcheque-main">
                <div class="frmcheque-firstgroup">
                    <div class="frmcheque-bankname">
                        <label for="name"
                            style={{ width: "100px" }}>Bank Name
                        </label>
                        <select name="blist" id="blist" style={{ width: "400px" }} onChange={handleSelectChange} disabled={!isdisabled} >
                            {chequedata.map(data => (
                                <option key={data.bankname}
                                    value={data.bankname}>{data.bankname}
                                </option>
                            ))}
                        </select>
                    </div>
                    <h3 style={{ width: "300px", color: "white" }}>Cheque Slip Printing</h3>
                    <div class="frmcheque-btngroup">
                        {isdisabled && 
                            <button onClick={() => handleAddBank()} >Add Bank</button>
                        }

                        {isdisabled &&
                            <button onClick={() => handleRemoveBank()}>Remove Bank</button>
                        }

                        {isdisabled === false &&
                            <button onClick={() => handleCancelSaveSettings()}>Cancel Edit</button>
                        }
                        <button onClick={() => handleSaveSettings()}>{isdisabled ? 'Edit Settings' : 'Save Settings'}</button>
                        {isdisabled && (licensed!=="D") && 
                            <FaHistory size={20} onClick={() => setshowmodal(true)} />
                        }
                    </div>
                </div>
                {showmodal &&
                    <div className="cheque-modal">
                        <div className="cheque-history-container">
                            <div className="modalcheque-topgrp">
                                <h3>Cheque History</h3>
                                <div className="modalcheque-btngrp">
                                    <FaFilePdf size={20} color="red" onClick={handlePDFDownload} />
                                    <AiOutlineClose size={20} style={{ backgroundColor: "red", color: "white" }} onClick={() => setshowmodal(false)} />
                                </div>

                            </div>
                            <div className="chequelookup-table">
                                <table className="cheque-table" id="chequehistory-table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "10%" }}>Date</th>
                                            <th style={{ width: "35%" }}>Bank Name</th>
                                            <th style={{ width: "10%" }}>Chq.Date</th>
                                            <th style={{ width: "35%" }}>Particulars</th>
                                            <th style={{ width: "10%" }}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chequehistorydata.map((data, index) => (

                                            <tr key={index}>
                                                <td>{data.trndate}</td>
                                                <td>{data.bankname}</td>
                                                <td>{data.chequedate}</td>
                                                <td>{data.particulars}</td>
                                                <td style={{ textAlign: "right" }}>{data.amount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }

                <div class="frmcheque-container">
                    <fieldset style={{ width: "100%", border: "2px solid whitesmoke", marginTop: "8px", marginBottom: "8px" }} disabled={isdisabled}>
                        <div class="frmcheque-date1">
                            <p>Date:</p>
                        </div>
                        <div class="frmcheque-date">
                            <div className="frmcheque-location">
                                <label for="location" >Location (x, y)</label>
                                <div className="frmcheque-location-xy">
                                    <NumericFormat
                                        thousandSeparator={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        ref={datelocationxRef} name='datex' value={curdata.datex} onChange={handleChange}
                                        maxLength={3}
                                    />
                                    <NumericFormat
                                        thousandSeparator={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        ref={datelocationyRef} name='datey' value={curdata.datey} onChange={handleChange}
                                        maxLength={2}
                                    />

                                </div>
                            </div>

                            <div className="frmcheque-fontselect">
                                <label for="fsize">Font</label>
                                <select id="numberSelect" style={{ width: "60px" }} name='datefs' value={curdata.datefs} onChange={handleChange}>
                                    {generateOptions(8, 26, 1)}
                                </select>
                            </div>
                            <div className="frmcheque-boldcheck">
                                <label for="bold">Bold</label>
                                <input type="checkbox" name='datebold' checked={curdata.datebold} onChange={handleCheckboxChange} />
                            </div>
                            <div className="frmcheque-cherspace">
                                <label for="space">Character Space</label>
                                <select id="dspace" style={{ width: "50px" }} name='datecs' value={curdata.datecs} onChange={handleChange}>
                                    {generateOptions(0, 10, 1)}
                                </select>
                            </div>
                            <div className="frmcheque-nofdigits">
                                <label for="space">No.of Digits(Year)</label>
                                <select id="datenofdigitsinyear" style={{ width: "50px" }} name='datenofdigitsinyear' value={curdata.datenofdigitsinyear} onChange={handleChange}>
                                    <option value={2}>2</option>
                                    <option value={4}>4</option>
                                </select>
                            </div>
                        </div>

                        <div class="frmcheque-date1">
                            <p>Name Particulars:</p>
                        </div>
                        <div class="frmcheque-date">
                            <div className="frmcheque-location">
                                <label for="location">Location (x, y)</label>
                                <div className="frmcheque-location-xy">
                                    <NumericFormat
                                        thousandSeparator={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        ref={nameparticularslocationxRef} name='namex' value={curdata.namex} onChange={handleChange}
                                        maxLength={3}
                                    />
                                    <NumericFormat
                                        thousandSeparator={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        ref={nameparticularslocationyRef} name='namey' value={curdata.namey} onChange={handleChange}
                                        maxLength={2}
                                    />

                                </div>
                            </div>

                            <div className="frmcheque-fontselect">
                                <label for="fsize">Font</label>
                                <select id="numberSelect" style={{ width: "60px" }} name='namefs' value={curdata.namefs} onChange={handleChange}>
                                    {generateOptions(8, 26, 1)}
                                </select>
                            </div>
                            <div className="frmcheque-boldcheck">
                                <label for="bold">Bold</label>
                                <input type="checkbox" name='namebold' checked={curdata.namebold} onChange={handleCheckboxChange} />
                            </div>
                            <div className="frmcheque-starcheck">
                                <label for="namewithstar">With **</label>
                                <input type="checkbox" name='namewithstar' checked={curdata.namewithstar} onChange={handleCheckboxChange} />
                            </div>
                            <div className="frmcheque-width">
                                <label for="width">Width</label>
                                <NumericFormat
                                    thousandSeparator={false}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    ref={nameparticularswidthRef} name='namewidth' value={curdata.namewidth} onChange={handleChange}
                                    maxLength={3}
                                />
                            </div>
                            <img src={frmChequeBackGroundImage} alt="" />
                        </div>


                        <div class="frmcheque-date1">
                            <p>Rupees in words:</p>
                        </div>
                        <div class="frmcheque-1stline">
                            <p>1st line</p>
                        </div>
                        <div class="frmcheque-date">
                            <div className="frmcheque-location">
                                <label for="location">Location (x, y)</label>
                                <div className="frmcheque-location-xy">
                                    <NumericFormat
                                        thousandSeparator={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        ref={rupees1stlinelocationxRef} name='rs1x' value={curdata.rs1x} onChange={handleChange}
                                        maxLength={3}
                                    />
                                    <NumericFormat
                                        thousandSeparator={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        ref={rupees1stlinelocationyRef} name='rs1y' value={curdata.rs1y} onChange={handleChange}
                                        maxLength={2}
                                    />
                                </div>
                            </div>

                            <div className="frmcheque-fontselect">
                                <label for="fsize">Font</label>
                                <select id="numberSelect" style={{ width: "60px" }} name='rs1fs' value={curdata.rs1fs} onChange={handleChange}>
                                    {generateOptions(8, 26, 1)}
                                </select>
                            </div>
                            <div className="frmcheque-boldcheck">
                                <label for="bold">Bold</label>
                                <input type="checkbox" name='rs1bold' checked={curdata.rs1bold} onChange={handleCheckboxChange} />
                            </div>
                            <div className="frmcheque-starcheck">
                                <label for="rs1withstar">With **</label>
                                <input type="checkbox" name='rs1withstar' checked={curdata.rs1withstar} onChange={handleCheckboxChange} />
                            </div>
                            <div className="frmcheque-width">
                                <label for="width">Width</label>
                                <NumericFormat
                                    thousandSeparator={false}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    ref={rupees1stlinewidthRef} name='rs1width' value={curdata.rs1width} onChange={handleChange}
                                    maxLength={3}
                                />
                            </div>

                        </div>
                        <div class="frmcheque-1stline">
                            <p>2nd line</p>
                        </div>
                        <div class="frmcheque-date">
                            <div className="frmcheque-location">
                                <label for="location">Location (x, y)</label>
                                <div className="frmcheque-location-xy">
                                    <NumericFormat
                                        thousandSeparator={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        ref={rupees2ndlinelocationxRef} name='rs2x' value={curdata.rs2x} onChange={handleChange}
                                        maxLength={3}
                                    />
                                    <NumericFormat
                                        thousandSeparator={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        ref={rupees2ndlinelocationyRef} name='rs2y' value={curdata.rs2y} onChange={handleChange}
                                        maxLength={2}
                                    />
                                </div>
                            </div>

                            <div className="frmcheque-fontselect">
                                <label class="frmcheque-fsize">Font</label>
                                <select id="numberSelect" class="frmcheque-fsize" style={{ width: "60px" }} name='rs2fs' value={curdata.rs2fs} onChange={handleChange} >
                                    {generateOptions(8, 26, 1)}
                                </select>
                            </div>
                            <div className="frmcheque-boldcheck">
                                <label class="frmcheque-fsize">Bold</label>
                                <input type="checkbox" class="frmcheque-fsize" name='rs2bold' checked={curdata.rs2bold} onChange={handleCheckboxChange} />
                            </div>
                            <div className="frmcheque-starcheck">
                            </div>
                            <div className="frmcheque-width">
                                <label for="width">Width</label>
                                <NumericFormat
                                    thousandSeparator={false}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    ref={rupees2ndlinewidthRef} name='rs2width' value={curdata.rs2width} onChange={handleChange}
                                    maxLength={3}
                                />
                            </div>

                        </div>
                        <div class="frmcheque-date1">
                            <p>Rupees in numbers:</p>
                        </div>
                        <div class="frmcheque-date">
                            <div className="frmcheque-location">
                                <label for="location">Location (x, y)</label>
                                <div className="frmcheque-location-xy">
                                    <NumericFormat
                                        thousandSeparator={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        ref={rupeesinnumberslocationxRef} name='rsnumberx' value={curdata.rsnumberx} onChange={handleChange}
                                        maxLength={3}
                                    />
                                    <NumericFormat
                                        thousandSeparator={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        ref={rupeesinnumberslocationyRef} name='rsnumbery' value={curdata.rsnumbery} onChange={handleChange}
                                        maxLength={2}
                                    />
                                </div>
                            </div>

                            <div className="frmcheque-fontselect">
                                <label for="fsize">Font</label>
                                <select id="numberSelect" style={{ width: "60px" }} name='rsnumberfs' value={curdata.rsnumberfs} onChange={handleChange}>
                                    {generateOptions(8, 26, 1)}
                                </select>
                            </div>
                            <div className="frmcheque-boldcheck">
                                <label for="bold">Bold</label>
                                <input type="checkbox" name='rsnumberbold' checked={curdata.rsnumberbold} onChange={handleCheckboxChange} />
                            </div>

                            <div className="frmcheque-starcheck">
                                <label for="rsnumberwithstar">With **</label>
                                <input type="checkbox" name='rsnumberwithstar' checked={curdata.rsnumberwithstar} onChange={handleCheckboxChange} />
                            </div>
                            <div className="frmcheque-width">
                                <label for="width">Width</label>
                                <NumericFormat
                                    thousandSeparator={false}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    ref={rupeesinnumberswidthRef} name='rsnumberwidth' value={curdata.rsnumberwidth} onChange={handleChange}
                                    maxLength={2}
                                />
                                <label for="alignment">Alignment</label>
                                <select id="alignment" name="rsnumberalignment" onChange={handleChange} style={{ width: "50px" }}>
                                    <option value="Left">Left</option>
                                    <option value="Right">Right</option>
                                </select>
                            </div>

                        </div>

                        <div class="frmcheque-date1">
                            <p>A/c Pay:</p>
                            <input type="checkbox"
                                style={{ marginLeft: "1px" }}
                                id="frmcheque-acpay-checkbox"
                                name='acpay'
                                checked={curdata.acpay}
                                onChange={handleCheckboxChange} />
                        </div>
                        <div class="frmcheque-date">
                            <div className="frmcheque-location">
                                <label for="location">Location (x, y)</label>
                                <div className="frmcheque-location-xy">
                                    <NumericFormat
                                        thousandSeparator={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        ref={acpaylocationxRef} name='acpayx' value={curdata.acpayx} onChange={handleChange}
                                        maxLength={3}
                                    />
                                    <NumericFormat
                                        thousandSeparator={false}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        ref={acpaylocationyRef} name='acpayy' value={curdata.acpayy} onChange={handleChange}
                                        maxLength={2}
                                    />
                                </div>
                            </div>

                            <div className="frmcheque-fontselect">
                                <label for="fsize">Font</label>
                                <select id="numberSelect" style={{ width: "60px" }} name='acpayfs' value={curdata.acpayfs} onChange={handleChange}>
                                    {generateOptions(8, 26, 1)}
                                </select>
                            </div>
                            <div className="frmcheque-boldcheck">
                                <label for="bold">Bold</label>
                                <input type="checkbox" name='acpaybold' checked={curdata.acpaybold} onChange={handleCheckboxChange} />
                            </div>
                            <div className="frmcheque-width">

                            </div>
                            <div className="frmcheque-alignment" >
                                <label > *All Measurements Here Used is in Millimeter(mm)</label>
                                <label > *History Having Last 100 Records Only </label>
                                <label > *Particulars Having Last 100 Records Only </label>
                            </div>
                        </div>
                    </fieldset>

                </div>
                <div class="frmcheque-lastgroup">
                    <div class="frmcheque-last">
                        <div class="frmcheque-ldate">
                            <label for="ldate" style={{ width: "40px" }}>Date</label>
                            <input type="text"
                                autoFocus={true}
                                onFocus={(e) => e.target.select()}
                                ref={inputDateRef}
                                style={{ width: "90px", paddingLeft: "2px" }}
                                value={inputdate}
                                onChange={handleDateChange}
                            />
                        </div>
                        <div class="frmcheque-lparticulars">
                            <label for="particulars" style={{ width: "80px" }}>Particulars</label>
                            <CreatableSelect
                                closeMenuOnSelect={false}
                                ref={chequeparticularsRef}
                                name="particulars"
                                value={particulars}
                                onChange={handleParticularsChange}
                                id="particulars"
                                onCreateOption={handleCreate}
                                options={options} // Set customer options fetched from API
                                placeholder=""
                                styles={customStyles}
                                components={customComponent}
                                menuPlacement="top"
                                tabSelectsValue={true}
                                onKeyDown={(event) => {
                                    if (event.key === 'Tab' && !event.shiftKey) {
                                        event.preventDefault();
                                        const inputValue = event.target.value;
                                        if (inputValue) {
                                            handleCreate(inputValue);
                                        }
                                        setTimeout(() => {
                                            rupeesRef.current.focus();
                                        }, 0);
                                    }

                                    if (event.shiftKey && event.key === 'Tab') {
                                        event.preventDefault();
                                        inputDateRef.current.focus();
                                    }

                                }}
                            />
                        </div>
                        <div class="frmcheque-lrupees">
                            <label for="rupees" style={{ width: "20px" }}>Rs.</label>
                            <input type="number" id="amount"
                                style={{ width: "130px", paddingLeft: "2px" }}
                                value={rupees}
                                onChange={handleRsInAmountChange}
                                ref={rupeesRef}
                            />
                        </div>
                    </div>
                    <div class="frmcheque-btngroup">
                        <button onClick={() => handleValidation()} >Print Preview</button>
                        <button onClick={() => navigate(-1)} >Close</button>
                    </div>
                </div>


            </div>

        </div>



    );
};

export default ChequePrinting;