import React, {  useEffect } from 'react';

import "./emptypage.css";
import { myaction } from "../../src/redux/Mystore"
import { useDispatch } from 'react-redux'

const Emptypage = () => {

  const mydispatch = useDispatch()

  function showmenu() {
      mydispatch(myaction.hideMenu(false))
  }

  useEffect(() => {
    showmenu()
  }, [])
  
  
  return (
    <>
    <div className="mypage"></div>
    </>
  );
};

export default Emptypage;