import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './SearchTaxPayer.css';
import blinking_gif from "../../assets/blinking_new.gif";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const SearchTaxPayer = () => {
  const [gstin, setGstin] = useState('');
  const [details, setDetails] = useState("");
  const [error, setError] = useState(null);
  const GSTIN_REGEX = /^[0-3,8]{1}[0-9]{1}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9,A-Z]{1}[Z]{1}[0-9,A-Z]{1}$/;

  const inputGSTINRef = useRef()
  let lastreadGSTIN = "";

  const navigate = useNavigate();

  function frmClose() {
    navigate(-1)
}

  const fetchGstinDetails = async () => {
    if (!GSTIN_REGEX.test(gstin)) {
      alert("Enter Valid GSTIN")
      inputGSTINRef.current.focus();
      return;
    }
    const data = { gstin: gstin }
    const url = `${process.env.REACT_APP_RAINBOW_URL}/searchtaxpayer`
    try {
      const response = await axios.post(url, data);
      console.log(response.data.result)
      if (response.data.success === false) {
        setError(response.data.message);
        return;
      }
      const jsondata = JSON.parse(response.data.result)
      setDetails(jsondata.data);
      lastreadGSTIN=gstin;
      setError(null);
    } catch (error) {
      lastreadGSTIN=""
      console.log(error.message)
      setError('Failed to fetch GSTIN details');
      setDetails(null);
    }
  }

  const handleKeyPress = (event) => {
    const char = event.key;
    if (char >= 'a' && char <= 'z') {
      event.preventDefault();
      const uppercaseChar = char.toUpperCase();
      setGstin(gstin + uppercaseChar);
    }
  };

  useEffect(() => {
    inputGSTINRef.current.focus();
  }, []);

  const handleGSTINChange = (e) => {
    const value = e.target.value;
    setGstin(value);
    if (value!==lastreadGSTIN) {
      setDetails(null);
    }
  };

  return (
    <>
      <div className='gstin-divmain'>
        <div className="gstin-container">
       
          <div className='gst-search'>
         
            <div className='searchTax'>
            <h2>Search Tax Payer</h2>
              <label htmlFor="inputGSTIN" title='' >GSTIN</label>
              <div className='gstin-input'>
                <input
                  maxLength={15}
                  id="inputGSTIN"
                  type="text"
                  value={gstin}
                  ref={inputGSTINRef}
                  onKeyPress={handleKeyPress}
                  onChange={handleGSTINChange}
                  style={{height:"20px"}}
                />
                <button onClick={fetchGstinDetails} className='btn' ><FaSearch style={{marginTop:"-1px"}}/>&nbsp;Search</button>
                {error && <p className="error">{error}</p>}
                <button className='btn' onClick={frmClose}> Close</button>
              </div>
            </div>

            {details && (
              <div className='gstin-inf'>
                <h2>GSTIN :&nbsp;&nbsp;{gstin}</h2>
                <p>Trade :&nbsp;&nbsp;<strong>{details.tradeNam}</strong></p>
                <div className={details.sts === "Active" ? 'gststatus' : "gststatus gststatus-alert"}>
                  <light>Status :&nbsp;&nbsp;&nbsp;</light>
                  <p className='status'>{details.sts}</p>
                  <img src={blinking_gif} alt="" />
                  <light>&nbsp;&nbsp;&nbsp;  Regn.Type :&nbsp;&nbsp;</light><p className='status'>{details.dty}</p>
                  <light>&nbsp;&nbsp;&nbsp;  eInvoice :&nbsp;&nbsp;</light><p className='status'>{details.einvoiceStatus}</p>                
                </div>
              </div>
            )}

          </div>

          <div className="gstin-details">
            {details && (
              <div className="gstin-main">
                <div className='gstin-offaddress'>
                  {details.adadr.length >0 && 
                      <p><strong>No. of Additional Places of Business : </strong>{details.adadr.length}</p>
                  }
                  <p><strong>Business :</strong> {details.nba}</p>
                  <p><strong>Constitution of Business :</strong> {details.ctb}</p>
                  <p><strong>Door No. :</strong> {details.pradr.addr.bno}</p>
                  <p><strong>Street :</strong> {details.pradr.addr.st}</p>
                  <p><strong>Place :</strong> {details.pradr.addr.loc} - {details.pradr.addr.pncd}</p>
                  <p><strong>District :</strong> {details.pradr.addr.dst}</p>
                  <p><strong>State :</strong> {details.pradr.addr.stcd}</p>
                  <p><strong>geoCode :</strong> {details.pradr.addr.geocodelvl}</p>
                  <p><strong>Latitude :</strong> {details.pradr.addr.lt}</p>
                  <p><strong>Longitude :</strong> {details.pradr.addr.lg}</p>
                  <p><strong>Reg.Date :</strong> {details.rgdt}</p>
                  <p><strong>Last update Date :</strong> {details.lstupdt}</p>

                  <p><strong>State Office :</strong> {details.stj}</p>
                  <p><strong>Central Office :</strong> {details.ctj}</p>
                </div>
                <div className='TaxPayer'>

                  {details.adadr.map((item, index) => {
                    return (
                      <div key={index} className='gstin-additional-address'>
                        <h3>Additional Place of Business - {index + 1}</h3>
                        <p><strong>Door No. :</strong> {item.addr.bno}</p>
                        <p><strong>Street :</strong> {item.addr.st}</p>
                        <p><strong>Place :</strong> {item.addr.loc} - {item.addr.pncd}</p>
                        <p><strong>District :</strong> {item.addr.dst}</p>
                        <p><strong>State :</strong> {item.addr.stcd}</p>
                        <p><strong>geoCode :</strong> {item.addr.geocodelvl}</p>
                        <p><strong>Latitude :</strong> {item.addr.lt}</p>
                        <p><strong>Longitude :</strong> {item.addr.lg}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchTaxPayer;