import React, { useState } from 'react';
import axios from 'axios';
import './ImportProductGroup.css';

function ImportProductGroup() {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [initialdata, setinitialdata] = useState([]);
    const [data, setData] = useState([]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            alert('Please select a file first.');
            return;
        }

        if (!data.length > 0) {
            alert('No more data');
            return;
        }

        console.log(data);
        try {
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/import_productgroup`, data);
            setMessage(response.data.message);
        } catch (error) {
            console.error('Error uploading file:', error);
            setMessage('Error uploading file.');
        }
    };


    const handleUploadItem = async () => {
        if (!file) {
            alert('Please select a file first.');
            return;
        }

        if (!initialdata.length > 0) {
            alert('No more data');
            return;
        }

        console.log(initialdata);
        try {
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/import_productmaster`, initialdata);
            setMessage(response.data.message);
        } catch (error) {
            console.error('Error uploading file:', error);
            setMessage('Error uploading file.');
        }
    };


    const handleDisplayGroups = async () => {
        const filtered = [];
        const uniqueGroupSerials = [];
        if (file && file.type === 'application/json') {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const jsonData = JSON.parse(e.target.result);
                    setinitialdata(jsonData);
                    console.log(setinitialdata);
                    jsonData.forEach(product => {
                        if (!uniqueGroupSerials.includes(product.groupserial)) {
                            uniqueGroupSerials.push(product.groupserial);
                            filtered.push(product);
                        }
                    });
                    console.log(filtered)
                    setData(filtered);
                } catch (err) {
                    setData([]);
                    setinitialdata([]);
                }
            };
            reader.readAsText(file);
        } else {
            setData([]);
        }
    };

    return (
        <div className='frmimportproductgroup-mainpage' >
            <div className='frmimportproductgroup-heading'>
                <h1>Upload JSON File</h1>
            </div>
            <div className="frmimportproductgroup">
                <div className='frmimportbuttons'>
                    <input type="file" accept=".json" style={{marginLeft:"20%"}}onChange={handleFileChange} />
                    <button onClick={handleUpload}>Upload Group</button>
                    <button onClick={handleUploadItem}>Upload Product List</button>
                    <button onClick={handleDisplayGroups}>Display Groups & Product</button>
                </div>

                <div className='frmimportlist'>
                    <div className="group-list">
                        <h2>Group List</h2>
                        <div className="group-list-details">
                            <ul>
                                {data.map(group => (
                                    <li key={group.groupserial}>
                                        Group Name : {group.groupname} (Serial: {group.groupserial})
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="product-list">
                        <h2>Product List</h2>
                        <div className="product-list-details">
                            <ul>
                                {initialdata.map(group => (
                                    <li key={group.pdtserial}>
                                        Product Name : {group.pdtname} (Serial: {group.pdtserial})
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImportProductGroup;
