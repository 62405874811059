import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useDispatch } from 'react-redux';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AiOutlineFilePdf, AiOutlineFileExcel, AiOutlineFileText, AiOutlinePrinter,AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './stockplacelookup.css';

import { myaction } from '../../redux/Mystore';

const Stockplacelookup = () => {
    const mydispatch=useDispatch()
    const navigate=useNavigate()
    const tableRef = useRef();
    const [initialData, setinitialData] = useState([]);
    const [curdata, setcurdata] = useState([]);

    
    const [search, setSearch] = useState('');
    
    const fetchdata = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/store_readall`);
            setinitialData(response.data);
            setcurdata(response.data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };
    
    useEffect(() => {
        fetchdata();
    },[]);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        if (value==="") {
            setcurdata(initialData);    
            return;
        }
        const filtered = initialData.filter(item => item.factoryname.toLowerCase().includes(value.toLowerCase()));
        setcurdata(filtered);
    };

    const handleDelete = (data) => {
            Swal.fire({
              title: 'Are you sure?',
              text: data.factoryname,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.isConfirmed) {
                confirmDelete(data);
              }
        });
    }

    const confirmDelete = async (data) => {
        const userdata = { 
            factorycode:data.factorycode
        }
        const url=`${process.env.REACT_APP_RAINBOW_URL}/store_delete`
        try {
            await axios.post(url,userdata);
            fetchdata();
        } catch (error) {
            console.error('There was an error deleting the data!', error);
        }
    };

    const handlePrint = () => {
        window.print();
    };
    
    const handlePDFDownload = () => {
        const doc = new jsPDF();
        doc.autoTable({ html: '#stockplace-table' });
        doc.save('stockplace-list.pdf');
    };

    const handleExportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(initialData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "stockplace");
        XLSX.writeFile(workbook, "data.xlsx");
    };

    const handleExportToJSON = () => {
        const blob = new Blob([JSON.stringify(initialData, null, 2)], { type: 'application/json' });
        saveAs(blob, 'data.json');
    };

    const handleEdit = (data) => {
        mydispatch(myaction.setfactorymode(["Edit",data.factorycode]));
        navigate("/installstockplace")
    }

    const handleAddNew = () => {
        mydispatch(myaction.setfactorymode(["New",0]));
        navigate("/installstockplace")
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Escape') {
                navigate("/emptypage");
                mydispatch(myaction.hideMenu(false))
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <div id="frmlookupstock" className= 'frmlookupstock-divmain'>
            <div className="table-container">
                <h3>Stock Place</h3>
                <div className='searchtext'>
                    <input
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                    <div className="mybtncontainer">
                        <button className="mybtn" onClick={handlePDFDownload}><AiOutlineFilePdf className="btn-icon" />PDF</button>
                        <button className="mybtn" onClick={handleExportToExcel}><AiOutlineFileExcel className="btn-icon" />Excel</button>
                        <button className="mybtn" onClick={handleExportToJSON}><AiOutlineFileText className="btn-icon" />JSON</button>
                        <button className="mybtn" onClick={handlePrint}><AiOutlinePrinter className="btn-icon"/>Print</button>
                        <button className="mybtn" onClick={handleAddNew}><AiOutlinePlus className='btn-icon'/>Addnew</button>
                    </div>

                </div>

                <div className="lookupTable" >
                    <table className="tabledata" id="stockplace-table" ref={tableRef}>
                        <thead className='tablehead' >
                            <tr style={{position: 'sticky', top: '-1px'}}>
                                <th style={{backgroundColor:"black"}}
                                    className="col-factoryname" >
                                    Name 
                                </th>
                                <th style={{backgroundColor:"black"}}
                                    className="col-companyname" >
                                    Company Name
                                </th>
                                <th style={{backgroundColor:"black"}}
                                    className="col-action" >
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {curdata.map(data => (
                                <tr className="row-data" key={data.factorycode} onDoubleClick={() => handleEdit(data)}>
                                    <td className="col-factoryname" >{data.factoryname}</td>
                                    <td className="col-companyname" >{data.actname}</td>
                                    <td className="col-action">
                                        <div className='col-action-div'>
                                            <button onClick={() => handleEdit(data)}>Edit</button>
                                            <button onClick={() => handleDelete(data)}>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>
    );
};

export default Stockplacelookup;
