import React, { useRef, useState, useEffect } from 'react';
import axios from "axios";
import InputMask from 'react-input-mask';


const ClientPartners = () => {
    let url;
    let response;
    const partnernameRef = useRef();
    const address1Ref = useRef();
    const address2Ref = useRef();
    const placeRef = useRef();
    const pincodeRef = useRef();
    const aadhaarRef = useRef();
    const panRef = useRef();
    const mobile1Ref = useRef();
    const emailRef = useRef();
    const shareratioRef = useRef();
    const auditornameRef = useRef();

    const [auditordata, setAuditordata] = useState([]);

    useEffect(() => {
        const handleFetchAuditorlist = async () => {
            try {
                const response = await axios.post('http://localhost:5000/auditorlist_getall');
                console.log(response);
                setAuditordata(response.data.data);
            } catch (error) {
                console.error('Error fetching Auditor:', error);
            }
        };
        handleFetchAuditorlist();
        partnernameRef.current.focus();
    }, []);


    const handleClick = (event) => {
        event.preventDefault();
    };

    const [partnersformdata, setPartnersFormdata] = useState({
        partnername: '',
        address1: '',
        address2: '',
        place: '',
        pincode: '',
        aadhaar: '',
        pan: '',
        mobile1: '',
        mobile2: '',
        email: '',
        shareratio: '',
        it: false,
        auditorname: ''
    })

    const resetPartnersForm = () => {
        setPartnersFormdata({
            partnername: '',
            address1: '',
            address2: '',
            place: '',
            pincode: '',
            aadhaar: '',
            pan: '',
            mobile1: '',
            mobile2: '',
            email: '',
            shareratio:'',
            it: false,
            auditorname: ''
        })
    }

    const toProperCase = (text) => {
        return text.replace(/\w\S*/g, (word) =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
    };

    const handlePartnersFormChange = async (event) => {
        let { name, value } = event.target;
        if (name === 'partnername' || name === 'address1' || name === 'address2' || name === 'place') {
            value = toProperCase(value);
            const { selectionStart, selectionEnd } = event.target;
            setTimeout(() => {
                if (name === 'partnername') {
                    partnernameRef.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'address1') {
                    address1Ref.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'address2') {
                    address2Ref.current.setSelectionRange(selectionStart, selectionEnd);
                } else if (name === 'place') {
                    placeRef.current.setSelectionRange(selectionStart, selectionEnd);
                }
            }, 0);
        }
        else if (name === 'it') {
            value = event.target.checked;
            setPartnersFormdata((prevPartnersFormdata) => ({
                ...prevPartnersFormdata,
                auditorname: '',
            }));
        }

        setPartnersFormdata((prevPartnersFormdata) => ({
            ...prevPartnersFormdata,
            [name]: value
        }));

    }
    const [curmode, setcurmode] = useState('New');

    const handlePartnersAddNew = () => {
        setcurmode('New');
        resetPartnersForm();
        partnernameRef.current.focus();
    }
    const [srlno, setsrlno] = useState(0);
    const handlePartnersEdit = (data, index) => {
        setPartnersFormdata({
            partnername: data.partnername,
            address1: data.address1,
            address2: data.address2,
            place: data.place,
            pincode: data.pincode,
            aadhaar: data.aadhaar,
            pan: data.pan,
            mobile1: data.mobile1,
            mobile2: data.mobile2,
            email: data.email,
            shareratio: data.shareratio,
            it: data.it,
            auditorname: data.auditorname
        })
        setcurmode('Edit');
        partnernameRef.current.focus();
        setsrlno(data.srlno);
        console.log(data.srlno);
    }

    const handlePartnersDelete = async (data, index) => {
        const confirmed = window.confirm("Are you sure you want to delete this entry?");
        if (confirmed) {
            const partnersdata = {
                srlno: data.srlno,
                coycode: 1
            }
            url = `${process.env.REACT_APP_EMPLOYEE_URL}/profilepartners_delete`;

            response = await axios.post(url, partnersdata)
            handleFetchAll();
            setcurmode('New');
            resetPartnersForm();
        }
    };



    const handlePartnersCancel = () => {
        resetPartnersForm();
        partnernameRef.current.focus();
        setcurmode('New');
    }

    const [profilepartnersdata, setProfilePartnersdata] = useState([]);
    const handleFetchAll = async () => {
        try {
            url = 'http://localhost:5000/profilepartners_getall';
            const data = {
                coycode: 1
            }
            const response = await axios.post(url, data);
            setProfilePartnersdata(response.data);
        } catch (error) {
            console.error('Error fetching profile details:', error);
        }
    };

    useEffect(() => {
        handleFetchAll();
    }, []);


    const handlePartnersSave = async () => {
        let value = partnersformdata.partnername;
        if (value === '') {
            alert('Must Enter Partner Name');
            partnernameRef.current.focus();
            return;
        }
        value = partnersformdata.address1;
        if (value === '') {
            alert('Must Enter Address');
            address1Ref.current.focus();
            return;
        }
        value = partnersformdata.place;
        if (value === '') {
            alert('Must Enter Place');
            placeRef.current.focus();
            return;
        }
        value = partnersformdata.pincode;
        if (value === '') {
            alert('Must Enter Pincode');
            window.document.getElementById('pincode').focus();
            window.document.getElementById('pincode').select();
            return;
        }
        value = partnersformdata.aadhaar;
        if (value === '') {
            alert('Must Enter Aadhaar No.');
            window.document.getElementById('aadhaar').focus();
            window.document.getElementById('aadhaar').select();
            return;
        }
        value = partnersformdata.pan;
        if (value === '') {
            alert('Must Enter PAN');
            window.document.getElementById('pan').focus();
            window.document.getElementById('pan').select();
            return;
        }
        value = partnersformdata.mobile1;
        if (value === '') {
            alert('Must Enter Mobile No.');
            mobile1Ref.current.focus();
            return;
        }
        value = partnersformdata.email;
        if (value === '') {
            alert('Must Enter Email');
            emailRef.current.focus();
            return;
        }
        value = partnersformdata.auditorname;
        if (partnersformdata.it === true && value === '') {
            alert('Must Choose Auditor Name');
            auditornameRef.current.focus();
            return;
        }
        try {
            const partnersdata = {
                srlno: curmode === 'Edit' ? srlno : 0,
                coycode: 1,
                partnername: partnersformdata.partnername,
                address1: partnersformdata.address1,
                address2: partnersformdata.address2,
                place: partnersformdata.place,
                pincode: partnersformdata.pincode,
                aadhaar: partnersformdata.aadhaar,
                pan: partnersformdata.pan,
                mobile1: partnersformdata.mobile1,
                mobile2: partnersformdata.mobile2,
                email: partnersformdata.email,
                shareratio: partnersformdata.shareratio,
                it: partnersformdata.it ? 1 : 0,
                auditorname: partnersformdata.it ? partnersformdata.auditorname : '',
            }
            console.log(partnersdata);

            url = `${process.env.REACT_APP_EMPLOYEE_URL}/profilepartners_create`;

            if (curmode === 'Edit') {
                url = `${process.env.REACT_APP_EMPLOYEE_URL}/profilepartners_update`;


            }
            response = await axios.post(url, partnersdata)
            handleFetchAll();
        }
        catch (error) {
            console.error('Error submitting form:', error);
        }
        setcurmode('New');


        resetPartnersForm();
        partnernameRef.current.focus();

    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission on Enter
        }
    };

    const defaultEnterRef = useRef('');
    const defaultEscapeRef = useRef('');

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Enter') {
                defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                defaultEscapeRef.current.click();
            }

        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);
    return (
        <form className='clientprofile-forms' onSubmit={handleClick} onKeyDown={handleKeyDown}>
            {/* {(formdata.selectedconstitution === 'Proprietor' || formdata.selectedconstitution === 'Proprietrix') && */}
            {/* <div className='clientprofile-heading'>
                        <p> Partners Not Provided!</p>
                    </div> */}
            {/* } */}
            {/* {(formdata.selectedconstitution !== 'Proprietor' && formdata.selectedconstitution !== 'Proprietrix') && */}
            <div className='clientprofile-cmpdetails'>
                {/* <p>Company ID : {formdata.coyId}</p>
                        <p>Company Name : {formdata.coyname}</p>
                        <p>Place : {formdata.place}</p>
                        <p>GSTIN: {formdata.GSTIN}</p> */}
            </div>
            {/* } */}

            {/* {(formdata.selectedconstitution !== 'Proprietor' && formdata.selectedconstitution !== 'Proprietrix') && */}
            <div className='clientprofile-partnerscontainer'>
                <div className='clientprofile-partnerstablepanel'>
                    <div className='clientprofile-addnew-partnersdetailsgrp'>
                        <p> Partners Details:</p>
                        {/* <div className='clientprofile-totalsharegrp'>
                            <label htmlFor="totalshare">Total Share:</label>
                            <input type="text" id='totalshare' />
                        </div> */}
                        <button onClick={handlePartnersAddNew}>Add New</button>
                    </div>
                    <div className='clientprofile-partnerstable' >
                        <table >
                            <thead>
                                <tr>
                                    <th style={{ width: '40%' }}>Partner Name</th>
                                    <th style={{ width: '20%' }}>Place</th>
                                    <th style={{ width: '10%' }}>Pincode</th>
                                    <th style={{ width: '15%' }}>PAN</th>
                                    <th style={{ width: '15%' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {profilepartnersdata.map((data, index) => (
                                    <tr key={index}>
                                        <td className="table-cell">{data.partnername}</td>
                                        <td className="table-cell">{data.place}</td>
                                        <td className="table-cell" style={{ textAlign: 'right' }}>{data.pincode}</td>
                                        <td className="table-cell">{data.pan}</td>
                                        <td className="table-cell">
                                            <div className='edit-deletebtngroup'>
                                                <button onClick={() => handlePartnersEdit(data, index)}>Edit</button>
                                                <button onClick={() => handlePartnersDelete(data, index)}>Delete</button>
                                            </div>
                                        </td>

                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='clientprofile-partnerssinglepanel'>
                    <div className='clientprofile-partnersform'>
                        <div className='clientprofile-partnersform-group'>
                            <label htmlFor="partnername" >  Partner Name:</label>
                            <input type='text' id='partnername' name='partnername' ref={partnernameRef} value={partnersformdata.partnername} onChange={handlePartnersFormChange} maxLength={50}></input>
                        </div>
                        <div className='clientprofile-partnersform-group'>
                            <label htmlFor="address1" > Address:</label>
                            <input type='text' id='address1' name='address1' ref={address1Ref} value={partnersformdata.address1} onChange={handlePartnersFormChange} maxLength={100}></input>
                        </div>
                        <div className='clientprofile-partnersform-group'>
                            <label htmlFor="address2" > </label>
                            <input type='text' id='address2' name='address2' ref={address2Ref} value={partnersformdata.address2} onChange={handlePartnersFormChange} maxLength={100}></input>
                        </div>
                        <div className='clientprofile-partnersform-group'>
                            <label htmlFor="place"> Place:</label>
                            <div className='clientprofile-form-subgroup1'>
                                <input type="text" id='place' style={{ width: "50%" }} name='place' ref={placeRef} value={partnersformdata.place} onChange={handlePartnersFormChange} maxLength={50} />
                                <div className='clientprofile-form-subgroup2' style={{ width: "50%" }} >
                                    <label htmlFor="pincode" style={{ width: "50%", textAlign: "right" }}>PinCode:</label>
                                    <InputMask
                                        mask="999999"
                                        maskChar={null}
                                        alwaysShowMask={false}
                                        id='pincode' style={{ width: "50%" }} name='pincode' ref={pincodeRef} value={partnersformdata.pincode} onChange={handlePartnersFormChange}
                                    ></InputMask>

                                </div>
                            </div>
                        </div>
                        <div className='clientprofile-partnersform-group'>
                            <label htmlFor="aadhaar" > Aadhaar:</label>
                            <div className='clientprofile-form-subgroup1'>
                                <InputMask
                                    mask="999999999999"
                                    maskChar={null}
                                    alwaysShowMask={false}
                                    id='aadhaar' style={{ width: "45%" }} name='aadhaar' ref={aadhaarRef} value={partnersformdata.aadhaar} onChange={handlePartnersFormChange}
                                ></InputMask>

                                <div className='clientprofile-form-subgroup2' style={{ width: "55%" }}>
                                    <label htmlFor="pan" style={{ width: "25%", textAlign: "right" }}> PAN:</label>
                                    <InputMask
                                        mask="AAAAA9999A"
                                        maskChar={null}
                                        alwaysShowMask={false}
                                        id='pan' style={{ width: "75%" }} name='pan' ref={panRef} value={partnersformdata.pan} onChange={handlePartnersFormChange}
                                    ></InputMask>

                                </div>
                            </div>
                        </div>

                        <div className='clientprofile-partnersform-group'>
                            <label htmlFor="mobile1" > Mobile:</label>
                            <div className='clientprofile-form-subgroup1'>
                                <input type="text" id='mobile1' style={{ width: "50%" }} name='mobile1' ref={mobile1Ref} value={partnersformdata.mobile1} onChange={handlePartnersFormChange} maxLength={12} />
                                <input type="text" id='mobile2' style={{ width: "50%" }} name='mobile2' value={partnersformdata.mobile2} onChange={handlePartnersFormChange} maxLength={12} />
                            </div>
                        </div>
                        <div className='clientprofile-partnersform-group'>
                            <label htmlFor="email" > E-mail:</label>
                            <input type="text" id='email' name='email' ref={emailRef} value={partnersformdata.email} onChange={handlePartnersFormChange} maxLength={100} />
                        </div>
                        <div className='clientprofile-partnersform-group'>
                            <label htmlFor="shareratio" > Share Ratio:</label>
                            <InputMask
                                mask="99"
                                maskChar={null}
                                alwaysShowMask={false}
                                style={{width:'50px', marginLeft:'-5px'}}
                                id='shareratio' name='shareratio' ref={shareratioRef} value={partnersformdata.shareratio} onChange={handlePartnersFormChange}
                            ></InputMask>

                        </div>
                        <div className='clientprofile-partnersformcheckbox-group'>
                            <div className='clientprofile-partnersformcheckbox-subgroup1'>
                                <label htmlFor="itcheckbox">IT</label>
                                <input
                                    type="checkbox"
                                    id='itcheckbox'
                                    name='it'
                                    checked={partnersformdata.it}
                                    onChange={handlePartnersFormChange}
                                    style={{ width: "20px", height: "15px" }}

                                />
                            </div>
                            < div className='clientprofile-partnersformcheckbox-subgroup2'>
                                <label htmlFor="auditorname" style={{ width: "35%" }}>Auditor Name:</label>

                                <select id='auditorname'
                                    style={{ width: "65%", height: "28px" }}
                                    name='auditorname' ref={auditornameRef} value={partnersformdata.auditorname} onChange={handlePartnersFormChange}
                                    disabled={!partnersformdata.it}
                                >
                                    <option value="" disabled selected>Select Auditor...</option>

                                    {auditordata.map(data => (
                                        <option key={data.auditorcode}
                                            value={data.auditorcode}>{data.auditorname}</option>

                                    ))}
                                </select>

                            </div>


                        </div>
                    </div>

                    <div className='clientprofile-save-cancelbtngrp'>
                        <button ref={defaultEscapeRef} onClick={handlePartnersCancel}>Cancel</button>
                        <button ref={defaultEnterRef} type='submit' onClick={handlePartnersSave} >Save</button>

                    </div>
                </div>
            </div>
            {/* } */}


        </form>
    )
}
export default ClientPartners;