import React, { useRef, useState, useEffect } from 'react';
import axios from "axios";
import InputMask from 'react-input-mask';
import { NumericFormat } from 'react-number-format';
import * as u from "../utils/Common";
import "./SalesTaxParticulars.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { myaction } from '../../redux/Mystore';

const SalesTaxParticulars = () => {
    const navigate = useNavigate();

    const multipleHSN = false;

    let url;
    let response;

    const salescodeRef = useRef();
    const prefixRef = useRef();
    const effromRef = useRef();
    const efftoRef = useRef();
    const commoditycodeRef = useRef();
    const categoryRef = useRef();
    const taxrateRef = useRef();
    const taxtypeRef = useRef();
    const taxcode1Ref = useRef();
    const taxcode2Ref = useRef();
    const gstcesscodeRef = useRef();
    const tcscodeRef = useRef();

    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [commoditydata, setCommoditydata] = useState([]);
    const [salesdata, setSalesdata] = useState([]);
    const [taxdata, setTaxdata] = useState([]);

    const categoryArray = [
        { code: 1, name: 'GST(Local)' },
        { code: 2, name: 'GST(Other State)' },
        { code: 3, name: 'GST Exempted' },
        { code: 4, name: 'IGST Exempted' },
        { code: 5, name: 'Service(Local)' },
        { code: 6, name: 'Service(Other State)' },
        { code: 7, name: 'RCM (Local)' },
        { code: 8, name: 'RCM(Other State)' },
        { code: 9, name: 'RCM Service(Local)' },
        { code: 10, name: 'RCM Service(Other State)' },
        { code: 11, name: 'Export/Import' },
        { code: 12, name: 'Non- GST' },
        { code: 13, name: 'Compounding' },
        { code: 14, name: 'SEZ' },
        { code: 15, name: 'Block Credit(Local)' },
        { code: 16, name: 'Block Credit(Other State)' },
        { code: 17, name: 'Below Limit(Local)' },
        { code: 18, name: 'Below Limit(Other State)' },
        { code: 19, name: 'Capital Goods(Local)' },
        { code: 20, name: 'Capital Goods(Other State)' }
    ]

    const rcmdata = [7, 8, 9, 10];
    const gstrate = [0, 1, 5, 6, 12, 18, 28];
    const compoundingtaxcode = 13;
    const igsttaxcode = 4;

    const fetchsalesdata = async () => {
        try {
            const data = { accgroupcode: 8 };
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/acchead_readgroupall`, data);
            console.log(response);
            setSalesdata(response.data);
        } catch (error) {
            console.error('There was an error fetching the sales data!', error);
        }
    };

    const fetchtaxdata = async () => {
        try {
            const data = { accgroupcode: 16 };
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/acchead_readgroupall`, data);
            console.log(response);
            setTaxdata(response.data);
        } catch (error) {
            console.error('There was an error fetching the tax data!', error);
        }
    };

    useEffect(() => {
        fetchsalesdata();
        fetchtaxdata();
        handleFetchCommoditylist();
    }, []);

    const handleFetchCommoditylist = async () => {
        try {
            const response = await axios.post('http://localhost:5000/commodity_getall');
            console.log(response);
            setCommoditydata(response.data);
        } catch (error) {
            console.error('Error fetching Commodity:', error);
        }
    };

    const handleClick = (event) => {
        event.preventDefault();
    };

    const [formdata, setFormdata] = useState({
        salescode: 0,
        prefix: '',
        effrom: '',
        effto: '',
        commoditycode: 0,
        hsncode: '',
        category: 0,
        taxrate: 0,
        taxtype: 'G',
        taxcode1: 0,
        taxcode2: 0,
        gstcesscodeYN: false,
        gstcesscode: 0,
        tcscodeYN: false,
        tcscode: 0,
        packingcharge: 0,
        mahamai: 0,
        insurance: 0,
        discount: 0,
        remarks1: '',
        remarks2: ''
    })

    const resetForm = () => {
        setFormdata({
            salescode: 0,
            prefix: '',
            effrom: '',
            effto: '',
            commoditycode: 0,
            hsncode: '',
            category: 0,
            taxrate: 0,
            taxtype: 'G',
            taxcode1: 0,
            taxcode2: 0,
            gstcesscodeYN: false,
            gstcesscode: 0,
            tcscodeYN: false,
            tcscode: 0,
            packingcharge: 0,
            mahamai: 0,
            insurance: 0,
            discount: 0,
            remarks1: '',
            remarks2: ''
        })
    }


    const handleFormChange = async (event) => {
        let { name, value } = event.target;
        if (name === 'prefix') {
            value = value.toUpperCase();
            const { selectionStart, selectionEnd } = event.target;
            setTimeout(() => {
                prefixRef.current.setSelectionRange(selectionStart, selectionEnd);
            }, 0);
        }
        if(name === 'category'){
            if( u.myInt(value) === igsttaxcode){
                formdata.taxtype='I';
                setFormdata(formdata);
            }
            else{
                formdata.taxtype = 'G';
                setFormdata(formdata);
            }
            
        }
        if (name === 'gstcesscodeYN' || name === 'tcscodeYN') {
            value = event.target.checked;
        }

        if (name === 'commoditycode') {
            const hsndata = commoditydata.find(item => item.commoditycode === parseInt(value));
            if (hsndata) {
                setFormdata((prevFormdata) => ({
                    ...prevFormdata,
                    hsncode: hsndata.hsncode
                }));
            }
        }

        setFormdata((prevFormdata) => ({
            ...prevFormdata,
            [name]: value
        }));


        // if( name === 'category' &&  rcmdata.includes(value) ){

        // }

    }

    const curmode = useSelector(function (data) {
        return data.salestaxmode;
    })

    useEffect(() => {
        const fetchdata = async () => {
            const data = { recno: curmode[1] };
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/tax_readbyrecno`, data)
            console.log(response);
            if (response.data.length > 0) {
                setFormdata({
                    ...formdata,
                    salescode: response.data[0].salescode,
                    prefix: response.data[0].prefix,
                    effrom: response.data[0].effrom,
                    effto: response.data[0].effto,
                    commoditycode: response.data[0].commoditycode,
                    hsncode: response.data[0].hsncode,
                    category: response.data[0].category,
                    taxrate: response.data[0].taxrate,
                    taxtype: response.data[0].taxtype,
                    taxcode1: response.data[0].taxcode1,
                    taxcode2: response.data[0].taxcode2,
                    gstcesscodeYN: response.data[0].gstcesscode ? true : false,
                    gstcesscode: response.data[0].gstcesscode,
                    tcscodeYN: response.data[0].tcscode ? true : false,
                    tcscode: response.data[0].tcscode,
                    packingcharge: response.data[0].packingcharge,
                    mahamai: response.data[0].mahamai,
                    insurance: response.data[0].insurance,
                    discount: response.data[0].discount,
                    remarks1: response.data[0].remarks1,
                    remarks2: response.data[0].remarks2
                });
                prefixRef.current.focus();
            }
        };
        if (curmode[0] === "Edit") {
            fetchdata();
        }
    }, [curmode])




    const handleSave = async () => {
        let value = formdata.salescode;
        if (value === 0) {
            alert('Sales Name Should be Given');
            salescodeRef.current.focus();
            return;
        }
        value = formdata.prefix;
        if (value.length > 0) {
            const regex = /^[A-Z0-9/-]*?$/;
            if (!regex.test(value)) {
                alert("Prefix Allows Only Characters, Numbers, / , - ");
                return;
            }
        }
        value = formdata.effrom;
        if(value !== ''){
            if (!u.validateDate(value)) {
                alert('Effective From Date is Invalid');
                window.document.getElementById("effrom").focus();
                window.document.getElementById("effrom").select();
                return;
            }
        }
       
        value = formdata.effto;
        if(value !== ''){
        if (!u.validateDate(value)) {
            alert('Effective To Date is Invalid');
            window.document.getElementById("effto").focus();
            window.document.getElementById("effto").select();
            return;
        }
    }
        if (multipleHSN === false) {
            value = formdata.commoditycode;
            if (value === 0) {
                alert('Commodity Name Should be Given');
                commoditycodeRef.current.focus();
                return;
            }
        }
        value = formdata.category;
        if (value === 0) {
            alert('Category Should be Given');
            categoryRef.current.focus();
            return;
        }
        if (formdata.gstcesscodeYN && formdata.gstcesscode === 0) {
            alert("GST CESS Ledger Should be Given");
            gstcesscodeRef.current.focus();
            return;
        }

        if (formdata.tcscodeYN && formdata.tcscode === 0) {
            alert("TCS Ledger Should be Given");
            tcscodeRef.current.focus();
            return;
        }

        if (formdata.taxrate > 0) {
            let category = u.myInt(formdata.category);
            if ((rcmdata.includes(category)) || (category === compoundingtaxcode)) {
                formdata.taxcode1 = 0;
                formdata.taxcode2 = 0;
                formdata.gstcesscode = 0;
                formdata.tcscode = 0;
                setFormdata(formdata);
                console.log(formdata);
            }
            else {
                console.log(formdata.taxtype);
                if (formdata.taxtype === 'G') {
                    if (formdata.taxcode1 === 0) {
                        alert('Tax Ledger Should be Given');
                        taxcode1Ref.current.focus();
                        return;
                    }
                    if (formdata.taxcode2 === 0) {
                        alert('Tax Ledger Should be Given');
                        taxcode2Ref.current.focus();
                        return;
                    }
                    if (formdata.taxcode1 === formdata.taxcode2) {
                        alert("Tax Ledger Should be Different");
                        taxcode2Ref.current.focus();
                        return;
                    }
                }
                else {
                    if (formdata.taxcode1 === 0) {
                        alert('Tax Ledger Should be Given');
                        taxcode1Ref.current.focus();
                        return;
                    }
                    formdata.taxcode2 = 0;
                }
            }
        }

        
        if (formdata.packingcharge >= 100) {
            alert("Packing Charge Should below 100");
            window.document.getElementById("packingcharge").focus();
            window.document.getElementById("packingcharge").select();
            return;
        }
        if (formdata.mahamai >= 100) {
            alert("Mahamai Should below 100");
            window.document.getElementById("mahamai").focus();
            window.document.getElementById("mahamai").select();
            return;
        }
        if (formdata.insurance >= 100) {
            alert("Insurance Should below 100");
            window.document.getElementById("insurance").focus();
            window.document.getElementById("insurance").select();
            return;
        }
        if (formdata.discount >= 100) {
            alert("Discount Should below 100");
            window.document.getElementById("discount").focus();
            window.document.getElementById("discount").select();
            return;
        }
        try {
            const data = {
                recno: (curmode[0] === "New" ? 0 : curmode[1]),
                salescode: u.myInt(formdata.salescode),
                prefix: formdata.prefix
            }
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/tax_prefixsalescodecheckexists`, data);
            if (response.data.length > 0) {
                alert("Prefix Already Exists");
                prefixRef.current.focus();
                return;
            }
        }
        catch(error) {
            console.error('Checking Error ', error);
        }


        try {
            const data = {
                recno: (curmode[0] === "New" ? 0 : curmode[1]),
                salescode: u.myInt(formdata.salescode),
                prefix: formdata.prefix,
                effrom: formdata.effrom,
                effto: formdata.effto,
                commoditycode: multipleHSN ? 0 : u.myInt(formdata.commoditycode),
                hsncode: multipleHSN ? 0 : u.myInt(formdata.hsncode),
                category: u.myInt(formdata.category),
                taxrate: u.myInt(formdata.taxrate),
                taxtype: formdata.taxtype,
                taxcode1: u.myInt(formdata.taxcode1),
                taxcode2: u.myInt(formdata.taxcode2),
                gstcesscode: formdata.gstcesscodeYN ? u.myInt(formdata.gstcesscode) : 0,
                tcscode: formdata.tcscodeYN ? u.myInt(formdata.tcscode) : 0,
                packingcharge: u.myFloat(formdata.packingcharge),
                mahamai: u.myFloat(formdata.mahamai),
                insurance: u.myFloat(formdata.insurance),
                discount: u.myFloat(formdata.discount),
                remarks1: formdata.remarks1,
                remarks2: formdata.remarks2
            }
            console.log(data);

            url = `${process.env.REACT_APP_RAINBOW_URL}/tax_create`;


            if (curmode[0] === "Edit") {
                url = `${process.env.REACT_APP_RAINBOW_URL}/tax_update`
            }
            await axios.post(url, data);

            if (curmode[0] === "Edit") {
                navigate(-1)
                return;
            }

        }
        catch (error) {
            console.error('Error submitting form:', error);
        }
        resetForm();
        salescodeRef.current.focus();

    }
    const handleCancel = () => {
        navigate(-1);
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission on Enter
        }
    };

    const defaultEnterRef = useRef('');
    const defaultEscapeRef = useRef('');

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Enter') {
                defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                defaultEscapeRef.current.click();
            }

        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);



    const isAllowed = (values) => {
        const { floatValue } = values;
        // Ensure the total number of digits (before and after the decimal) does not exceed 5
        return floatValue === undefined || floatValue.toString().replace('.', '').length <= 5;
    };

    return (
        <div className='salestax-divmain'>
            <div className='salestax-body'>
                <div className="salestax-form-content">
                    <form className='salestax-forms' onSubmit={handleClick} onKeyDown={handleKeyDown} >
                        <div className='salestax-details'>

                            <h4>Sales Tax Particulars</h4>
                        </div>
                        <div className='salestax-container'>

                            <div className='salestax-form'>
                                <div className='salestax-form-group'>
                                    <label htmlFor="salescode" > Sales Name:</label>
                                    <select name="salescode" id="salescode" ref={salescodeRef} value={formdata.salescode} onChange={handleFormChange} disabled={curmode[0] === 'Edit'}>
                                        <option value={0} disabled selected>Select Commodity Name</option>
                                        {salesdata.map(data => (
                                            <option key={data.actcode}
                                                value={data.actcode}>{data.actname}</option>

                                        ))}
                                    </select>
                                </div>
                                <div className='salestax-form-group'>
                                    <label htmlFor="prefix" > Prefix:</label>
                                    <div className='salestax-form-subgroup1'>
                                        <input type='text' id="prefix" name='prefix' ref={prefixRef} value={formdata.prefix} onChange={handleFormChange}
                                            style={{ width: "20%" }}>
                                        </input>
                                        {/* <div className='salestax-form-subgroup2' */}

                                        <label htmlFor="effrom" style={{ width: "15%" }}>  Eff. From:</label>
                                        <InputMask
                                            mask="99/99/99"
                                            maskChar={null}
                                            alwaysShowMask={false}
                                            id='effrom' name='effrom' ref={effromRef} value={formdata.effrom} onChange={handleFormChange}
                                            style={{ width: "15%" }}
                                        ></InputMask>


                                        <label htmlFor="effto" style={{ width: "10%" }}>  Eff. To:</label>
                                        <InputMask
                                            mask="99/99/99"
                                            maskChar={null}
                                            alwaysShowMask={false}
                                            id='effto' name='effto' ref={efftoRef} value={formdata.effto} onChange={handleFormChange}
                                            style={{ width: "15%" }}
                                        ></InputMask>

                                        {/* </div> */}
                                    </div>
                                </div>
                                {multipleHSN === false &&
                                    <div className='salestax-form-group'>
                                        <label htmlFor="commoditycode" > Commodity Name:</label>

                                        <select name="commoditycode" id="commoditycode" ref={commoditycodeRef} value={formdata.commoditycode} onChange={handleFormChange}>
                                            <option value={0} disabled selected>Select Commodity Name</option>
                                            {commoditydata.map(data => (
                                                <option key={data.commoditycode}
                                                    value={data.commoditycode}>{data.commodityname}</option>

                                            ))}
                                        </select>

                                    </div>
                                }
                                {multipleHSN === false &&
                                    <div className='salestax-form-group' >
                                        <label htmlFor="hsncode"  > HSN/SAC:</label>
                                        <input type="text" id='hsncode' name='hsncode' value={formdata.hsncode} style={{ width: '15%' }} onChange={handleFormChange} readOnly tabIndex={-1} />

                                    </div>
                                }
                                <div className='salestax-form-group'>
                                    <label htmlFor="category" > Category:</label>
                                    <select name="category" id="category"
                                        ref={categoryRef} value={formdata.category} onChange={handleFormChange} >
                                        <option value={0} disabled selected>Select Category</option>
                                        {categoryArray.map(data => (
                                            <option key={data.code}
                                                value={data.code}>{data.name}</option>

                                        ))}
                                    </select>

                                </div>
                                <div className='salestax-form-group'>
                                    <label htmlFor="taxrate" > Tax Rate:</label>
                                    <div className='salestax-form-subgroup1'>
                                        < select id="taxrate" name='taxrate' ref={taxrateRef} value={formdata.taxrate} onChange={handleFormChange}
                                            style={{ width: "5%", minWidth: "50px" }}>
                                            {gstrate.map(data => (
                                                <option key={data}
                                                    value={data}>{data}</option>

                                            ))}
                                        </select>

                                        <label htmlFor="taxtype" style={{ width: '15%' }} > Tax Type:</label>
                                        <select name="taxtype" id="taxtype" ref={taxtypeRef} value={formdata.taxtype} onChange={handleFormChange} style={{ width: "10%", minWidth: "70px" }} >

                                            <option value="G" selected>GST</option>
                                            <option value="I">IGST</option>
                                        </select>


                                    </div>
                                </div>

                                <div className='salestax-form-group'>
                                    <label htmlFor="taxcode1" >{formdata.taxtype === 'I' ? 'Tax Head (IGST):' : 'Tax Head (CGST):'}</label>
                                    <select name="taxcode1" id="taxcode1" ref={taxcode1Ref} value={formdata.taxcode1} onChange={handleFormChange} >
                                        <option value={0} disabled selected>Select Tax Ledger</option>
                                        {taxdata.map(data => (
                                            <option key={data.actcode}
                                                value={data.actcode}>{data.actname}</option>

                                        ))}
                                    </select>
                                </div>
                                <div className='salestax-form-group'>
                                    <label htmlFor="taxcode2" > Tax Head (SGST):</label>
                                    <select name="taxcode2" id="taxcode2" ref={taxcode2Ref} value={formdata.taxcode2} onChange={handleFormChange} disabled={formdata.taxtype === 'I'} >
                                        <option value={0} disabled selected>Select Tax Ledger</option>
                                        {taxdata.map(data => (
                                            <option key={data.actcode}
                                                value={data.actcode}>{data.actname}</option>

                                        ))}
                                    </select>
                                </div>
                                <div className='salestax-form-group'>
                                    <label htmlFor="gstcesscodeYN" > GST CESS:</label>
                                    <div className='salestax-form-checkboxgroup'>
                                        <input type='checkbox' id="gstcesscodeYN" name='gstcesscodeYN' checked={formdata.gstcesscodeYN} onChange={handleFormChange}
                                            style={{ width: "4%" }} >
                                        </input>
                                        <select name="gstcesscode" id="gstcesscode" ref={gstcesscodeRef} value={formdata.gstcesscode} onChange={handleFormChange}
                                            style={{ width: "96%" }} disabled={!formdata.gstcesscodeYN}>
                                            <option value={0} disabled selected>Select CESS</option>
                                            {taxdata.map(data => (
                                                <option key={data.actcode}
                                                    value={data.actcode}>{data.actname}</option>

                                            ))}

                                        </select>
                                    </div>
                                </div>
                                <div className='salestax-form-group'>
                                    <label htmlFor="tcscodeYN" > TCS:</label>
                                    <div className='salestax-form-checkboxgroup'>
                                        <input type='checkbox' id="tcscodeYN" name='tcscodeYN' checked={formdata.tcscodeYN} onChange={handleFormChange}
                                            style={{ width: "4%" }} >
                                        </input>
                                        <select name="tcscode" id="tcscode" ref={tcscodeRef} value={formdata.tcscode} onChange={handleFormChange}
                                            style={{ width: "96%" }} disabled={!formdata.tcscodeYN}>
                                            <option value={0} disabled selected>Select TCS</option>
                                            {taxdata.map(data => (
                                                <option key={data.actcode}
                                                    value={data.actcode}>{data.actname}</option>

                                            ))}

                                        </select>
                                    </div>
                                </div>
                                <div className='salestax-form-packinggroup'>
                                    <label htmlFor="packingcharge" style={{ width: "22%" }}> Packing Charge:</label>

                                    <div className='salestax-form-packingsubgroup'>
                                        <NumericFormat
                                            thousandSeparator={false}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            className="input-field"
                                            style={{ width: '10%', paddingLeft: '2px' }}
                                            id='packingcharge' name='packingcharge' value={formdata.packingcharge} onChange={handleFormChange}
                                            maxLength={6}
                                        />

                                        <label htmlFor="mahamai" > Mahamai:</label>
                                        <NumericFormat
                                            thousandSeparator={false}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            className="input-field"
                                            style={{ width: '10%', paddingLeft: '2px' }}
                                            id='mahamai' name='mahamai' value={formdata.mahamai} onChange={handleFormChange}
                                            maxLength={6}
                                        />

                                        <label htmlFor="insurance" > Insurance:</label>
                                        <NumericFormat
                                            thousandSeparator={false}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            className="input-field"
                                            style={{ width: '10%', paddingLeft: '2px' }}
                                            id='insurance' name='insurance' value={formdata.insurance} onChange={handleFormChange}
                                            maxLength={6}
                                        />

                                        <label htmlFor="discount" > Discount</label>

                                        <NumericFormat
                                            thousandSeparator={false}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            className="input-field"
                                            style={{ width: '10%', paddingLeft: '2px' }}
                                            id='discount' name='discount' value={formdata.discount} onChange={handleFormChange}
                                            maxLength={6}
                                        />
                                    </div>
                                </div>
                                <div className='salestax-form-group'>
                                    <label htmlFor="remarks1" > Remarks 1:</label>
                                    <textarea
                                        id="remarks1"
                                        name="remarks1"
                                        rows="3"
                                        cols="46"
                                        value={formdata.remarks1}
                                        onChange={handleFormChange}
                                        style={{ fontSize: '15px' }}
                                    />
                                </div>
                                <div className='salestax-form-group'>
                                    <label htmlFor="remarks2" > Remarks 2:</label>
                                    <textarea
                                        id="remarks2"
                                        name="remarks2"
                                        rows="3"
                                        cols="46"
                                        value={formdata.remarks2}
                                        onChange={handleFormChange}
                                        style={{ fontSize: '15px' }}
                                    />
                                </div>


                            </div>


                            <div className='salestax-save-cancelbtngrp'>
                                <button ref={defaultEscapeRef} onClick={handleCancel}>Cancel</button>
                                <button type='submit' ref={defaultEnterRef} onClick={handleSave}>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default SalesTaxParticulars;