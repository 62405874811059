// import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useDispatch } from 'react-redux';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { AiOutlinePrinter, AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './SaleInvoiceLookup.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { myaction } from '../../redux/Mystore';

const SaleInvoiceLookup = ({ TrnType = "SI" }) => {
    const mydispatch = useDispatch()
    // const trntype = TrnType;
    let trnname = "Sale Invoice";
    const [initialData, setinitialData] = useState([]);
    const [curdata, setcurdata] = useState([]);

    const [search, setSearch] = useState('');
    useEffect(() => {
        window.document.getElementById("search").focus();
        window.document.getElementById("search").select();
    }, []);
    const fetchdata = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/saleinvoice_readall`);
            const modifiedData = response.data.map(item => ({
                ...item,
                isSelected: false // or true, depending on your initial state
            }));
            setinitialData(modifiedData);
            setcurdata(modifiedData);

        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    useEffect(() => {
        fetchdata();
    }, []);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        if (value === "") {
            setcurdata(initialData);
            setSelectedIndex(0); // Reset selection to the first row
            return;
        }
        const filtered = initialData.filter(item => item.actname.toLowerCase().includes(value.toLowerCase()));
        setcurdata(filtered);
        setSelectedIndex(0);
    };

    const handleDelete = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: data.docuno,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(data);
            }
        });
    }

    const confirmDelete = async (data) => {
        const userdata = {
            docuno: data.docuno
        }
        console.log(userdata)
        const url = `${process.env.REACT_APP_RAINBOW_URL}/saleinvoice_delete`
        try {
            await axios.post(url, userdata);
            fetchdata();
        } catch (error) {
            console.error('There was an error deleting the data!', error);
        }
    };

    const handleEdit = (data) => {
        mydispatch(myaction.setsaleinvoicemode(["Edit", data.docuno]));
        navigate("/installsaleinvoice")
    }

    const handlePrintSelected = async () => {
        const xdata = { docuno: selectedList }
        const url = `${process.env.REACT_APP_RAINBOW_URL}/saleinvoice_preview`
        const response = await axios.post(url, xdata, { responseType: 'blob' });
        const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        window.open(fileURL, '_blank');
        setSelectedList([]);
    }


    const handlePrint = async (data) => {
        const xdata = { docuno: [data.docuno] }
        const url = `${process.env.REACT_APP_RAINBOW_URL}/saleinvoice_preview`
        const response = await axios.post(url, xdata, { responseType: 'blob' });
        const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        window.open(fileURL, '_blank');
    }

    const handleAddNew = () => {
        mydispatch(myaction.setsaleinvoicemode(["New", 0]));
        navigate("/installsaleinvoice")
    }

    const navigate = useNavigate()

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Escape') {
                navigate("/emptypage")
                mydispatch(myaction.hideMenu(false))
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const [selectedList, setSelectedList] = useState([]);

    // const handleCheckboxChange = (selectedId) => {
    //     setSelectedList(prevSelectedList => {
    //         if (prevSelectedList.includes(selectedId)) {
    //             return prevSelectedList.filter(id => id !== selectedId);
    //         } else {
    //             return [...prevSelectedList, selectedId];
    //         }
    //     });
    // };
    const [columnDefs] = useState([
        {
            headerClass: 'custom-header',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            headerName: '',
            field: 'select',
            width: 40,
            pinned: 'left',
            filter: false,
            cellStyle: { overflow: 'hidden' }
        },
        { headerName: 'Ref.No', field: 'docuno', headerClass: 'custom-header', sortable: true, filter: false, width: 70, resizable: true, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
        { headerName: 'Date', field: 'docudate', headerClass: 'custom-header', sortable: true, filter: true, resizable: true, width: 110, cellStyle: { overflow: 'hidden', textAlign: 'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
        { headerName: 'Customer', field: 'actname', headerClass: 'custom-header', sortable: true, filter: true, resizable: true, width: 290, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
        { headerName: 'Place', field: 'place', headerClass: 'custom-header', sortable: true, filter: true, resizable: true, width: 150, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
        { headerName: 'GSTIN', field: 'gstin', headerClass: 'custom-header', sortable: true, filter: true, resizable: true, width: 170, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } },
        {
            headerName: 'Bill Amount', field: 'debit', headerClass: 'custom-header', sortable: true, filter: true, resizable: true, width: 120, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
            valueFormatter: (params) => {
                return (params.value.toFixed(2))
            }
        },
        {
            headerName: 'Actions',
            field: 'actions',
            cellRenderer: (params) => (
                <div className="col-action-div">
                    <button onClick={() => handleEdit(params.data)}>Edit</button>
                    <button onClick={() => handleDelete(params.data)}>Delete</button>
                    <button onClick={() => handlePrint(params.data)}>Print</button>
                </div>
            ),
            headerClass: 'custom-header',
            width: 140,
        }
    ]);

    const defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true
    };

    const gridOptions = {
        rowSelection: 'multiple',
        onRowDoubleClicked: (event) => handleEdit(event.data),
    };
    const gridRef = useRef();
    const [arrowKeyUsed, setArrowKeyUsed] = useState(false); // Track arrow key usage

    const handleKeyDown = (event) => {
        if (!curdata.length) return; // Exit if there's no data

        if (event.key === 'ArrowDown') {
            setSelectedIndex((prevIndex) => {
                const newIndex = Math.min(prevIndex + 1, curdata.length - 1);
                gridRef.current.api.ensureIndexVisible(newIndex); // Make sure the selected row is visible
                gridRef.current.api.getDisplayedRowAtIndex(newIndex).setSelected(true); // Set row selected
                return newIndex;
            });
            setArrowKeyUsed(true);
        } else if (event.key === 'ArrowUp') {
            setSelectedIndex((prevIndex) => {
                const newIndex = Math.max(prevIndex - 1, 0);
                gridRef.current.api.ensureIndexVisible(newIndex); // Ensure selected row is visible
                gridRef.current.api.getDisplayedRowAtIndex(newIndex).setSelected(true); // Set row selected
                return newIndex;
            });
            setArrowKeyUsed(true);
        } else if (event.key === 'Enter') {
            if (arrowKeyUsed) {
                handleEdit(curdata[selectedIndex]);
                setArrowKeyUsed(false); // Reset after edit
            } else {
                console.log('Enter pressed without using arrow keys first');
            }
        }
    };

    const handleRowClick = (event) => {
        const index = event.rowIndex;
        setSelectedIndex(index);
        setArrowKeyUsed(false); // Reset arrow key flag since row was selected by mouse
    };

    useEffect(() => {
        const handleKeyDownGlobal = (event) => {
            handleKeyDown(event);
        };

        window.addEventListener('keydown', handleKeyDownGlobal);
        return () => {
            window.removeEventListener('keydown', handleKeyDownGlobal);
        };
    }, [curdata, selectedIndex, arrowKeyUsed]);

    const getRowClass = (params) => {
        return params.node.rowIndex === selectedIndex ? 'highlighted-row' : '';
    };
    return (
        <div id="frmlookupsaleinvoice" className='frmlookupsaleinvoice-divmain'>
            <div className="table-container">
                <h3>{trnname}</h3>
                <div className='searchtext'>
                    <input
                        id='search'
                        autoComplete='off'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={handleSearchChange}
                        className="search-input"
                    />

                    <div className="mybtncontainer">
                        <button className="mybtn" onClick={handlePrintSelected}><AiOutlinePrinter className="btn-icon" />Print</button>
                        <button className="mybtn" onClick={handleAddNew}><AiOutlinePlus className="btn-icon" />Addnew</button>
                    </div>

                </div>
                <div className='grid-table'>
                    <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={curdata}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            gridOptions={gridOptions}
                            rowSelection="multiple"
                            rowHeight={25}
                            onRowClicked={handleRowClick} // Handle row click
                            // onGridReady={(params) => {
                            //     params.api.getDisplayedRowAtIndex(selectedIndex).setSelected(true); // Set initial row selected
                            // }}
                            onFilterChanged={() => {
                                const firstRowNode = gridRef.current.api.getDisplayedRowAtIndex(0);
                                if (firstRowNode) {
                                    gridRef.current.api.setFocusedCell(0, 'docuno');
                                    gridRef.current.api.getRowNode(firstRowNode.id).setSelected(true);
                                }
                            }}
                            getRowClass={getRowClass} 
                            domLayout="normal"
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SaleInvoiceLookup;
