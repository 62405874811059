import React, { useRef, useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import axios from "axios";

const ClientBank = () => {
    let url;
    let response;
    useEffect(() => {
        ifscRef.current.focus();
    }, []);
    const handleClick = (event) => {
        event.preventDefault();
    };
    const ifscRef = useRef();
    const trntypeRef = useRef();
    const accountnoRef = useRef();
    const renewaldateRef = useRef();


    const [bankformdata, setBankFormdata] = useState({
        ifsc: '',
        bank: '',
        branch: '',
        trntype: '',
        accountno: '',
        renewal: false,
        renewaldate: ''
    })
    const resetBankForm = () => {
        setBankFormdata({
            ifsc: "",
            bank: '',
            branch: '',
            trntype: '',
            accountno: '',
            renewal: false,
            renewaldate: ''
        })
    }


    const fetchBankDetails = async (ifsc) => {
        const response = await axios.get(` https://ifsc.razorpay.com/${ifsc} `);
        return {
            bank: response.data.BANK,
            branch: response.data.BRANCH,
        };
    };

    const handleBankFormChange = async (event) => {
        let { name, value } = event.target;
        if (name === 'ifsc' && value) {
            value = value.toUpperCase();
            const { selectionStart, selectionEnd } = event.target;
            setTimeout(() => {
                ifscRef.current.setSelectionRange(selectionStart, selectionStart);
            }, 0);
            if (value.length === 11) {
                try {
                    const bankDetails = await fetchBankDetails(value);
                    setBankFormdata((prevBankFormdata) => ({
                        ...prevBankFormdata,
                        bank: bankDetails.bank.toUpperCase(),
                        branch: bankDetails.branch.toUpperCase(),
                    }));

                } catch (error) {
                    console.error("Error fetching bank details:", error);
                    alert(error.message === 'Network Error' ? error.message : "Invalid IFS Code!");
                    ifscRef.current.focus();
                    ifscRef.current.select();
                    return;
                }
            } else {
                setBankFormdata((prevBankFormdata) => ({
                    ...prevBankFormdata,
                    bank: '',
                    branch: ''
                }));

            }
        }
        if (name === 'trntype' && value !== 'CC') {
            setBankFormdata((prevBankFormdata) => ({
                ...prevBankFormdata,
                renewaldate: '',
                renewal: false
            }));

        }
        if (name === 'renewal') {
            value = event.target.checked;
            setBankFormdata((prevBankFormdata) => ({
                ...prevBankFormdata,
                renewaldate: '',
            }));
        }

        setBankFormdata((prevBankFormdata) => ({
            ...prevBankFormdata,
            [name]: value
        }));
        console.log(value);

    }
    const isValidDate = (day, month) => {
        if (day < 1 || day > 31 || month < 1 || month > 12) {
            return false;
        }
        const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (month === 2 && day > 29) {
            return false; // February has at most 29 days
        } else if (day > daysInMonth[month - 1]) {
            return false; // Check for 30/31 day months
        }

        return true;
    };

    const [curmode, setcurmode] = useState('New');

    const handleBankAddNew = () => {
        resetBankForm();
        ifscRef.current.focus();
        setcurmode('New');
    }
    const [srlno, setsrlno] = useState(0);
    const handleBankEdit = (data, index) => {
        setBankFormdata({
            ifsc: data.ifscode,
            bank: data.bankname,
            branch: data.branch,
            trntype: data.trntype,
            accountno: data.accno,
            renewal: data.renewal,
            renewaldate: data.renewaldate
        })
        setcurmode('Edit');
        ifscRef.current.focus();
        setsrlno(data.srlno);
        console.log(data.srlno);
    }

    const handleBankDelete = async (data, index) => {
        // setcurmode('Edit');
        const confirmed = window.confirm("Are you sure you want to delete this entry?");
        if (confirmed) {
            const bankdata = {
                srlno: data.srlno,
                coycode: 1
            }
            url = `${process.env.REACT_APP_EMPLOYEE_URL}/profilebank_delete`;

            response = await axios.post(url, bankdata)
            handleFetchAll();
            setcurmode('New');
            resetBankForm();
        }
    };
    const handleBankCancel = () => {
        resetBankForm();
        ifscRef.current.focus();
        setcurmode('New');
    }

    const [profilebankdata, setProfileBankdata] = useState([]);
    const handleFetchAll = async () => {
        try {
            url = 'http://localhost:5000/profilebank_getall';
            const data = {
                coycode: 1
            }
            console.log(data);
            const response = await axios.post(url, data);
            setProfileBankdata(response.data);
        } catch (error) {
            console.error('Error fetching bank details:', error);
        }
    };

    useEffect(() => {
        handleFetchAll();
    }, []);

    const handleBankSave = async () => {
        let value = bankformdata.ifsc;
        if (value === '') {
            alert('Must Enter IFSC');
            ifscRef.current.focus();
            return;
        }
        if (bankformdata.bank === '' && bankformdata.branch === '') {
            alert('Invalid IFSC or Network Error');
            ifscRef.current.focus();
            return;
        }
        value = bankformdata.trntype;
        if (value === '') {
            alert('Must Choose Transaction Type');
            trntypeRef.current.focus();
            return;
        }
        value = bankformdata.accountno;
        if (value === '' || value.length < 8) {
            alert('Must Enter Valid Account Number Range Between 8 and 18 Digits');
            accountnoRef.current.focus();
            return;
        }
        value = bankformdata.renewaldate;
        if (bankformdata.renewal === true && value === '') {
            alert('Must Enter Renewal Date');
            window.document.getElementById('renewaldate').focus();
            window.document.getElementById('renewaldate').select();
            return;
        }
        value = bankformdata.renewaldate;
        if (bankformdata.renewal === true && value) {
            const [day, month] = bankformdata.renewaldate.split('/').map(Number);
            if (!isValidDate(day, month)) {
                alert('Invalid date.');
                window.document.getElementById('renewaldate').focus();
                window.document.getElementById('renewaldate').select();
                return;
            }
        }

        try {
            url = `${process.env.REACT_APP_EMPLOYEE_URL}/profilebank_accnocheck`;
            const data = {
                accno: bankformdata.accountno,
                coycode: 1,
                srlno: curmode === 'Edit' ? srlno : 0,
            }
            console.log(data);
            const response = await axios.post(url, data);
            console.log(response);
            if (response.data.data.length > 0) {
                alert('Account Number Already Given');
                accountnoRef.current.select();
                return;
            }
        } catch (error) {
            console.error('Error :', error);
        }



        try {
            const bankdata = {
                srlno: curmode === 'Edit' ? srlno : 0,
                coycode: 1,
                bankname: bankformdata.bank,
                branch: bankformdata.branch,
                ifscode: bankformdata.ifsc,
                accno: bankformdata.accountno,
                trntype: bankformdata.trntype,
                renewal: bankformdata.renewal ? 1 : 0,
                renewaldate: bankformdata.renewal ? bankformdata.renewaldate : '',
            }
            console.log(bankdata);

            url = `${process.env.REACT_APP_EMPLOYEE_URL}/profilebank_create`;

            if (curmode === 'Edit') {
                url = `${process.env.REACT_APP_EMPLOYEE_URL}/profilebank_update`;


            }
            response = await axios.post(url, bankdata)
            handleFetchAll();
        }
        catch (error) {
            console.error('Error submitting form:', error);
        }
        setcurmode('New');
        resetBankForm();
        ifscRef.current.focus();
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission on Enter
        }
    };
    const defaultEnterRef = useRef('');
    const defaultEscapeRef = useRef('');

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Enter') {
                defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                defaultEscapeRef.current.click();
            }

        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);
    return (

        <form className='clientprofile-forms' onSubmit={handleClick} onKeyDown={handleKeyDown}>
            <div className='clientprofile-cmpdetails'>
                {/* <p>Company ID : {formdata.coyId}</p>
                    <p>Company Name : {formdata.coyname}</p>
                    <p>Place : {formdata.place}</p>
                    <p>GSTIN: {formdata.GSTIN}</p> */}
            </div>

            <div className='clientprofile-bankcontainer'>
                <div className='clientprofile-banktablepanel'>
                    <div className='clientprofile-addnew-bankdetailsgrp'>
                        <p> Bank Account Details:</p>
                        <button onClick={handleBankAddNew}>Add New</button>
                    </div>
                    <div className='clientprofile-banktable' >
                        <table >
                            <thead>
                                <tr>
                                    <th style={{ width: "20%" }}>Bank Name</th>
                                    <th style={{ width: "18%" }}>Branch</th>
                                    <th style={{ width: "15%" }}>IFSC</th>
                                    <th style={{ width: "20%" }}>Account Number</th>
                                    <th style={{ width: "10%" }}>Trn. Type</th>
                                    <th style={{ width: "16%" }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {profilebankdata.map((data, index) => (
                                    <tr key={index}>

                                        <td className="table-cell">{data.bankname}</td>
                                        <td className="table-cell">{data.branch}</td>
                                        <td className="table-cell">{data.ifscode}</td>
                                        <td className="table-cell">{data.accno}</td>
                                        <td className="table-cell">{data.trntype}</td>
                                        <td className="table-cell">
                                            <div className='edit-deletebtngroup'>
                                                <button
                                                    onClick={() => handleBankEdit(data, index)}
                                                >Edit</button>
                                                <button
                                                    onClick={() => handleBankDelete(data, index)}
                                                >Delete</button>
                                            </div>
                                        </td>

                                    </tr>
                                ))}


                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='clientprofile-banksinglepanel'>
                    <div className='clientprofile-bankform'>
                        <div className='clientprofile-bankform-group'>
                            <label htmlFor="ifsc" >IFSC:</label>
                            <input type="text" id='ifsc' name='ifsc' ref={ifscRef} value={bankformdata.ifsc} onChange={handleBankFormChange} maxLength={11} />
                        </div>
                        <div className='clientprofile-bankform-group'>
                            <label htmlFor="bankname" >  Bank Name:</label>
                            <input type='text' id='bankname' name='bank' value={bankformdata.bank} onChange={handleBankFormChange} readOnly tabIndex={-1} />
                        </div>
                        <div className='clientprofile-bankform-group'>
                            <label htmlFor="branch" > Branch:</label>
                            <input type="text" id='branch' name='branch' value={bankformdata.branch} onChange={handleBankFormChange} readOnly tabIndex={-1} />
                        </div>
                        <div className='clientprofile-bankform-group'>
                            <label htmlFor="trntype" > Transaction Type:</label>
                            <select id='trntype' name='trntype' ref={trntypeRef} value={bankformdata.trntype} onChange={handleBankFormChange}>
                                <option value="" disabled>Select Transaction Type</option>
                                <option value="SB">Savings Account</option>
                                <option value="CA">Current Account</option>
                                <option value="CC">Cash Credit Account</option>
                                <option value="OD">OD Account</option>
                                <option value="TL">Term Loan Account </option>
                            </select>
                        </div>
                        <div className='clientprofile-bankform-group'>
                            <label htmlFor="accountno" > Account No:</label>
                            <input type="text" id='accountno' name='accountno' ref={accountnoRef} value={bankformdata.accountno} onChange={handleBankFormChange} />
                        </div>
                        <div className='clientprofile-bankform-group'>

                            {/* <label htmlFor="renewal"> Renewal</label> */}
                            <label htmlFor=""></label>


                            <div className='clientprofile-bankform-renewalgroup2'>
                                <label htmlFor="renewal" style={{ width: '22%' }}> Renewal</label>
                                <input type="checkbox" id='renewal'
                                    name='renewal'
                                    checked={bankformdata.renewal}
                                    onChange={handleBankFormChange}
                                    disabled={bankformdata.trntype !== 'CC'}
                                    style={{ width: "8%", height: '20px'}} />
                                <label htmlFor="renewaldate" style={{ width: "50%", textAlign: "right" }}> Renewal Date:</label>
                                <InputMask
                                    mask="99/99"
                                    placeholder="DD/MM"
                                    id='renewaldate'
                                    name='renewaldate'
                                    value={bankformdata.renewaldate}
                                    onChange={handleBankFormChange}
                                    ref={renewaldateRef}
                                    style={{ width: "20%" }} readOnly={bankformdata.renewal === false || bankformdata.trntype !== 'CC'}
                                />
                                {/* <input type="text" id='renewaldate'
                                    name='renewaldate'
                                    value={bankformdata.renewaldate}
                                    onChange={handleBankFormChange}
                                    ref={renewaldateRef}
                                    style={{ width: "30%" }} readOnly={!isRenewalChecked} /> */}
                            </div>


                        </div>
                    </div>


                    <div className='clientprofile-save-cancelbtngrp'>
                        <button ref={defaultEscapeRef} onClick={handleBankCancel}>Cancel</button>
                        <button ref={defaultEnterRef} type='submit' onClick={handleBankSave}>Save</button>
                    </div>
                </div>
            </div>



        </form>


    )
}
export default ClientBank;